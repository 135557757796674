import * as React from "react"

const Restaurant = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 18 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M1.781 13.664H16.22M2.813 13.664a6.187 6.187 0 0 1 12.374 0"
			strokeWidth={1.5}
			strokeLinecap="round"
			strokeLinejoin="round"
			fill={"none"}
		/>
		<path
			d="M9 7.43a1.547 1.547 0 1 0 0-3.094A1.547 1.547 0 0 0 9 7.43Z"
			strokeWidth={1.5}
			strokeLinecap="round"
			strokeLinejoin="round"
			fill={"none"}
		/>
	</svg>
)

export default Restaurant

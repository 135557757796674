import * as React from "react"

const Finance2 = (props: React.SVGProps<SVGSVGElement>) => {
	// Уникальный ID
	const gradId = Math.random()
		.toString(16)
		.slice(2)

	return (
		<svg
			{...props}
			width="25"
			height="24"
			viewBox="0 0 25 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16.8789 3.5C16.3266 3.5 15.8789 3.94772 15.8789 4.5C15.8789 5.05228 16.3266 5.5 16.8789 5.5H18.3027L15.0454 8.37323L11.129 4.91861C10.751 4.5852 10.1839 4.5852 9.80594 4.91861L3.33851 10.6234C2.92433 10.9887 2.88473 11.6206 3.25007 12.0348C3.61541 12.449 4.24734 12.4886 4.66152 12.1233L10.4674 7.00199L14.3839 10.4566C14.7619 10.79 15.3289 10.79 15.7069 10.4566L20.0001 6.66964V8.50003C20.0001 9.05232 20.4478 9.50003 21.0001 9.50003C21.5524 9.50003 22.0001 9.05232 22.0001 8.50003V4.5C22.0001 3.94772 21.5524 3.5 21.0001 3.5H16.8789ZM13.8203 14.3943C13.8203 13.842 14.268 13.3943 14.8203 13.3943C15.3726 13.3943 15.8203 13.842 15.8203 14.3943V19.5001C15.8203 20.0523 15.3726 20.5001 14.8203 20.5001C14.268 20.5001 13.8203 20.0523 13.8203 19.5001V14.3943ZM10.7025 9.99048C11.2548 9.99048 11.7025 10.4382 11.7025 10.9905V19.5C11.7025 20.0523 11.2548 20.5 10.7025 20.5C10.1502 20.5 9.70252 20.0523 9.70252 19.5V10.9905C9.70252 10.4382 10.1502 9.99048 10.7025 9.99048ZM19.9392 12.6924C19.9392 12.1401 19.4915 11.6924 18.9392 11.6924C18.3869 11.6924 17.9392 12.1401 17.9392 12.6924V19.5C17.9392 20.0523 18.3869 20.5 18.9392 20.5C19.4915 20.5 19.9392 20.0523 19.9392 19.5V12.6924ZM6.57618 15.0962C6.0239 15.0962 5.57618 15.5439 5.57618 16.0962V19.5C5.57618 20.0523 6.0239 20.5 6.57618 20.5C7.12847 20.5 7.57618 20.0523 7.57618 19.5V16.0962C7.57618 15.5439 7.12847 15.0962 6.57618 15.0962Z"
				fill={`url(#${gradId})`}
				data-not-fill-stroke
				strokeWidth={1}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<defs>
				<linearGradient
					id={gradId}
					x1="12.5001"
					y1="3.5"
					x2="13.9428"
					y2="20.3767"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FEE04C" />
					<stop offset="1" stopColor="#DFAD2B" />
				</linearGradient>
			</defs>
		</svg>
	)
}

export default Finance2

import React from "react"

import menuStyles from "../components/Menu/styles.module.scss"
import colors from "../../../../styles/_V2/export.module.scss"

import { pagesLinks } from "../../../../pages-data/_V2/common/links"

import QuickRestoManager from "../assets/dropdownItemsIcons/QuickRestoManager"
import Notifications from "../assets/dropdownItemsIcons/Notifications"
import Finance2 from "../assets/dropdownItemsIcons/Finance2"

export const managersItemHoverColor = colors.yellowLightBg
export const managersTitleClassName = menuStyles.__managers
export const managersIconColor = colors.yellow
export const managersItemNameMobileHoverColor = menuStyles.menuItem_managers

export const managersItems = {
	indexQRM: {
		icon: <QuickRestoManager />,
		name: "Quick Resto Manager",
		description: "Приложение для контроля ресторана",
		link: pagesLinks.indexQRM,
	},
	reportsNotifications: {
		icon: <Notifications />,
		name: "Уведомления",
		description: (
			<>
				Работают на любом устройстве,
				<br />в приложении и в Telegram
			</>
		),
		link: pagesLinks.notifications,
	},
	finance: {
		icon: <Finance2 />,
		name: "Финансовый модуль",
		description: `Держи фокус на финансовом результате`,
		link: pagesLinks.finance,
	},
}

export const managersItemsKz = {
	indexQRM: {
		icon: <QuickRestoManager />,
		name: "Quick Resto Manager",
		description: "Мейрамхананы бақылауға арналған қосымша",
		link: pagesLinks.indexQRM,
	},
	reportsNotifications: {
		icon: <Notifications />,
		name: "Хабарламалар",
		description: (
			<>
				Кез келген құрылғыда,
				<br />қосымшада және Telegram-да жұмыс істейді
			</>
		),
		link: pagesLinks.notifications,
	},
	finance: {
		icon: <Finance2 />,
		name: "Қаржы модулі",
		description: "Қаржылық нәтижеге назар аудар",
		link: pagesLinks.finance,
	},
}

import * as React from "react"

const VkIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		width={32}
		height={32}
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16 30c7.732 0 14-6.268 14-14S23.732 2 16 2 2 8.268 2 16s6.268 14 14 14ZM6.489 11.244c.146 7.032 3.656 11.25 9.81 11.25l.349.012v-4.017a5.552 5.552 0 0 1 4.657 4.017h3.206a8.884 8.884 0 0 0-4.623-5.625 8.572 8.572 0 0 0 3.937-5.625h-2.902c-.63 2.227-2.498 4.252-4.275 4.443v-4.443h-2.903v7.773c-1.8-.472-4.072-2.632-4.174-7.785H6.49Z"
		/>
	</svg>
)

export default VkIcon

import * as React from "react"
import { SVGProps } from "react"

const Hookah = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="14"
		height="18"
		viewBox="0 0 14 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M9.02193 16.5V17.25H9.30022L9.51116 17.0685L9.02193 16.5ZM4.9774 16.5L4.48817 17.0685L4.6991 17.25H4.9774V16.5ZM9.35138 14.1481C9.35138 14.861 9.03514 15.4991 8.53269 15.9315L9.51116 17.0685C10.3306 16.3632 10.8514 15.3161 10.8514 14.1481H9.35138ZM6.99966 11.7964C8.29848 11.7964 9.35138 12.8493 9.35138 14.1481H10.8514C10.8514 12.0209 9.12691 10.2964 6.99966 10.2964V11.7964ZM4.64795 14.1481C4.64795 12.8493 5.70085 11.7964 6.99966 11.7964V10.2964C4.87242 10.2964 3.14795 12.0209 3.14795 14.1481H4.64795ZM5.46664 15.9315C4.96419 15.4991 4.64795 14.861 4.64795 14.1481H3.14795C3.14795 15.3161 3.66874 16.3632 4.48817 17.0685L5.46664 15.9315ZM4.9774 17.25H9.02193V15.75H4.9774V17.25Z"
			fill="#FF9600"
		/>
		<path
			d="M7 10.9219V4.5"
			stroke="#FF9600"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M7 10.5L2.5 10.5C1.67157 10.5 1 9.82843 1 9C1 8.17157 1.67157 7.5 2.5 7.5H10C11.6569 7.5 13 6.15685 13 4.5"
			stroke="#FF9600"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M4.375 4.5L9.625 4.5"
			stroke="#FF9600"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M9.25 1.5L8.50311 4.5L5.52702 4.5L4.75 1.5L9.25 1.5Z"
			stroke="#FF9600"
			strokeWidth="1.5"
			strokeLinejoin="round"
		/>
	</svg>
)

export default Hookah

import React, { useState, useEffect, createContext } from "react"

import { ContentSection } from "../data"

import styles from "../styles.module.scss"

interface HeaderUIState {
	mobileHeaderExpanded: boolean
	setMobileHeaderExpandState: (value: boolean) => void
	selectedContentSection: ContentSection | null
	setSelectedContentSections: (value: ContentSection | null) => void
	isDropdownMenuOpen: boolean
	setDropdownMenuOpenState: (value: boolean) => void
	isSticky: boolean
}

export function useHeaderUIState(): HeaderUIState {
	const [mobileHeaderExpanded, setMobileHeaderExpandState] = useState(false)
	const [isSticky, setStickyState] = useState(false)
	const [
		selectedContentSection,
		setSelectedContentSections,
	] = useState<ContentSection | null>(null)
	// const [
	//     selectedContentSection,
	//     setSelectedContentSections,
	// ] = useState<ContentSection | null>(data.contentSections.solutions)
	const [isDropdownMenuOpen, setDropdownMenuOpenState] = useState<boolean>(
		false
	)

	useEffect(() => {
		if (typeof window !== "undefined") {
			window.addEventListener("scroll", onScrollHandler)

			onScrollHandler()

			return () => {
				window.removeEventListener("scroll", onScrollHandler)
			}
		}

		function onScrollHandler() {
			setStickyState(window.pageYOffset !== 0)
		}
	}, [])

	useEffect(() => {
		function onClickOutsideHeader(e) {
			const isBody = e.target.closest("*").tagName === "BODY"

			if (isBody && mobileHeaderExpanded) {
				setMobileHeaderExpandState(false)
			}
		}

		document.addEventListener("click", onClickOutsideHeader)

		return () => document.removeEventListener("click", onClickOutsideHeader)
	}, [])

	useEffect((): void => {
		if (typeof window === "undefined") {
			return
		}

		const htmlEl = document.querySelector("html")
		const body = document.getElementsByTagName("body")[0]

		if (mobileHeaderExpanded) {
			body.classList.add(styles.bodyWithExpandedHeader)
			htmlEl.style.overflow = "hidden"
		} else {
			body.classList.remove(styles.bodyWithExpandedHeader)
			htmlEl.style.overflow = "auto"
		}
	}, [mobileHeaderExpanded])

	return {
		mobileHeaderExpanded,
		setMobileHeaderExpandState,
		selectedContentSection,
		setSelectedContentSections,
		isDropdownMenuOpen,
		setDropdownMenuOpenState,
		isSticky,
	}
}

export const HeaderUIStateContext = createContext({} as HeaderUIState)

export const HeaderUIStateProvider = (props: { children: JSX.Element }) => {
	const headerUIState = useHeaderUIState()

	return (
		<HeaderUIStateContext.Provider value={headerUIState}>
			{props.children}
		</HeaderUIStateContext.Provider>
	)
}

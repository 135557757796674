import * as React from "react"

const Sushi = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		width="19"
		height="18"
		viewBox="0 0 19 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<rect
			x="3.5"
			y="9"
			width="6"
			height="6"
			rx="1.5"
			stroke="#FF9500"
			strokeWidth="1.5"
		/>
		<circle cx="6.5" cy="12" r="1.125" fill="#FF9500" />
		<rect
			x="9.5"
			y="9"
			width="6"
			height="6"
			rx="1.5"
			stroke="#FF9500"
			strokeWidth="1.5"
		/>
		<circle cx="12.5" cy="12" r="1.125" fill="#FF9500" />
		<rect
			x="6.5"
			y="3"
			width="6"
			height="6"
			rx="1.5"
			stroke="#FF9500"
			strokeWidth="1.5"
		/>
		<circle cx="9.5" cy="6" r="1.125" fill="#FF9500" />
	</svg>
)

export default Sushi

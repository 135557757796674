import * as React from "react"

const LoyaltyCards = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 25 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M2.5 10.99v5a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-8a2 2 0 0 0-2-2h-3"
			strokeWidth={2}
			strokeLinecap="round"
			fill={"none"}
		/>
		<path
			d="M6.596 3.512A2.555 2.555 0 0 0 4.61 7.68l4.098 3.945 4.058-3.897.02-.022.02-.026a2.557 2.557 0 1 0-4.098-3.05A2.55 2.55 0 0 0 6.6 3.512h-.003Z"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
			fill={"none"}
		/>
	</svg>
)

export default LoyaltyCards

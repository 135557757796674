import React, {useContext} from "react";
import {useLocalizationContext} from "../../../localization/useLocalizationContext";

import LocaleIcon from "../../../assets/_V2/svg/LocaleIcon"
import { HeaderUIStateContext } from '../Header/hooks/headerUIState'

import styles from './styles.module.scss'
import classNames from "classnames";

interface Props {

    className? : string | undefined

    isDarkTheme?: boolean;

}

export const LocaleSwitcher: React.FC = (props : Props) => {

    const localizationContext = useLocalizationContext()

    const isDarkTheme = props.isDarkTheme;

    const {
        mobileHeaderExpanded,
        isSticky,
    } = useContext(HeaderUIStateContext)

    const localeNames = {
        'ru-RU': 'РУ',
        'ru-BY': 'РУ',
        'ru-KZ': 'РУ',
        'kz-KZ': 'KZ',
    }

    return (

            <ul
                className={classNames(
                    props.className,
                    {
                        [styles.onWhiteBackground]: isSticky || mobileHeaderExpanded,
                        [styles.locales_theme_dark]: isDarkTheme
                    }
                )}>
                <li
                    className={styles.locale__item}
                    onClick={() => localizationContext.setLocale(localizationContext.locale)}
                >
                    <LocaleIcon/>
                    {localeNames[localizationContext.locale]}
                </li>
                {localizationContext.locale == 'ru-KZ' ? (
                    <li
                        className={classNames(styles.locale__item, styles.hidden)}
                        onClick={() => localizationContext.setLocale('kz-KZ')}
                    >
                        KZ
                    </li>
                ) :
                    (
                        <li
                            className={classNames(styles.locale__item, styles.hidden)}
                            onClick={() => localizationContext.setLocale('ru-KZ')}
                        >
                            РУ
                        </li>
                    )}

            </ul>
        // <div className={styles.container}>
        //     <button
        //         onClick={() => localizationContext.setLocale('ru-KZ')}
        //         className={styles.button}
        //         disabled={'ru-KZ' === localizationContext.locale}
        //     >
        //         {'ru-KZ'}
        //     </button>
        //     <button
        //         onClick={() => localizationContext.setLocale('kz-KZ')}
        //         className={styles.button}
        //         disabled={'kz-KZ' === localizationContext.locale}
        //     >
        //         {'kz-KZ'}
        //     </button>
        // </div>
    )

}

import * as React from "react"

const BurgerIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path d="M5 8h22M5 16h22M5 24h15" strokeWidth={3} strokeLinecap="round" />
	</svg>
)

export default BurgerIcon

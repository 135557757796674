import * as React from "react"

const Video = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 18 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<rect
			x={1.85693}
			y={1.14844}
			width={14.2857}
			height={10.7171}
			rx={1.5}
			fill={"none"}
			strokeWidth={1.5}
		/>
		<path
			d="M7.57129 4.27051L11.8571 6.50003L7.57129 8.74288V4.27051Z"
			stroke={"none"}
			strokeWidth={1.5}
			strokeLinejoin="round"
		/>
		<path
			d="M1.85693 14.6729C1.44272 14.6729 1.10693 15.0086 1.10693 15.4229C1.10693 15.8371 1.44272 16.1729 1.85693 16.1729V14.6729ZM4.71408 16.1729C5.12829 16.1729 5.46408 15.8371 5.46408 15.4229C5.46408 15.0086 5.12829 14.6729 4.71408 14.6729V16.1729ZM1.85693 16.1729H4.71408V14.6729H1.85693V16.1729Z"
			stroke={"none"}
		/>
		<path
			d="M8.95313 14.6729C8.53891 14.6729 8.20313 15.0086 8.20312 15.4229C8.20312 15.8371 8.53891 16.1729 8.95312 16.1729L8.95313 14.6729ZM16.1427 16.1729C16.5569 16.1729 16.8927 15.8371 16.8927 15.4229C16.8927 15.0086 16.5569 14.6729 16.1427 14.6729L16.1427 16.1729ZM8.95312 16.1729L16.1427 16.1729L16.1427 14.6729L8.95313 14.6729L8.95312 16.1729Z"
			stroke={"none"}
		/>
		<circle cx={5.27672} cy={15.4227} r={1.42857} stroke={"none"} />
	</svg>
)

export default Video

import * as React from "react"

const CloseCross = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path d="m7 7 18 18M25 7 7 25" strokeWidth={3} strokeLinecap="round" />
	</svg>
)

export default CloseCross

import * as React from "react"

const CashTerminal = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M18 21V6a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v15"
			fill={"none"}
			strokeWidth={2}
		/>
		<path
			d="M15 14.996H9M15 10.996H9M6 17H4a2 2 0 1 0 0 4h16a2 2 0 1 0 0-4h-2"
			strokeWidth={2}
			fill={"none"}
			strokeLinecap="round"
		/>
	</svg>
)

export default CashTerminal

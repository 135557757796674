import * as React from "react"

const Entertainment = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M12 2L14.2961 6.45672L19.0711 4.92893L17.5433 9.7039L22 12L17.5433 14.2961L19.0711 19.0711L14.2961 17.5433L12 22L9.7039 17.5433L4.92893 19.0711L6.45672 14.2961L2 12L6.45672 9.7039L4.92893 4.92893L9.7039 6.45672L12 2Z"
			stroke="#FF9600"
			strokeWidth="2"
			strokeLinejoin="round"
		/>
	</svg>
)

export default Entertainment

import React from "react"
import classNames from "classnames"

import AdaptiveLink, { AdaptiveLinkBaseProps } from "../../../AdaptiveLink"

import styles from "./styles.module.scss"

interface Props {
	className?: string

	icon: JSX.Element

	title: string

	text: string

	link: AdaptiveLinkBaseProps
}

export default function MenuItemDesktopCustomTile(props: Props) {
	return (
		<div
			className={classNames(styles.title, props.className)}
		>
			<div className={styles.title__content}>
				<AdaptiveLink
                    href={props.link.href}
                    useGatsbyLink={props.link.useGatsbyLink}
                    className={styles.title__title}
                >
                    {props.title}
                </AdaptiveLink>

				<p className={styles.title__text}>{props.text}</p>
			</div>

			<div className={styles.title__iconContainer}>{props.icon}</div>
		</div>
	)
}

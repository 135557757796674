import * as React from "react"
import { SVGProps } from "react"

const Canteen = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M11.5212 3.18457L11.5212 7.68457C11.5212 9.61757 9.95424 11.1846 8.02124 11.1846C6.08824 11.1846 4.52124 9.61757 4.52124 7.68457L4.52124 3.18457"
			stroke="#FF9500"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M8.02124 11.8149V20.8149"
			stroke="#FF9500"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M8.02124 3.18457V7.45529"
			stroke="#FF9500"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M19.4788 13.1846L15.4788 13.1846L15.4788 6.82093C15.4788 4.81263 17.1068 3.18457 19.1151 3.18457V3.18457C19.316 3.18457 19.4788 3.34738 19.4788 3.54821L19.4788 13.1846Z"
			stroke="#FF9500"
			strokeWidth="2"
			strokeLinejoin="round"
		/>
		<path
			d="M19.4788 11.8149V20.8149"
			stroke="#FF9500"
			strokeWidth="2"
			strokeLinecap="round"
		/>
	</svg>
)

export default Canteen

import * as React from "react"

const Assistance = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 18 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<rect
			x={1.5}
			y={9}
			width={2.25}
			height={4.956}
			rx={1.125}
			fill={"none"}
			strokeWidth={1.5}
		/>
		<rect
			x={14.25}
			y={9}
			width={2.25}
			height={4.956}
			rx={1.125}
			fill={"none"}
			strokeWidth={1.5}
		/>
		<path
			d="M13.5 6.75a.75.75 0 1 0 1.5 0h-1.5ZM3 6.75a.75.75 0 0 0 1.5 0H3Zm6-4.5a4.5 4.5 0 0 1 4.5 4.5H15a6 6 0 0 0-6-6v1.5Zm0-1.5a6 6 0 0 0-6 6h1.5A4.5 4.5 0 0 1 9 2.25V.75ZM9 15.75a.75.75 0 0 0 0 1.5v-1.5Zm6-4.5a.75.75 0 0 0-1.5 0H15Zm-1.5 0a4.5 4.5 0 0 1-4.5 4.5v1.5a6 6 0 0 0 6-6h-1.5Z"
			stroke={"none"}
		/>
		<circle cx={9} cy={9} r={0.75} stroke={"none"} />
		<circle cx={11.25} cy={9} r={0.75} stroke={"none"} />
		<circle cx={6.75} cy={9} r={0.75} stroke={"none"} />
		<circle cx={9} cy={15.375} r={1.125} fill={"none"} strokeWidth={1.5} />
	</svg>
)

export default Assistance

import React, { useEffect, useState } from "react"
import classNames from "classnames"

import SignIn from "../../../SignIn"
import AdaptiveLink from "../../../AdaptiveLink"
import {useLocalizationContext} from "../../../../../localization/useLocalizationContext";

import { getData } from "../../data"

import styles from "./styles.module.scss"
import { checkAuth } from "../../../../../apiRequests/auth"
import {pagesLinks} from "../../../../../pages-data/_V2/common/links";

interface Props {
	className?: string

	signInClassName?: string

	onWhiteBackground: boolean

	inFooter?: boolean
}

export default function ButtonsSet(props: Props) {

	const localizationContext = useLocalizationContext();
	const data = getData(localizationContext.locale)
	const [isAuth, setIsAuth] = useState(false)

	useEffect(() => {
		checkAuth().then(isUserAuthorized => {
			setIsAuth(isUserAuthorized)
		})
	}, [])

	return (
		<div
			className={classNames(props.className, styles.buttonsSet, {
				[styles.__onWhiteBackground]: props.onWhiteBackground,
				[styles.__inFooter]: props.inFooter,
			})}
		>
			<SignIn
				buttonClassName={classNames(styles.buttons__signInButton, {
					[styles.__onWhiteBackground]: props.onWhiteBackground,
					[styles.__inFooter]: props.inFooter,
				})}
				profileIconClassName={styles.buttons__signInProfile}
			/>

			{!isAuth && (
				<AdaptiveLink
					className={classNames(styles.buttons__startButton, {
						[styles.__inFooter]: props.inFooter,
					})}
					href={data.startButton.href}
				>
					{localizationContext.locale === 'kz-KZ' ? data.startButton.textKz : data.startButton.text}
				</AdaptiveLink>
			)}
		</div>
	)
}

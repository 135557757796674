import React from "react"

import colors from "../../../../styles/_V2/export.module.scss"

import { pagesLinks } from "../../../../pages-data/_V2/common/links"

import Warehouse from "../assets/dropdownItemsIcons/Warehouse"
import Personnel from "../assets/dropdownItemsIcons/Personnel"
import MarketingAndCRM from "../assets/dropdownItemsIcons/MarketingAndCRM"
import Finance from "../assets/dropdownItemsIcons/Finance"
import ReportsAnalytics from "../assets/dropdownItemsIcons/ReportsAnalytics"
import MenuRouteCards from "../assets/dropdownItemsIcons/MenuRouteCards"

export const backOfficeStaffIconColor = colors.green
export const backOfficeStaffItemHoverColor = colors.greenLight

export const backOfficeStaffItems = {
	menu: {
		icon: <MenuRouteCards />,
		name: "Меню и техкарты",
		description: "Настройка техкарт, порядок в меню – порядок в бизнесе",
		link: pagesLinks.menu,
	},
	warehouse: {
		icon: <Warehouse />,
		name: "Склад",
		description: "Контроль остатков в реальном времени, управление поставками",
		link: pagesLinks.warehouse,
	},
	staff: {
		icon: <Personnel />,
		name: "Персонал",
		description: "Учёт рабочего времени, премии\nи штрафы, график работы",
		link: pagesLinks.staff,
	},
	marketing: {
		icon: <MarketingAndCRM />,
		name: "Маркетинг и CRM",
		mobileName: "Маркетинг\nи CRM",
		description: "Программы лояльности, сегментация, уведомления",
		link: pagesLinks.marketing,
	},
	finance: {
		icon: <Finance />,
		name: "Финансы",
		description: "Управленческий учёт, \nинтеграция с 1С",
		link: pagesLinks.finance,
	},
	reports: {
		icon: <ReportsAnalytics />,
		name: "Отчёты и аналитика",
		mobileName: "Отчёты \nи аналитика",
		description: "Принимай решения на основе данных",
		link: pagesLinks.reports,
	},
}

export const backOfficeStaffItemsKz = {
	menu: {
		icon: <MenuRouteCards />,
		name: "Мәзір және техкарталар",
		description: "Техкарталарды теңшеу, мәзірде тәртіп болса – бизнесте тәртіп болады",
		link: pagesLinks.menu,
	},
	warehouse: {
		icon: <Warehouse />,
		name: "Қойма",
		description: "Нақты уақытта қалдықтарды бақылау, жеткізуді басқару",
		link: pagesLinks.warehouse,
	},
	staff: {
		icon: <Personnel />,
		name: "Персонал",
		description: "Жұмыс уақытын есепке алу, сыйлықақылар\nмен айыппұлдар, жұмыс кестесі",
		link: pagesLinks.staff,
	},
	marketing: {
		icon: <MarketingAndCRM />,
		name: "Маркетинг және CRM",
		mobileName: "Маркетинг\nжәне CRM",
		description: "Адалдық бағдарламалары, сегменттеу, хабарламалар",
		link: pagesLinks.marketing,
	},
	finance: {
		icon: <Finance />,
		name: "Финансы",
		description: "Управленческий учёт, \nинтеграция с 1С",
		link: pagesLinks.finance,
	},
	reports: {
		icon: <ReportsAnalytics />,
		name: "Есептер мен аналитика",
		mobileName: "Есептер\nмен аналитика",
		description: "Деректер негізінде шешімдер қабылда",
		link: pagesLinks.reports,
	},
}

import React from "react"
import { MenuData } from "../../components/Menu"

import {
	getBusinessTypesLinks,
	businessTypesIconColor,
	businessTypesItemHoverColor,
} from "../../navMenuData/businessTypes"

import TableService from "../../assets/dropdownItemsIcons/TableService"
import Entertainment from "../../assets/dropdownItemsIcons/Entertainment"
import TakeawayOnly from "../../assets/dropdownItemsIcons/TakeawayOnly"
import Canteen from "../../assets/dropdownItemsIcons/Canteen"

import Fastfood from "../../assets/dropdownItemsIcons/Fastfood"
import OtherIcon from "../../assets/dropdownItemsIcons/Other"
import Coffeehouse from "../../assets/dropdownItemsIcons/Coffeehouse"
import {Locales} from "../../../../../localization/types";
import {contacts, contactsBy, contactsKz} from "../../../../../pages-data/_V2/common/contacts";

export const getData = (locale: Locales): {
	businessTypes: MenuData
} => {
	const businessTypesLinks = getBusinessTypesLinks(locale);

	const tableServiceTitle = {
		'kz-KZ': "Үстелдер алдында қызмет көрсету",
		'ru-RU': "Обслуживание за столиками",
		'ru-KZ': "Обслуживание за столиками",
		'ru-BY': "Обслуживание за столиками"
	}

	const entertainmentTitle = {
		'kz-KZ': "Үстелдер алдында қызмет көрсету",
		'ru-RU': "Обслуживание за столиками",
		'ru-KZ': "Обслуживание за столиками",
		'ru-BY': "Обслуживание за столиками"
	}

	const fastfoodTitle = {
		'kz-KZ': "Үстелдер алдында қызмет көрсету",
		'ru-RU': "Обслуживание за столиками",
		'ru-KZ': "Обслуживание за столиками",
		'ru-BY': "Обслуживание за столиками"
	}

	const otherTitle = {
		'kz-KZ': "Басқа",
		'ru-RU': "Другое",
		'ru-KZ': "Другое",
		'ru-BY': "Другое",
	}

	const coffeehouseTitle = {
		'kz-KZ': "Кофе және нан пісіру",
		'ru-RU': "Кофе и выпечка",
		'ru-KZ': "Кофе и выпечка",
		'ru-BY': "Кофе и выпечка"
	}

	const canteenTitle = {
		'kz-KZ': "Асхана және салмақ бойынша сатылатын тағамдар",
		'ru-RU': "Столовая и блюда на вес",
		'ru-KZ': "Столовая и блюда на вес",
		'ru-BY': "Столовая и блюда на вес",
	}

	const takeawayTitle = {
		'kz-KZ': "Жеткізу және өзімен әкету",
		'ru-RU': "Доставка и навынос",
		'ru-KZ': "Доставка и навынос",
		'ru-BY': "Доставка и навынос",
	}


	return {
		businessTypes: {
			id: "businessTypes",
			iconColor: businessTypesIconColor,
			itemHoverColor: businessTypesItemHoverColor,
			items: [
				{
					icon: <TableService />,
					name: tableServiceTitle[locale],
					links: [businessTypesLinks.restaurant, businessTypesLinks.cafe],
				},
				{
					icon: <Entertainment />,
					name: entertainmentTitle[locale],
					links: [
						businessTypesLinks.bar,
						businessTypesLinks.pub,
						businessTypesLinks.hookah,
					],
				},
				{
					icon: <Fastfood />,
					name: fastfoodTitle[locale],
					links: [businessTypesLinks.fastfood, businessTypesLinks.foodtruck],
				},
				{
					icon: <OtherIcon />,
					name: otherTitle[locale],
					links: [businessTypesLinks.publicEvents],
				},
				{
					icon: <Coffeehouse />,
					name: coffeehouseTitle[locale],
					links: [
						businessTypesLinks.coffeehouse,
						businessTypesLinks.bakery,
						businessTypesLinks.confectionery,
					],
				},
				{
					icon: <Canteen />,
					name: canteenTitle[locale],
					links: [businessTypesLinks.refectory, businessTypesLinks.cookery],
				},
				{
					icon: <TakeawayOnly />,
					name: takeawayTitle[locale],
					links: [
						businessTypesLinks.pizzeria,
						businessTypesLinks.sushi,
						businessTypesLinks.darkKitchen,
					],
				},
			],
		},
	}
}

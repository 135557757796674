import * as React from "react"

const Confectionery = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		width="15"
		height="14"
		viewBox="0 0 15 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M2.50024 8.15173H12.5005L11.4874 13.0003H3.51334L2.50024 8.15173Z"
			stroke="#FF9500"
			strokeWidth="1.50004"
			strokeLinejoin="round"
		/>
		<path
			d="M5.49997 8.15173L6.5 13.0003"
			stroke="#FF9500"
			strokeWidth="1.50004"
			strokeLinecap="round"
		/>
		<path
			d="M9.4999 8.15173L8.49988 13.0003"
			stroke="#FF9500"
			strokeWidth="1.50004"
			strokeLinecap="round"
		/>
		<path
			d="M3.24719 4.78923L3.26217 5.5391L3.84678 5.52741L3.97806 4.95762L3.24719 4.78923ZM11.7547 4.78926L11.0238 4.95765L11.155 5.527L11.7391 5.53911L11.7547 4.78926ZM10.6144 2.49616L10.0462 2.98584V2.98584L10.6144 2.49616ZM4.38751 2.49616L4.95561 2.98584L4.38751 2.49616ZM2.25002 6.57239C2.25002 6.00872 2.70141 5.5503 3.26217 5.5391L3.2322 4.03936C1.85648 4.06685 0.749981 5.19032 0.749981 6.57239H2.25002ZM3.28353 7.6059C2.71274 7.6059 2.25002 7.14318 2.25002 6.57239H0.749981C0.749981 7.97163 1.88429 9.10594 3.28353 9.10594V7.6059ZM11.717 7.6059H3.28353V9.10594H11.717V7.6059ZM12.7505 6.57239C12.7505 7.14318 12.2877 7.6059 11.717 7.6059V9.10594C13.1162 9.10594 14.2505 7.97163 14.2505 6.57239H12.7505ZM11.7391 5.53911C12.2995 5.55074 12.7505 6.00899 12.7505 6.57239H14.2505C14.2505 5.19097 13.1451 4.06792 11.7702 4.0394L11.7391 5.53911ZM12.4856 4.62087C12.2621 3.65085 11.8195 2.7456 11.1825 2.00648L10.0462 2.98584C10.513 3.52738 10.851 4.20776 11.0238 4.95765L12.4856 4.62087ZM11.1825 2.00648C10.2276 0.898734 8.90661 0.249981 7.50093 0.249981V1.75002C8.43077 1.75002 9.34961 2.17764 10.0462 2.98584L11.1825 2.00648ZM7.50093 0.249981C6.09525 0.249981 4.77423 0.898734 3.8194 2.00648L4.95561 2.98584C5.65225 2.17764 6.57109 1.75002 7.50093 1.75002V0.249981ZM3.8194 2.00648C3.18233 2.74559 2.73981 3.65082 2.51632 4.62083L3.97806 4.95762C4.15083 4.20775 4.48884 3.52737 4.95561 2.98584L3.8194 2.00648Z"
			fill="#FF9500"
		/>
	</svg>
)

export default Confectionery

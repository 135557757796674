import React from 'react'
import classNames from 'classnames'

import styles from './styles.module.scss'
import AdaptiveLink from '../../../AdaptiveLink'
import AdaptiveWrapText from '../../../AdaptiveWrapText'

import { AdaptiveLinkBaseProps } from '../../../AdaptiveLink'

export interface CommonMenuDataItem {

    icon : JSX.Element

    name : string

    mobileName? : string

    description? : string

    link : AdaptiveLinkBaseProps,

}

export interface NestedLinksMenuDataItem {

    icon : JSX.Element

    name : string

    links : Array<{

        name : string

        link : AdaptiveLinkBaseProps

    }>

}

export interface CustomElementMenuDataItem {

    content : JSX.Element,

}

type MenuItem = (
    CommonMenuDataItem |
    CustomElementMenuDataItem |
    NestedLinksMenuDataItem
    )


export interface MenuData {

    // Нужен для навешивания css переменных
    id : string

    // Заголовок меню
    title? : string

    // Класс для оформления заголовка
    titleClassName? : string

    // Цвет заливки иконки элемента. Поле опциональное,
    // поскольку иконки могут иметь встроенную градиентную заливку.
    iconColor? : string

    // Фон элементов при ховере
    itemHoverColor : string

    // Класс для установки цвета текста, если оно отличается от цвета иконки,
    // и это не просто цвет, например, градиент
    itemNameHoverClassName? : string

    // Элемент может быть типовой и описываться структурой или кастомный элемент
    items : Array<MenuItem>

}


interface Props {

    className? : string

    menuClassName? : string

    data ?: MenuData

    composedData ?: Array<MenuData>
}


export default function Menu(props : Props) {

    const getMenuItemContainerClassModifier = (
        item : MenuItem,
    ) : string => {

        if ('link' in item) {
            return styles.__linkItem
        } else if ('content' in item) {
            return styles.__jsxItem
        } else {
            return styles.__commonItem
        }

    }

    const renderMenuItem = (
        item : MenuItem,
        menuData: MenuData
    ) : JSX.Element => {

        if ('link' in item) {
            return renderCommonMenuItem(item, menuData)
        } else if ('content' in item) {
            return item.content
        } else {
            return renderNestedLinksMenuItem(item)
        }

    }

    const renderCommonMenuItem = (
        item : CommonMenuDataItem,
        menuData: MenuData
    ) : JSX.Element => {
        const getLinkWIthTitle = (title?: string) => {
            return <AdaptiveLink
                href={item.link.href}
                target={item.link.target}
                rel={item.link.rel}
                useGatsbyLink={(typeof window !== 'undefined' && window?.location.pathname === item.link.href) ? false : item.link.useGatsbyLink}
                className={classNames(styles.menuItem__linkCover, styles.menuItem__name)}
            >
                { title || '' }
            </AdaptiveLink>;
        }

        return (
            <div
                className={classNames(
                    styles.menu__item,
                    styles.menuItem,
                    menuData.itemNameHoverClassName
                )}
            >
                <div className={styles.menuItem__iconContainer}>
                    {item.icon}
                </div>

                {/* <h5 className={styles.menuItem__name}>
                <AdaptiveWrapText
                    desktop={item.name}
                    tablet={item.mobileName}
                    mobile={item.mobileName}
                />
            </h5> */}

                <AdaptiveWrapText
                    desktop={getLinkWIthTitle(item.name)}
                    tablet={getLinkWIthTitle(item.mobileName || item.name)}
                    mobile={getLinkWIthTitle(item.mobileName || item.name)}
                    titleClasses={styles.menuItem__name}
                    className={styles.menuItem__linkCover}
                >
                    {getLinkWIthTitle(item.name)}
                </AdaptiveWrapText>

                <p className={styles.menuItem__description}>
                    {item.description}
                </p>
            </div>
        )
    }

    const renderNestedLinksMenuItem = (
        item : NestedLinksMenuDataItem,
    ) : JSX.Element => (
        <div
            className={classNames(
                styles.menu__item,
                styles.menuItem,
            )}
        >
            <div className={styles.menuItem__iconContainer}>
                {item.icon}
            </div>

            <div className={styles.menuItem__name}>
                {item.name}
            </div>

            <ul className={styles.menuItem__nestedLinksList}>
                {item.links?.map((item, index) => (
                    <li
                        key={index}
                        className={styles.menuItem__nestedLinkContainer}
                    >
                        <AdaptiveLink
                            className={styles.menuItem__nestedLink}
                            href={item.link.href}
                            target={item.link.target}
                            useGatsbyLink={(typeof window !== 'undefined' && window?.location.pathname === item.link.href) ? false : item.link.useGatsbyLink}
                        >
                            {item.name}
                        </AdaptiveLink>
                    </li>
                ))}
            </ul>
        </div>
    )

    // ЗАРАНЕЕ ПРОЩУ ПРОЩЕНИЯ ЗА ТО, ЧТО НИЖЕ
    // Я чуть не помер от перегрева, пока не разобрался и не разродился этим;
    // уверен, что можно сделать элегантнее и красивее, например, вынести рендер composedMenu
    // в отдельный компонент, или сделать нормальное условие, не по трушности props.data.
    // Но я не сообразил, как.
    const renderSingleOrComposedMenu = () => {
        if (props.data) {
            return (
                <div
                    className={classNames(
                        styles.menuContainer,
                        props.className,
                    )}
                    // Дата-атрибут нужный для установки css-переменных
                    data-id={props.data.id}
                >

                    <style>
                        {`
                            [data-id="${props.data.id}"] {
                                ${props.data.iconColor && `--icon-color: ${props.data.iconColor};`}
                                ${props.data.itemHoverColor && `--icon-hover-color: ${props.data.itemHoverColor};`}
                            }
                        `}
                    </style>

                    {props.data.title && (
                        <div className={classNames(
                            styles.menu__title,
                            props.data.titleClassName,
                        )}
                        >
                            {props.data.title}
                        </div>
                    )}

                    <ul className={classNames(
                        styles.menu__itemsList,
                        props.menuClassName,
                    )}
                    >
                        {props.data.items.map((item, index) => (
                            <li
                                key={index}
                                className={classNames(
                                    styles.menu__itemContainer,
                                    getMenuItemContainerClassModifier(item),
                                )}
                            >
                                {renderMenuItem(item, props.data!)}
                            </li>
                        ))}
                    </ul>

                </div>
            )
        }

        else {
            return (
                <ul className={styles.menu__itemsList}>
                    {props.composedData?.map((menuData: MenuData) => (
                        menuData.items.map((item, index) => (
                            <li
                                key={index}
                                className={classNames(
                                    styles.menu__itemContainer,
                                    getMenuItemContainerClassModifier(item),
                                )}
                                data-id={menuData.id}
                            >
                                <style>
                                    {`
                                        [data-id="${menuData.id}"] {
                                          ${menuData.iconColor && `--icon-color: ${menuData.iconColor};`}
                                          ${menuData.itemHoverColor && `--icon-hover-color: ${menuData.itemHoverColor};`}
                                        }
                                    `}
                                </style>
                                {renderMenuItem(item, menuData)}
                            </li>
                        ))
                    ))}
                </ul>
            )
        }
    }


    return renderSingleOrComposedMenu()
}

import * as React from "react"

const Bakery = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		width="19"
		height="18"
		viewBox="0 0 19 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M15.5636 13.5999C15.5636 14.4137 14.9113 15.0648 14.0975 15.0648H4.96613C4.15236 15.0648 3.5 14.4137 3.5 13.5999V13.5999C3.5 10.2686 6.20054 7.56812 9.53181 7.56812C12.8631 7.56812 15.5636 10.2687 15.5636 13.5999V13.5999Z"
			stroke="#FF9500"
			strokeWidth="1.5"
		/>
		<path
			d="M6.73594 5.68108L6.72921 5.67435C6.36275 5.30789 6.36275 4.71374 6.72921 4.34728L6.73594 4.34054L6.74268 4.3338C7.10914 3.96734 7.10914 3.3732 6.74268 3.00674L6.73594 3"
			stroke="#FF9500"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M9.47447 5.68108L9.46773 5.67435C9.10127 5.30789 9.10127 4.71374 9.46773 4.34728L9.47447 4.34054L9.48121 4.3338C9.84767 3.96734 9.84767 3.3732 9.48121 3.00674L9.47447 3"
			stroke="#FF9500"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M12.2135 5.68108L12.2067 5.67435C11.8403 5.30789 11.8403 4.71374 12.2067 4.34728L12.2135 4.34054L12.2202 4.3338C12.5867 3.96734 12.5867 3.3732 12.2202 3.00674L12.2135 3"
			stroke="#FF9500"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M9.47437 7.57959V10.3729"
			stroke="#FF9500"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M6.79321 8.41895V10.3727"
			stroke="#FF9500"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M12.1553 8.41895V10.3727"
			stroke="#FF9500"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default Bakery

import * as React from "react"

const Blog = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 18 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M9.79449 9.04093L9.04449 9.04095C9.0445 9.23986 9.12352 9.43063 9.26418 9.57127C9.40483 9.71192 9.5956 9.79093 9.79451 9.79093L9.79449 9.04093ZM9.79443 7.21904L9.04443 7.21906L9.79443 7.21904ZM10.0141 6.68868L9.48377 6.15835L10.0141 6.68868ZM15.1243 2.6391L16.1962 3.71094L17.2568 2.65028L16.185 1.57844L15.1243 2.6391ZM16.1962 3.71094L11.6162 8.29087L12.6769 9.35153L17.2568 4.7716L16.1962 3.71094ZM11.6162 8.29087L9.79447 8.29093L9.79451 9.79093L11.6163 9.79087L11.6162 8.29087ZM10.5445 9.0409L10.5444 7.21901L9.04443 7.21906L9.04449 9.04095L10.5445 9.0409ZM10.5444 7.21901L15.1243 2.6391L14.0637 1.57844L9.48377 6.15835L10.5444 7.21901ZM10.5444 7.21901L9.48377 6.15835C9.20245 6.43967 9.04442 6.82122 9.04443 7.21906L10.5444 7.21901ZM11.6162 8.29087L11.6162 8.29087L11.6163 9.79087C12.0141 9.79085 12.3956 9.63282 12.6769 9.35153L11.6162 8.29087ZM16.185 1.57844C15.5992 0.992656 14.6495 0.992657 14.0637 1.57844L15.1243 2.6391L15.1243 2.6391L16.185 1.57844ZM16.1962 3.71094L16.1962 3.71094L17.2568 4.7716C17.8426 4.18581 17.8426 3.23606 17.2568 2.65028L16.1962 3.71094Z"
			stroke={"none"}
		/>
		<path
			d="M9.03556 4.51123C9.44977 4.51123 9.78556 4.17544 9.78556 3.76123C9.78556 3.34702 9.44977 3.01123 9.03556 3.01123V4.51123ZM16.5711 9.03889C16.5711 8.62467 16.2353 8.28889 15.8211 8.28889C15.4069 8.28889 15.0711 8.62467 15.0711 9.03889H16.5711ZM9.03556 16.5784L8.45939 17.0585C8.60189 17.2295 8.81297 17.3284 9.03556 17.3284C9.25814 17.3284 9.46923 17.2295 9.61172 17.0585L9.03556 16.5784ZM7.15068 14.3165L7.72685 13.8364C7.58435 13.6654 7.37326 13.5665 7.15068 13.5665V14.3165ZM10.9204 14.3165V13.5665C10.6978 13.5665 10.4868 13.6654 10.3443 13.8364L10.9204 14.3165ZM3 13.5665V4.51123H1.5V13.5665H3ZM3 4.51123H9.03556V3.01123H3V4.51123ZM15.0711 9.03889V13.5665H16.5711V9.03889H15.0711ZM9.61172 16.0983L7.72685 13.8364L6.57451 14.7967L8.45939 17.0585L9.61172 16.0983ZM7.15068 13.5665H3V15.0665H7.15068V13.5665ZM15.0711 13.5665H10.9204V15.0665H15.0711V13.5665ZM10.3443 13.8364L8.45939 16.0983L9.61172 17.0585L11.4966 14.7967L10.3443 13.8364ZM1.5 13.5665C1.5 14.395 2.17157 15.0665 3 15.0665V13.5665H3H1.5ZM15.0711 13.5665V13.5665V15.0665C15.8995 15.0665 16.5711 14.395 16.5711 13.5665H15.0711ZM3 4.51123V4.51123V3.01123C2.17157 3.01123 1.5 3.6828 1.5 4.51123H3Z"
			stroke={"none"}
		/>
		<path
			d="M4.5 8.99219H7.33881"
			strokeWidth={1.5}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M4.5 6.33496H7.33881"
			strokeWidth={1.5}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M4.5 11.6475H13.5"
			strokeWidth={1.5}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default Blog

import * as React from "react"

const CaseOfTheMonth = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 18 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M12.5418 2.36426V8.0455C12.5418 10.0065 10.9562 11.5963 9.00016 11.5963C7.04415 11.5963 5.4585 10.0065 5.4585 8.0455L5.4585 2.36426L12.5418 2.36426Z"
			fill={"none"}
			strokeWidth={1.61905}
		/>
		<path
			d="M5.45833 2.36426L5.45833 8.73926C3.89353 8.73926 2.625 7.47073 2.625 5.90592L2.625 2.36426L5.45833 2.36426Z"
			fill={"none"}
			strokeWidth={1.61905}
			strokeLinejoin="round"
		/>
		<path
			d="M12.5417 2.36426L12.5417 8.73926C14.1065 8.73926 15.375 7.47073 15.375 5.90592V2.36426L12.5417 2.36426Z"
			fill={"none"}
			strokeWidth={1.61905}
			strokeLinejoin="round"
		/>
		<path
			d="M6.1665 15.6351H8.99984M11.8332 15.6351H8.99984M8.99984 15.6351V11.5962"
			fill={"none"}
			strokeWidth={1.61905}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default CaseOfTheMonth

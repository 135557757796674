import React from "react"


import {
	backOfficeStaffIconColor,
	backOfficeStaffItemHoverColor,
	backOfficeStaffItems,
	backOfficeStaffItemsKz
} from "../../navMenuData/backOfficeStaff"
import {
	getBusinessTypesLinks,
	businessTypesIconColor,
	businessTypesItemHoverColor,
} from "../../navMenuData/businessTypes"
import {
	guestsIconColor,
	guestsItemHoverColor,
	guestsItems,
	guestsItemsKz
} from "../../navMenuData/guests"
import {
	hallStaffIconColor,
	hallStaffItemHoverColor,
	hallStaffItems,
	hallStaffItemsKz
} from "../../navMenuData/hallStaff"
import {
	knowledgeBaseIconColor,
	knowledgeBaseItemHoverColor,
	getKnowledgeBaseItems,
} from "../../navMenuData/knowledgeBase"
import {
	managersItemHoverColor,
	managersItems,
	managersItemsKz,
	managersItemNameMobileHoverColor,
	managersTitleClassName,
	managersIconColor,
} from "../../navMenuData/managers"
import MenuItemCustomTile from "../MenuItemCustomTile"
import { pagesLinks } from "../../../../../pages-data/_V2/common/links"
import FranchiseIcon from "../../assets/FranchiseIcon"
import Restaurant from "../../assets/dropdownItemsIcons/Restaurant"
import Cafe from "../../assets/dropdownItemsIcons/Cafe"
import Bar from "../../assets/dropdownItemsIcons/Bar"
import Hookah from "../../assets/dropdownItemsIcons/Hookah"
import DarkKitchen from "../../assets/dropdownItemsIcons/DarkKitchen"
import Coffeehouse from "../../assets/dropdownItemsIcons/Coffeehouse"
import Refectory from "../../assets/dropdownItemsIcons/Refectory"
import FastfoodSmall from "../../assets/dropdownItemsIcons/FastfoodSmall"
import Confectionery from "../../assets/dropdownItemsIcons/Confectionery"
import Bakery from "../../assets/dropdownItemsIcons/Bakery"
import Pub from "../../assets/dropdownItemsIcons/Pub"
import Cookery from "../../assets/dropdownItemsIcons/Cookery"
import Foodtruck from "../../assets/dropdownItemsIcons/Foodtruck"
import Pizzeria from "../../assets/dropdownItemsIcons/Pizzeria"
import Sushi from "../../assets/dropdownItemsIcons/Sushi"
import Multiformat from "../../assets/dropdownItemsIcons/Multiformat"
import PublicEvents from "../../assets/dropdownItemsIcons/PublicEvents"
import {Locales} from "../../../../../localization/types";

const guests = {
	id: "guests",
	iconColor: guestsIconColor,
	itemHoverColor: guestsItemHoverColor,
	items: [
		guestsItems.indexWL,
		guestsItems.passkit,
		guestsItems.wlShop,
		guestsItems.line,
		guestsItems.customersDisplay,
	],
}

const guestsKz = {
	id: "guests",
	iconColor: guestsIconColor,
	itemHoverColor: guestsItemHoverColor,
	items: [
		guestsItemsKz.indexWL,
		guestsItemsKz.passkit,
		guestsItemsKz.wlShop,
		guestsItemsKz.line,
		guestsItemsKz.customersDisplay,
	],
}

const backOfficeStaff = {
	id: "backOfficeStaff",
	iconColor: backOfficeStaffIconColor,
	itemHoverColor: backOfficeStaffItemHoverColor,
	items: [
		backOfficeStaffItems.menu,
		backOfficeStaffItems.warehouse,
		backOfficeStaffItems.marketing,
		backOfficeStaffItems.reports,
		backOfficeStaffItems.staff,
	],
}

const backOfficeStaffKz = {
	id: "backOfficeStaff",
	iconColor: backOfficeStaffIconColor,
	itemHoverColor: backOfficeStaffItemHoverColor,
	items: [
		backOfficeStaffItemsKz.menu,
		backOfficeStaffItemsKz.warehouse,
		backOfficeStaffItemsKz.marketing,
		backOfficeStaffItemsKz.reports,
		backOfficeStaffItemsKz.staff,
	],
}

const managers = {
	id: "managers",
	iconColor: managersIconColor,
	titleClassName: managersTitleClassName,
	itemHoverColor: managersItemHoverColor,
	itemNameHoverClassName: managersItemNameMobileHoverColor,
	items: [
		managersItems.reportsNotifications,
		managersItems.indexQRM,
		managersItems.finance,
		{
			content: (
				<MenuItemCustomTile
					link={pagesLinks.franchise}
					icon={<FranchiseIcon />}
					title="Управление франшизой"
					text="Все инструменты"
				/>
			),
		},
	],
}

const managersKz = {
	id: "managers",
	iconColor: managersIconColor,
	titleClassName: managersTitleClassName,
	itemHoverColor: managersItemHoverColor,
	itemNameHoverClassName: managersItemNameMobileHoverColor,
	items: [
		managersItemsKz.reportsNotifications,
		managersItemsKz.indexQRM,
		managersItemsKz.finance,
		{
			content: (
				<MenuItemCustomTile
					link={pagesLinks.franchise}
					icon={<FranchiseIcon />}
					title="Управление франшизой"
					text="Все инструменты"
				/>
			),
		},
	],
}

const getBusinessTypes = (locale: Locales) => {
	const businessTypesLinks = getBusinessTypesLinks(locale);

	return {
		id: "businessTypes",
		titleClassName: businessTypesIconColor,
		itemHoverColor: businessTypesItemHoverColor,
		items: [
			{
				icon: <Restaurant />,
				...businessTypesLinks.restaurant,
			},
			{
				icon: <Cafe />,
				...businessTypesLinks.cafe,
			},
			{
				icon: <Coffeehouse />,
				...businessTypesLinks.coffeehouse,
			},
			{
				icon: <Bakery />,
				...businessTypesLinks.bakery,
			},
			{
				icon: <Hookah />,
				...businessTypesLinks.hookah,
			},
			{
				icon: <Confectionery />,
				...businessTypesLinks.confectionery,
			},
			{
				icon: <Pub />,
				...businessTypesLinks.pub,
			},
			{
				icon: <Bar />,
				...businessTypesLinks.bar,
			},
			{
				icon: <Refectory />,
				...businessTypesLinks.refectory,
			},
			{
				icon: <Cookery />,
				...businessTypesLinks.cookery,
			},
			{
				icon: <FastfoodSmall />,
				...businessTypesLinks.fastfood,
			},
			{
				icon: <Foodtruck />,
				...businessTypesLinks.foodtruck,
			},
			{
				icon: <Pizzeria />,
				...businessTypesLinks.pizzeria,
			},
			{
				icon: <Sushi />,
				...businessTypesLinks.sushi,
			},
			{
				icon: <PublicEvents />,
				...businessTypesLinks.publicEvents,
			},
			{
				icon: <DarkKitchen />,
				...businessTypesLinks.darkKitchen,
			},
		],
	}
}

const getKnowledgeBase = (locale: Locales) => {
	const knowledgeBaseItems = getKnowledgeBaseItems(locale);

	const name = {
		'ru-KZ': 'Справочник ресторатора',
		'kz-KZ': 'Справочник ресторатора',
		'ru-BY': 'Справочник ресторатора',
		'ru-RU': 'Справочник ресторатора',
		default: 'Справочник ресторатора'
	}

	return {
		id: "knowledgeBase",
		iconColor: knowledgeBaseIconColor,
		itemHoverColor: knowledgeBaseItemHoverColor,
		items: [
			knowledgeBaseItems.support,
			knowledgeBaseItems.webinars,
			knowledgeBaseItems.youtube,
			{
				...knowledgeBaseItems.demoCloud,
				name: "Демо продукта",
			},
			knowledgeBaseItems.blog,
			knowledgeBaseItems.tgRestoChanel,
			knowledgeBaseItems.service,
			{
				...knowledgeBaseItems.restoGuide,
				name: name[locale] || name.default,
			},
			knowledgeBaseItems.secretIngredient
		],
	}
}

const tiles = {
	franchise: {
		title: "Управление франшизой",
		link: pagesLinks.franchise,
	},
	price: {
		title: "Цены",
		link: pagesLinks.price,
	},
}

export const getData = (locale: Locales) => {
    if (locale === "ru-KZ") {
        const hallStaff = {
            id: "hallStaff",
            iconColor: hallStaffIconColor,
            itemHoverColor: hallStaffItemHoverColor,
            items: [
                hallStaffItems.terminal,
							  hallStaffItems.delivery,
                hallStaffItems.indexKDS,
            ],
        }

        return {
            solutions: [hallStaff, guests, backOfficeStaff, managers],
            hallStaff,
            guests,
            backOfficeStaff,
            managers,
            getBusinessTypes,
            getKnowledgeBase,
            tiles,
        }
    }

	if (locale === "kz-KZ") {

		const tiles = {

			franchise: {
				title: "Франшизаны басқару",
				link: pagesLinks.franchise,
			},
			price: {
				title: "Бағалар",
				link: pagesLinks.price,
			},
		}

		const hallStaff = {
			id: "hallStaff",
			iconColor: hallStaffIconColor,
			itemHoverColor: hallStaffItemHoverColor,
			items: [
				hallStaffItemsKz.terminal,
				hallStaffItemsKz.delivery,
				hallStaffItemsKz.indexKDS,
			],
		}

		return {
			solutions: [hallStaff, guestsKz, backOfficeStaffKz, managersKz],
			hallStaff,
			guests,
			backOfficeStaff,
			managers,
			getBusinessTypes,
			getKnowledgeBase,
			tiles,
		}
	}

    if (locale === "ru-BY") {
        const hallStaff = {
            id: "hallStaff",
            iconColor: hallStaffIconColor,
            itemHoverColor: hallStaffItemHoverColor,
            items: [
                hallStaffItems.terminal,
							  hallStaffItems.delivery,
                hallStaffItems.indexKDS,
            ],
        }

        return {
            solutions: [hallStaff, guests, backOfficeStaff, managers],
            hallStaff,
            guests,
            backOfficeStaff,
            managers,
            getBusinessTypes,
            getKnowledgeBase,
            tiles,
        }
    }

    const hallStaff = {
        id: "hallStaff",
        iconColor: hallStaffIconColor,
        itemHoverColor: hallStaffItemHoverColor,
        items: [
            hallStaffItems.terminal,
            hallStaffItems.delivery,
            hallStaffItems.indexKDS,
        ],
    }

    return {
        solutions: [hallStaff, guests, backOfficeStaff, managers],
        hallStaff,
        guests,
        backOfficeStaff,
        managers,
        getBusinessTypes,
        getKnowledgeBase,
        tiles,
    }
}

import * as React from "react"

const RestoGuide = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		{...props}
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M4 13.6614V5.86963H7.99976M20 11.2598L20 19.0518L15 19.0518C13.5563 19.0518 12.3509 20.0717 12.0649 21.4303M4 11.2598L4 19.0518L9.00002 19.0518C10.4437 19.0518 11.6491 20.0717 11.9351 21.4303M19.999 5.86963V11.2618M19.999 5.86963H15.9993M19.999 5.86963L15 5.86963C13.5021 5.86963 12.2607 6.96751 12.0366 8.40244"
			stroke="#00C7D9"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M12.0003 12.3281C14.1333 12.3281 15.8624 10.5989 15.8624 8.46588L15.0626 2.57129H8.93792L8.13806 8.46588C8.13806 10.5989 9.86722 12.3281 12.0003 12.3281Z"
			stroke="#00C7D9"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M12 12.8589V15.6059"
			stroke="#00C7D9"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M14.2332 15.6064L9.76819 15.6064"
			stroke="#00C7D9"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default RestoGuide

import * as React from "react"

const ApplicationAndWebsite = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 25 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<rect
			x={4.5}
			y={4}
			width={6}
			height={6}
			rx={1}
			strokeWidth={2}
			fill={"none"}
		/>
		<path
			d="M17.5 10a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
			strokeWidth={2}
			strokeMiterlimit={10}
			strokeLinecap="round"
			fill={"none"}
		/>
		<rect
			x={4.5}
			y={14}
			width={6}
			height={6}
			rx={1}
			strokeWidth={2}
			fill={"none"}
		/>
		<rect
			x={14.5}
			y={14}
			width={6}
			height={6}
			rx={1}
			strokeWidth={2}
			fill={"none"}
		/>
	</svg>
)

export default ApplicationAndWebsite

import * as React from "react"

const Refectory = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 18 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M8.641 2.388v3.375a2.625 2.625 0 1 1-5.25 0V2.388"
			strokeWidth={1.5}
			strokeLinecap="round"
			strokeLinejoin="round"
			fill={"none"}
		/>
		<path
			d="M6.016 8.861v6.75M6.016 2.388v3.203"
			strokeWidth={1.5}
			strokeLinecap="round"
			fill={"none"}
		/>
		<path
			d="M14.61 9.888h-3V5.116a2.727 2.727 0 0 1 2.726-2.728c.151 0 .273.123.273.273v7.227Z"
			strokeWidth={1.5}
			strokeLinejoin="round"
			fill={"none"}
		/>
		<path d="M14.61 8.861v6.75" strokeWidth={1.5} strokeLinecap="round" />
	</svg>
)

export default Refectory

import * as React from "react"

const Fastfood = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		width="14"
		height="15"
		viewBox="0 0 14 15"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M1.21143 8.14975C1.90278 6.54288 3.48167 5.41992 5.31883 5.41992C7.15599 5.41992 8.73487 6.54288 9.42623 8.14975"
			stroke="#FF9500"
			strokeWidth="1.5"
			strokeLinecap="round"
		/>
		<path
			d="M1 13.9995H9.6379"
			stroke="#FF9500"
			strokeWidth="1.5"
			strokeLinecap="round"
		/>
		<path
			d="M1 12.0497H9.6379"
			stroke="#FF9500"
			strokeWidth="1.5"
			strokeLinecap="round"
		/>
		<path
			d="M1 10.0989H9.6379"
			stroke="#FF9500"
			strokeWidth="1.5"
			strokeLinecap="round"
		/>
		<path
			d="M5.95947 3.60547H12.9998L11.8266 13.9999"
			stroke="#FF9500"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M9.44934 3.32076V1"
			stroke="#FF9500"
			strokeWidth="1.5"
			strokeLinecap="round"
		/>
	</svg>
)

export default Fastfood

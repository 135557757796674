import * as React from "react"

const Personnel = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M9 14.625c.786 1.048 1.69 1.5 3 1.5s2.214-.452 3-1.5"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
			fill={"none"}
		/>
		<circle cx={7.5} cy={10.125} r={1} />
		<path
			d="M21 11.062c0-2.82 0-4.233-.55-5.31a5.037 5.037 0 0 0-2.198-2.206C17.175 3 15.764 3 12.942 3h-1.889c-2.817 0-4.228 0-5.305.546a5.047 5.047 0 0 0-2.202 2.205C3 6.83 3 8.242 3 11.062v1.876c0 2.82 0 4.233.546 5.31a5.048 5.048 0 0 0 2.202 2.206C6.825 21 8.236 21 11.053 21h1.869c2.822 0 4.233 0 5.31-.546a5.038 5.038 0 0 0 2.198-2.205c.55-1.078.55-2.491.55-5.311l.02-1.876Z"
			strokeWidth={2}
			fill={"none"}
		/>
		<circle r={1} transform="matrix(-1 0 0 1 16.5 10.125)" />
	</svg>
)

export default Personnel

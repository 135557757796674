import * as React from "react"

const OnlineMenu = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<rect
			x={19}
			y={2.995}
			width={18}
			height={14}
			rx={2}
			transform="rotate(90 19 2.995)"
			strokeWidth={2}
			fill={"none"}
		/>
		<path
			d="M15 16.995H9M15 13.995H9"
			strokeWidth={2}
			strokeLinecap="round"
			fill={"none"}
		/>
		<path
			d="M12 6.995a3 3 0 0 0-3 3h6a3 3 0 0 0-3-3Z"
			strokeWidth={2}
			strokeMiterlimit={10}
			strokeLinecap="round"
			strokeLinejoin="round"
			fill={"none"}
		/>
		<path
			d="M12 6.995v-1"
			strokeWidth={2}
			strokeLinecap="round"
			fill={"none"}
		/>
	</svg>
)

export default OnlineMenu

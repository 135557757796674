import * as React from "react"

const Webinars = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 18 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<rect
			x={1.5}
			y={2.25}
			width={15}
			height={10.4355}
			rx={1.5}
			fill={"none"}
			strokeWidth={1.5}
		/>
		<path
			d="M5.08691 15C4.6727 15 4.33691 15.3358 4.33691 15.75C4.33691 16.1642 4.6727 16.5 5.08691 16.5V15ZM12.913 16.5C13.3272 16.5 13.663 16.1642 13.663 15.75C13.663 15.3358 13.3272 15 12.913 15V16.5ZM5.08691 16.5H12.913V15H5.08691V16.5Z"
			stroke={"none"}
		/>
		<path
			d="M9.75 12.75C9.75 12.3358 9.41421 12 9 12C8.58579 12 8.25 12.3358 8.25 12.75L9.75 12.75ZM8.25 15.75C8.25 16.1642 8.58579 16.5 9 16.5C9.41421 16.5 9.75 16.1642 9.75 15.75L8.25 15.75ZM8.25 12.75L8.25 15.75L9.75 15.75L9.75 12.75L8.25 12.75Z"
			stroke={"none"}
		/>
		<path
			d="M6.75244 9.35192H6.00244H6.75244ZM8.99995 10.4028V11.1528V10.4028ZM11.254 9.35192H12.004L12.004 9.34771L11.254 9.35192ZM11.254 9.34632L10.504 9.34631L10.504 9.35053L11.254 9.34632ZM5.08691 6.35685L4.7701 5.67705C4.50585 5.8002 4.33691 6.06532 4.33691 6.35685C4.33691 6.64838 4.50585 6.9135 4.7701 7.03665L5.08691 6.35685ZM8.99996 4.5332L9.31677 3.8534C9.11593 3.7598 8.88398 3.7598 8.68314 3.8534L8.99996 4.5332ZM12.913 6.35685L13.2298 7.03665C13.4941 6.9135 13.663 6.64838 13.663 6.35685C13.663 6.06532 13.4941 5.8002 13.2298 5.67705L12.913 6.35685ZM8.99996 8.1805L8.68314 8.8603C8.88398 8.9539 9.11593 8.9539 9.31677 8.8603L8.99996 8.1805ZM8.99995 9.65277C8.44594 9.65277 7.999 9.54882 7.71923 9.41676C7.38699 9.25991 7.50244 9.18736 7.50244 9.35192H6.00244C6.00244 10.0969 6.60547 10.5497 7.07889 10.7732C7.6048 11.0215 8.28161 11.1528 8.99995 11.1528V9.65277ZM10.504 9.35192C10.504 9.18512 10.6211 9.25824 10.285 9.41626C10.003 9.54883 9.55396 9.65277 8.99995 9.65277V11.1528C9.71831 11.1528 10.3963 11.0215 10.9232 10.7737C11.3961 10.5514 12.004 10.0991 12.004 9.35192H10.504ZM10.504 9.35053L10.504 9.35614L12.004 9.34771L12.004 9.34211L10.504 9.35053ZM10.504 7.16586L10.504 9.34631L12.004 9.34633L12.004 7.16588L10.504 7.16586ZM5.40373 7.03665L9.31677 5.213L8.68314 3.8534L4.7701 5.67705L5.40373 7.03665ZM8.68314 5.213L12.5962 7.03665L13.2298 5.67705L9.31677 3.8534L8.68314 5.213ZM12.5962 5.67705L8.68314 7.5007L9.31677 8.8603L13.2298 7.03665L12.5962 5.67705ZM9.31677 7.5007L5.40373 5.67705L4.7701 7.03665L8.68314 8.8603L9.31677 7.5007ZM7.50244 9.35192V7.16587H6.00244V9.35192H7.50244Z"
			stroke={"none"}
		/>
	</svg>
)

export default Webinars

import React from "react"
import classNames from "classnames"

import Menu from "../Menu"

import { getData } from "./data"

import styles from "./styles.module.scss"
import MenuItemCustomTile from "../MenuItemCustomTile"
import FranchiseIcon from "../../assets/FranchiseIcon"
import PriceIcon from "../../assets/PriceIcon"
import {useLocalizationContext} from "../../../../../localization/useLocalizationContext";
import {JsonLd} from "react-schemaorg";
import {ItemList, SiteNavigationElement} from "schema-dts";

interface Props {
	className?: string
}

export default function NavigationMobile(props: Props) {
	const localizationContext = useLocalizationContext();

    const data = getData(localizationContext.locale);

	const businessTypes = data.getBusinessTypes(localizationContext.locale);
	const knowledgeBase = data.getKnowledgeBase(localizationContext.locale);

	const solutionNames = {
		'ru-RU': "Решения",
		'ru-BY': "Решения",
		'ru-KZ': "Решения",
		'kz-KZ': "ШЕШІМДЕР",
	}
	const businessTypesNames = {
		'ru-RU': "Типы бизнеса",
		'ru-BY': "Типы бизнеса",
		'ru-KZ': "Типы бизнеса",
		'kz-KZ': "БИЗНЕС ТҮРЛЕРІ",
	}
	const knowledgeBaseNames = {
		'ru-RU': "База знаний",
		'ru-BY': "База знаний",
		'ru-KZ': "База знаний",
		'kz-KZ': "БІЛІМ ҚОРЫ",
	}

	return (
		<div className={classNames(props.className)}>
			<div className={styles.navigation__itemTitle}>{solutionNames[localizationContext.locale]}</div>
			<Menu composedData={data.solutions} className={styles.navigation__menu} />
			<div className={styles.navigation__itemTitle}>{businessTypesNames[localizationContext.locale]}</div>
			<Menu data={businessTypes} className={styles.navigation__menu} />
			<div className={styles.navigation__itemTitle}>{knowledgeBaseNames[localizationContext.locale]}</div>
			<Menu data={knowledgeBase} className={styles.navigation__menu} />

			<div className={styles.tilesList}>
				<MenuItemCustomTile
					vertical={true}
					className={styles.tiles}
					icon={<FranchiseIcon />}
					title={data.tiles.franchise.title}
					link={data.tiles.franchise.link}
				/>
				<MenuItemCustomTile
					vertical={true}
					className={styles.tiles}
					icon={<PriceIcon />}
					title={data.tiles.price.title}
					link={data.tiles.price.link}
				/>
			</div>
			<JsonLd<ItemList>
				item={{
					"@context": "https://schema.org",
					"@type": "ItemList",
					itemListElement: [
						{
							"@type": "ItemList",
							name: "Решения",
							itemListElement: [
								{
									"@type": "SiteNavigationElement",
									name: "Кассовый терминал",
									url: 'http://localhost'
								},
								{
									"@type": "SiteNavigationElement",
									name: "Кухонный экран повара",
									url: "http://localhost:8080"
								}
							]
						},
						{
							"@type": "SiteNavigationElement",
							name: "Цены",
							url: "/price"
						}
					]
				}}
			/>
		</div>
	)
}

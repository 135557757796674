import React, { useEffect, useState } from "react"
import classNames from "classnames"

import { pagesLinks } from "../../../pages-data/_V2/common/links"

// @ts-ignore
import { checkAuth } from "../../../apiRequests/auth"

import AdaptiveLink from "../AdaptiveLink"

import styles from "./styles.module.scss"

import ProfileIcon from "./assets/ProfileIcon"

import {useLocalizationContext} from "../../../localization/useLocalizationContext";

enum AUTH_STATUSES {
	PENDING = "PENDING",
	AUTHORIZED = "AUTHORIZED",
	NOT_AUTHORIZED = "NOT_AUTHORIZED",
}

interface Props {
	className?: string

	buttonClassName?: string

	profileIconClassName?: string
}

export default function SignIn(props: Props) {

	const localizationContext = useLocalizationContext()

	const [authState, setAuthState] = useState<AUTH_STATUSES>(
		AUTH_STATUSES.PENDING
	)

	useEffect(() => {
		const fetchAuthState = async () => {
			const isUserAuthorized = await checkAuth()

			setAuthState(
				isUserAuthorized
					? AUTH_STATUSES.AUTHORIZED
					: AUTH_STATUSES.NOT_AUTHORIZED
			)
		}

		fetchAuthState().catch(console.error)
	}, [])

	return (
		<div
			className={classNames(
				props.className,
				authState === AUTH_STATUSES.PENDING
					? styles.signIn__loader
					: styles.signIn
			)}
		>
			{authState === AUTH_STATUSES.AUTHORIZED ? (
				<AdaptiveLink
					className={classNames(
						styles.profile__link,
						props.profileIconClassName
					)}
					href={pagesLinks.personal.href}
				>
					<>
						<ProfileIcon className={classNames(styles.signIn__profileIcon)} />

						{localizationContext.locale === 'kz-KZ' ? pagesLinks.personal.textKz : pagesLinks.personal.text}
					</>
				</AdaptiveLink>
			) : (
				<AdaptiveLink
					className={classNames(styles.signIn__link, props.buttonClassName)}
					href={pagesLinks.login.href}
				>
					{localizationContext.locale === 'kz-KZ' ? pagesLinks.login.textKz : pagesLinks.login.text}
				</AdaptiveLink>
			)}
		</div>
	)
}

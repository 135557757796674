import * as React from "react"

const Foodtruck = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		width="19"
		height="18"
		viewBox="0 0 19 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M12.0658 9.86344V4L3.5083 4V9.86344"
			stroke="#FF9500"
			strokeWidth="1.5"
			strokeLinejoin="round"
		/>
		<path
			d="M12.0742 5.36723L14.0608 5.36719L16.4914 9.86517"
			stroke="#FF9500"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M3.5083 9.86377V13.7372H5.13222M14.8757 13.7372H16.4997V9.86377H10.004H6.75614H5.55222M9.06665 13.7372H10.004H10.9413"
			stroke="#FF9500"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<circle
			cx="7.04662"
			cy="13.5066"
			r="1.47875"
			stroke="#FF9500"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<circle
			cx="12.9612"
			cy="13.5066"
			r="1.47875"
			stroke="#FF9500"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M9.21648 6.19775V6.23715C9.21648 7.02638 8.57668 7.66619 7.78744 7.66619C6.9982 7.66619 6.3584 7.02638 6.3584 6.23715V6.19775"
			stroke="#FF9500"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M12.0739 6.19775V6.23715C12.0739 7.02638 11.4341 7.66619 10.6449 7.66619C9.85562 7.66619 9.21582 7.02638 9.21582 6.23715V6.19775"
			stroke="#FF9500"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M6.35808 6.19775V6.23715C6.35808 7.02638 5.71828 7.66619 4.92904 7.66619C4.1398 7.66619 3.5 7.02638 3.5 6.23715V6.19775"
			stroke="#FF9500"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default Foodtruck

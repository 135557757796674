import React, { useContext, useEffect } from "react"
import classNames from "classnames"

import Logo from "./assets/Logo"

import AdaptiveLink from "../AdaptiveLink"
import DropdownPopup from "./components/DropdownPopup"
import NavigationDesktop from "./components/NavigationDesktop"
import MobilePopup from "./components/MobilePopup"

import BurgerIcon from "./assets/BurgerIcon"
import ButtonsSet from "./components/ButtonsSet"

import { LocaleSwitcher } from "../LocaleSwitcher";

import { HeaderUIStateContext } from "./hooks/headerUIState"

import { getData } from "./data"

import styles from "./styles.module.scss"
import {useLocalizationContext} from "../../../localization/useLocalizationContext";

export enum HEADER_THEMES {
	DEFAULT = "DEFAULT",
	DARK = "DARK",
}
export interface HeaderProps {
	theme: HEADER_THEMES
	alwaysOnWhiteBackground?: boolean
}

export default function Header(props: HeaderProps) {
	const theme = props.theme || HEADER_THEMES.DEFAULT
	const isDarkTheme = theme === HEADER_THEMES.DARK
	const alwaysOnWhiteBackground = props.alwaysOnWhiteBackground || false
	const currentPathname =
		typeof window !== "undefined" ? window.location.pathname : ""

	const localizationContext = useLocalizationContext()
	const data = getData(localizationContext.locale)

	const {
		mobileHeaderExpanded,
		setMobileHeaderExpandState,
		isSticky,
		isPinnedToTheTop,
	} = useContext(HeaderUIStateContext)

	useEffect(() => {
		// На странице автоматизации есть меню,
		// прокрутка которого зависит от переменной window.automationMenuIndex.
		// Здесь она обнуляется для того,
		// чтобы при переходе на страницы автоматизации с других страниц,
		// первой в меню выводился именно тот пункт, на странице которой оказался пользователь
		if (!window.location.pathname.includes("automation")) {
			window.automationMenuIndex = null
		}
	}, [])

	//
	// Подкомпоненты, настолько маленькие, что их не хочется выносить в отдельные компоненты
	//
	const renderPhone = (
		onWhiteBackground: boolean,
		className?: string
	): JSX.Element => (
		<a
			className={classNames(className, styles.phoneLink, {
				[styles.__onWhiteBackground]: onWhiteBackground,
				[styles.phoneLink__kz]: localizationContext.locale === 'kz-KZ' || localizationContext.locale === 'ru-KZ'
			})}
			href={`tel:${data.phone.value}`}
		>
			{data.phone.text}
		</a>
	)

	return (
		<>
			<div className={styles.spacer} />

			<header
				className={classNames(
					styles.header,
					{
						[classNames(styles.__onWhiteBackground, styles.header__shadow)]:
							isSticky || mobileHeaderExpanded || alwaysOnWhiteBackground,
						[styles.header_theme_dark]: isDarkTheme,
					},
					{
						[styles.header__isPinnedToTheTop]: isSticky,
					}
				)}
			>
				<div className={styles.headerContent}>
					{currentPathname === data.indexPage.href ? (
						<span className={styles.header__logoLink}>
							<Logo className={styles.header__logoIcon} />
						</span>
					) : (
						<AdaptiveLink
							className={styles.header__logoLink}
							href={data.indexPage.href}
							useGatsbyLink={data.indexPage.useGatsbyLink}
							title={"Quick Resto программное обеспечение для ресторанов и кафе"}
						>
							<Logo className={styles.header__logoIcon} />
						</AdaptiveLink>
					)}

					<NavigationDesktop
						className={classNames(styles.header__navigationDesktop,
							{[styles.header__navigationDesktop__kz]: (localizationContext.locale == 'ru-KZ' || localizationContext.locale == 'kz-KZ') })}
						isDarkTheme={isDarkTheme}
					/>

					{(localizationContext.locale == 'ru-KZ' || localizationContext.locale == 'kz-KZ') && (
						<LocaleSwitcher
							className={styles.header__locale}
							isDarkTheme={isDarkTheme}
						/>
					)}

					{renderPhone(isSticky || isDarkTheme, styles.header__phone)}

					<ButtonsSet
						className={classNames(
							styles.header__buttonsDesktop,
							{[styles.header__buttonsDesktop__kz]: localizationContext.locale === 'ru-KZ' || localizationContext.locale === 'kz-KZ'})}
						onWhiteBackground={isSticky || isDarkTheme}
					/>

					<BurgerIcon
						onClick={() => setMobileHeaderExpandState(true)}
						className={styles.header__burgerIcon}
					/>

					<MobilePopup
						className={classNames(styles.header__mobilePopup, {
							[styles.__open]: mobileHeaderExpanded,
						})}
						phoneComponent={renderPhone(true)}
					/>
					<DropdownPopup className={styles.header__popup} />
				</div>
			</header>
		</>
	)
}

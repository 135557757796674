import React, {useContext} from "react"
import classNames from "classnames";

import {HeaderUIStateContext} from "../../hooks/headerUIState";
import {useLocalizationContext} from "../../../../../localization/useLocalizationContext";

import styles from "./styles.module.scss";
import { getData } from "../../data";


interface Props {

    className ?: string

}

export default function DropdownPopup(props: Props) {

    const localizationContext = useLocalizationContext()
    const data = getData(localizationContext.locale)

    const {
        isDropdownMenuOpen,
        selectedContentSection,
        setDropdownMenuOpenState,
    } = useContext(HeaderUIStateContext)

    const contentSectionSizes = selectedContentSection?.sizes

    return (
        <div
            className={classNames(
                styles.popupContainer,
                props.className,
                { [styles.__open]: isDropdownMenuOpen },
            )}
            onMouseLeave={(event ) => {
                setDropdownMenuOpenState(false)
            }}
        >
            <div className={classNames(
                styles.popup,
                { [styles.__open]: isDropdownMenuOpen },
            )}>

                <div
                    className={styles.popup__content}
                    style={
                        selectedContentSection !== null
                            ? {
                                width: contentSectionSizes!.width,
                                height: contentSectionSizes!.height,
                                transform: `translateX(${ contentSectionSizes!.left }px)`
                            }
                            : undefined
                    }
                >

                    {Object.values(data.contentSections).map(contentSection => (
                        <article
                            key={contentSection.name}
                            className={classNames(
                                styles.popup__contentSections,
                                { [styles.__selected]: contentSection.name == selectedContentSection?.name }
                            )}
                            data-is-popup={true}
                        >
                            {contentSection.element}
                        </article>
                    ))}
                </div>


                <div
                    className={styles.popup__background}
                    style={
                        selectedContentSection !== null
                            ? {
                                transform: `
                                translateX(${ contentSectionSizes!.left }px)
                                scaleX(${ contentSectionSizes!.width / 1320 })
                                scaleY(${ contentSectionSizes!.height / 710 })
                                `
                            }
                            : undefined
                    }
                />

            </div>
        </div>
    )

}

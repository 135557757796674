import * as React from "react"

const Finance = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="m13.75 5.942-.912-.41.912.41Zm4.838-1.77.976.219a1 1 0 0 0-.976-1.22v1Zm-.726 3.236-.976-.22a1 1 0 0 0 .332.985l.644-.765Zm1.502 1.9-.894.45a1 1 0 0 0 .894.55v-1Zm2.636 0h1a1 1 0 0 0-1-1v1Zm0 4.448.562.827a1 1 0 0 0 .438-.827h-1Zm-3.847 2.613-.562-.827a1 1 0 0 0-.424.991l.986-.164ZM18.758 20v1a1 1 0 0 0 .987-1.164l-.987.164Zm-4 0-.986.164a1 1 0 0 0 .986.836v-1Zm-.336-2.015.987-.164a1 1 0 0 0-1.056-.833l.07.997Zm-4.844 0 .069-.997a1 1 0 0 0-1.056.833l.987.164ZM9.242 20v1a1 1 0 0 0 .986-.836L9.242 20Zm-4 0-.987-.164A1 1 0 0 0 5.242 21v-1Zm.61-3.664.987.164a1 1 0 0 0-.295-.887l-.691.723ZM13.725 6v1a1 1 0 0 0 .912-.59L13.724 6Zm2.763-2.829a4 4 0 0 0-3.65 2.361l1.825.82a2 2 0 0 1 1.825-1.18v-2Zm2.102 0h-2.102v2h2.102v-2Zm.248 4.456.727-3.236-1.951-.439-.727 3.237 1.951.438Zm1.42 1.232a7.024 7.024 0 0 0-1.751-2.216l-1.288 1.53c.518.436.946.975 1.252 1.584l1.787-.898Zm-.893 1.449H22v-2h-2.636v2Zm1.636-1v4.448h2V9.308h-2Zm.438 3.62-3.847 2.614 1.124 1.654 3.847-2.613-1.124-1.654Zm-1.693 6.908-.606-3.632-1.972.33.605 3.63 1.973-.328ZM14.758 21h4v-2h-4v2Zm-1.322-2.85.336 2.014 1.973-.328-.336-2.015-1.973.329ZM14 19c.165 0 .33-.006.492-.017l-.139-1.995A5.086 5.086 0 0 1 14 17v2Zm-4 0h4v-2h-4v2Zm-.492-.017c.163.011.327.017.492.017v-2c-.119 0-.237-.004-.353-.012l-.139 1.995Zm.72 1.181.336-2.014-1.973-.329-.336 2.015 1.973.328ZM5.242 21h4v-2h-4v2Zm-.376-4.829-.61 3.665 1.972.328.61-3.664-1.972-.329ZM3 12c0 1.989.83 3.785 2.161 5.058l1.383-1.445A4.983 4.983 0 0 1 5 12H3Zm7-7a7 7 0 0 0-7 7h2a5 5 0 0 1 5-5V5Zm3.724 0H10v2h3.724V5Zm-.886.532-.026.058 1.824.82.026-.058-1.824-.82Z"
			stroke={"none"}
		/>
		<circle cx={15.427} cy={10.558} r={1} fill="#1BC594" />
		<path d="M3.573 12a2 2 0 0 1-2-2" strokeWidth={2} strokeLinecap="round" />
	</svg>
)

export default Finance

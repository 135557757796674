import React from "react"

import Menu from "../../components/Menu"

import styles from "./styles.module.scss"

import { getData } from "./data"
import {useLocalizationContext} from "../../../../../localization/useLocalizationContext";

export default function Solutions() {
    const localizationContext = useLocalizationContext()

    const data = getData(localizationContext.locale);

	return (
		<div className={styles.container}>
			<Menu data={data.hallStaff} />
			<Menu data={data.guests} />
			<Menu data={data.backOfficeStaff} />
			<Menu data={data.managers} />
		</div>
	)
}

import React from "react"
import classNames from "classnames"

import styles from "./styles.module.scss"

interface Props {
	desktop?: string | JSX.Element
	tablet?: string | JSX.Element
	mobile?: string | JSX.Element
	noWrap?: boolean

	className?: string
	titleClasses?: string
	children: JSX.Element | JSX.Element[]

	noBreakWordsMobile?: boolean
}

// Компонент для адаптации текста под desktop, tablet, mobile
// В children должен принимать JSX для desktop
// Возвращает div с children JSX и доп заголовки для tablet & mobile

export default function AdaptiveWrapText(props: Props) {
	return (
		<div>
			{/* <span className={classNames(
                    styles.adaptiveText,
                    styles.__desktop,
                    styles.__withPreWrap,
                    props.className,
                )}>
                    {props.desktop}
                </span> */}

			{React.Children.map(props.children, child =>
				React.cloneElement(child as React.ReactElement<any>, {
					className: `${classNames(
						props.titleClasses,
						styles.adaptiveText,
						styles.__desktop,
						{[styles.__withPreWrap]: !props.noWrap},
						props.className
					)}`,
				})
			)}

			{/* текст одинаковый и не должен индексироваться 3 раза */}
			<noindex>
				<div className={props.titleClasses}>
					<span
						className={classNames(
							styles.adaptiveText,
							styles.__tablet,
							{ [styles.__withPreWrap]: props.tablet },
							props.className
						)}
					>
						{props.tablet || props.desktop}
					</span>

					<span
						className={classNames(
							styles.adaptiveText,
							styles.__mobile,
							{
								[styles.__withPreWrap]: props.mobile,
								[styles.__noBreakWords]: props.noBreakWordsMobile
							},
							props.className
						)}
					>
						{props.mobile || props.desktop}
					</span>
				</div>
			</noindex>
		</div>
	)
}

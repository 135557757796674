import * as React from "react"

const MenuRouteCards = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M2 16.005v-1a1 1 0 0 0-1 1h1Zm20 0h1a1 1 0 0 0-1-1v1Zm-20 1h20v-2H2v2Zm19-1v2h2v-2h-2Zm-1 3H4v2h16v-2Zm-17-1v-2H1v2h2Zm1 1a1 1 0 0 1-1-1H1a3 3 0 0 0 3 3v-2Zm17-1a1 1 0 0 1-1 1v2a3 3 0 0 0 3-3h-2ZM21 12.563a1 1 0 1 0 2 0h-2Zm-20 0a1 1 0 1 0 2 0H1ZM20 4v1-1Zm2 2h-1 1ZM2 6H1h1Zm2-2V3v1Zm-1 8.563V6H1v6.563h2ZM4 5h16V3H4v2Zm17 1v6.563h2V6h-2Zm-1-1a1 1 0 0 1 1 1h2a3 3 0 0 0-3-3v2ZM3 6a1 1 0 0 1 1-1V3a3 3 0 0 0-3 3h2Z"
			stroke={"none"}
		/>
		<path
			d="M16.996 7.005 13 11.002l-1.998-1.999L7.004 13"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default MenuRouteCards

import * as React from "react"

const LeftVignette = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		width={16}
		height={16}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16 16V0A16.001 16.001 0 0 1 0 16h16Z"
			fill="#fff"
		/>
	</svg>
)

export default LeftVignette

import * as React from "react"

const Cafe = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 19 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M13.25 4.125h1.875a1.875 1.875 0 0 1 0 3.75H13.25v-3.75ZM3.5 4.125h9.75V9A4.875 4.875 0 1 1 3.5 9V4.125Z"
			strokeWidth={1.5}
			strokeLinejoin="round"
			fill={"none"}
		/>
	</svg>
)

export default Cafe

import * as React from "react"

const PublicEvents = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		width="14"
		height="18"
		viewBox="0 0 14 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<ellipse
			cx="6.99998"
			cy="7.21431"
			rx="5.71433"
			ry="3.57143"
			stroke="#FF9600"
			strokeWidth="1.5"
		/>
		<ellipse
			cx="6.99998"
			cy="7.21431"
			rx="5.71433"
			ry="3.57143"
			stroke="#FF9600"
			strokeWidth="1.5"
		/>
		<path
			d="M1.28564 7.21436V12.9286C1.28564 14.9011 3.84404 16.5001 6.99998 16.5001C10.1559 16.5001 12.7143 14.9011 12.7143 12.9286V7.21436"
			stroke="#FF9600"
			strokeWidth="1.5"
			strokeLinecap="round"
		/>
		<ellipse
			cx="6.9996"
			cy="9.35728"
			rx="2.28573"
			ry="1.42857"
			stroke="#FF9600"
			strokeWidth="1.5"
		/>
		<path
			d="M7 3.64286V1.5"
			stroke="#FF9600"
			strokeWidth="1.5"
			strokeLinecap="round"
		/>
		<path
			d="M2.71423 4.35716V2.92859"
			stroke="#FF9600"
			strokeWidth="1.5"
			strokeLinecap="round"
		/>
		<path
			d="M11.2858 4.35716V2.92859"
			stroke="#FF9600"
			strokeWidth="1.5"
			strokeLinecap="round"
		/>
	</svg>
)

export default PublicEvents

import { contacts, contactsBy, contactsKz } from '../../../pages-data/_V2/common/contacts'
import { pagesLinks, pagesLinksKz, externalLinks, PageLinkItem }  from '../../../pages-data/_V2/common/links'
import { Locales } from '../../../localization/types'


const getNotRuLocaleBusinessTypesLink = (linkType: keyof typeof pagesLinks.businessTypes) => {
  const linkInfo: PageLinkItem = pagesLinks.businessTypes[linkType];

  return {
    ...linkInfo,
    href: `${externalLinks.quickrestoRU.href}${linkInfo.href.slice(1)}`,
    useGatsbyLink: false,
    target: '_blank'
  } as PageLinkItem;
}

const getKzBusinessTypesLink = (linkType: keyof typeof pagesLinks.businessTypes) => {
  const linkInfo: PageLinkItem = pagesLinksKz.businessTypes[linkType];

  return {
    ...linkInfo,
    href: `${externalLinks.quickrestoRU.href}${linkInfo.href.slice(1)}`,
    useGatsbyLink: false,
    target: '_blank'
  } as PageLinkItem;
}

export const getData = (locale: Locales) => {
  if (locale === 'kz-KZ') {
    return {
      companyInfo: {
        title: 'Компания',
        links: [
          {
            ...pagesLinksKz.support,
            href: `${externalLinks.quickrestoRU.href}${pagesLinks.support.href.slice(1)}`,
            useGatsbyLink: false,
            target: '_blank'
          },
          pagesLinksKz.capability.index,
          pagesLinksKz.bo,
          pagesLinksKz.price.index,
          pagesLinksKz.discounts.index,
          {
            text: pagesLinksKz.shop.index.text,
            href: `${externalLinks.quickrestoRU.href}${pagesLinks.shop.index.href.slice(1)}`,
            useGatsbyLink: false,
            target: '_blank'
          },
          {
            ...pagesLinksKz.partners,
            target: '_blank'
          },
          {
            ...pagesLinksKz.blog,
            target: '_blank'
          },
        ],
      },
      businessTypes: {
        title: 'Бизнес түрлері',
        links: [
          getKzBusinessTypesLink('bar'),
          getKzBusinessTypesLink('cafe'),
          getKzBusinessTypesLink('cookery'),
          getKzBusinessTypesLink('restaurant'),
          getKzBusinessTypesLink('refectory'),
          getKzBusinessTypesLink('fastfood'),
          getKzBusinessTypesLink('foodtruck'),
          getKzBusinessTypesLink('coffeehouse'),
        ],
      },
      clientsInfo: {
        title: 'Клиенттерге',
        links: [
          pagesLinksKz.about,
          {
            text: 'Біздің қосымшалар',
            href: externalLinks.appstoreAllApps,
            useGatsbyLink: false,
          },
          pagesLinksKz.integrations,
          {
            text: pagesLinksKz.api.text,
            href: `${externalLinks.quickrestoRU.href}${pagesLinks.api.href.slice(1)}`,
            useGatsbyLink: false,
            target: '_blank'
          },
          pagesLinksKz.contacts,
          pagesLinksKz.deliveryAndPayment,
        ],
      },
      email: contactsKz.supportEmail,
      phone: {
        title: contactsKz.phone.text,
        value: contactsKz.phone.value,
      },
      vk: {
        href: externalLinks.vk,
        ariaLabel: 'VK',
      },
      youtube: {
        href: externalLinks.youtube,
        ariaLabel: 'Youtube',
      },
      oneC: {
        link: externalLinks.oneC,
        alt: 'Интеграция 1С и Quick Resto',
      },
      skolkovo: {
        link: externalLinks.skolkovo,
        alt: 'Фонд Сколково',
      },
      appstore: {
        href: externalLinks.appstoreAllApps,
        ariaLabel: 'Appstore',
      },
      googleplay: {
        link: externalLinks.googlePlayClientsCrm,
        alt: 'Google Play Market',
      },
      microsoftStore: {
        link: 'https://quickresto.ru/support/wt/wt_setup/',
        alt: 'Microsoft Store',
      },
      copyRight: `© ${new Date().getFullYear()} Quick Resto`,
      copyRightYear: `${new Date().getFullYear()}`,
      copyRightCompany: `Quick Resto`,
      licenseAgreement: pagesLinksKz.lisenceAgreementKz as PageLinkItem,
      privacyPolicy: pagesLinksKz.privacyPolicyNotRu as PageLinkItem,
    }
  } else if (locale === 'ru-KZ') {
    return {
      companyInfo: {
        title: 'Компания',
        links: [
          {
            ...pagesLinks.support,
            href: `${externalLinks.quickrestoRU.href}${pagesLinks.support.href.slice(1)}`,
            useGatsbyLink: false,
            target: '_blank'
          },
          pagesLinks.capability.index,
          pagesLinks.bo,
          pagesLinks.price.index,
          pagesLinks.discounts.index,
          {
            text: pagesLinks.shop.index.text,
            href: `${externalLinks.quickrestoRU.href}${pagesLinks.shop.index.href.slice(1)}`,
            useGatsbyLink: false,
            target: '_blank'
          },
          {
            ...pagesLinks.partners,
            target: '_blank'
          },
          {
            ...pagesLinks.blog,
            target: '_blank'
          },
        ],
      },
      businessTypes: {
        title: 'Типы бизнеса',
        links: [
          getNotRuLocaleBusinessTypesLink('bar'),
          getNotRuLocaleBusinessTypesLink('cafe'),
          getNotRuLocaleBusinessTypesLink('cookery'),
          getNotRuLocaleBusinessTypesLink('restaurant'),
          getNotRuLocaleBusinessTypesLink('refectory'),
          getNotRuLocaleBusinessTypesLink('fastfood'),
          getNotRuLocaleBusinessTypesLink('foodtruck'),
          getNotRuLocaleBusinessTypesLink('coffeehouse'),
        ],
      },
      clientsInfo: {
        title: 'Клиентам',
        links: [
          pagesLinks.about,
          {
            text: 'Наши приложения',
            href: externalLinks.appstoreAllApps,
            useGatsbyLink: false,
          },
          pagesLinks.integrations,
          {
            text: pagesLinks.api.text,
            href: `${externalLinks.quickrestoRU.href}${pagesLinks.api.href.slice(1)}`,
            useGatsbyLink: false,
            target: '_blank'
          },
          pagesLinks.contacts,
          pagesLinks.deliveryAndPayment,
        ],
      },
      email: contactsKz.supportEmail,
      phone: {
        title: contactsKz.phone.text,
        value: contactsKz.phone.value,
      },
      vk: {
        href: externalLinks.vk,
        ariaLabel: 'VK',
      },
      youtube: {
        href: externalLinks.youtube,
        ariaLabel: 'Youtube',
      },
      oneC: {
        link: externalLinks.oneC,
        alt: 'Интеграция 1С и Quick Resto',
      },
      skolkovo: {
        link: externalLinks.skolkovo,
        alt: 'Фонд Сколково',
      },
      appstore: {
        href: externalLinks.appstoreAllApps,
        ariaLabel: 'Appstore',
      },
      googleplay: {
        link: externalLinks.googlePlayClientsCrm,
        alt: 'Google Play Market',
      },
      microsoftStore: {
        link: 'https://quickresto.ru/support/wt/wt_setup/',
        alt: 'Microsoft Store',
      },
      copyRight: `© ${new Date().getFullYear()} Quick Resto`,
      copyRightYear: `${new Date().getFullYear()}`,
      copyRightCompany: `Quick Resto`,
      licenseAgreement: pagesLinks.lisenceAgreementNotRu as PageLinkItem,
      privacyPolicy: pagesLinks.privacyPolicyNotRu as PageLinkItem,
    }
  } else if (locale === 'ru-BY') {
    return {
      companyInfo: {
        title: 'Компания',
        links: [
          {
            ...pagesLinks.support,
            href: `${externalLinks.quickrestoRU.href}${pagesLinks.support.href.slice(1)}`,
            useGatsbyLink: false,
            target: '_blank'
          },
          pagesLinks.capability.index,
          pagesLinks.bo,
          pagesLinks.price.index,
          pagesLinks.discounts.index,
          {
            text: pagesLinks.shop.index.text,
            href: `${externalLinks.quickrestoRU.href}${pagesLinks.shop.index.href.slice(1)}`,
            useGatsbyLink: false,
            target: '_blank'
          },
          {
            ...pagesLinks.partners,
            target: '_blank'
          },
          {
            ...pagesLinks.blog,
            target: '_blank'
          },
        ],
      },
      businessTypes: {
        title: 'Типы бизнеса',
        links: [
          getNotRuLocaleBusinessTypesLink('bar'),
          getNotRuLocaleBusinessTypesLink('cafe'),
          getNotRuLocaleBusinessTypesLink('cookery'),
          getNotRuLocaleBusinessTypesLink('restaurant'),
          getNotRuLocaleBusinessTypesLink('refectory'),
          getNotRuLocaleBusinessTypesLink('fastfood'),
          getNotRuLocaleBusinessTypesLink('foodtruck'),
          getNotRuLocaleBusinessTypesLink('coffeehouse'),
        ],
      },
      clientsInfo: {
        title: 'Клиентам',
        links: [
          pagesLinks.about,
          {
            text: 'Наши приложения',
            href: externalLinks.appstoreAllApps,
            useGatsbyLink: false,
          },
          pagesLinks.integrations,
          {
            text: pagesLinks.api.text,
            href: `${externalLinks.quickrestoRU.href}${pagesLinks.api.href.slice(1)}`,
            useGatsbyLink: false,
            target: '_blank'
          },
          pagesLinks.contacts,
          pagesLinks.deliveryAndPayment,
        ],
      },
      email: contactsBy.infoEmail,
      phone: {
        title: contactsBy.phone.text,
        value: contactsBy.phone.value,
      },
      vk: {
        href: externalLinks.vk,
        ariaLabel: 'VK',
      },
      youtube: {
        href: externalLinks.youtube,
        ariaLabel: 'Youtube',
      },
      oneC: {
        link: externalLinks.oneC,
        alt: 'Интеграция 1С и Quick Resto',
      },
      skolkovo: {
        link: externalLinks.skolkovo,
        alt: 'Фонд Сколково',
      },
      appstore: {
        href: externalLinks.appstoreAllApps,
        ariaLabel: 'Appstore',
      },
      googleplay: {
        link: externalLinks.googlePlayClientsCrm,
        alt: 'Google Play Market',
      },
      microsoftStore: {
        link: 'https://quickresto.ru/support/wt/wt_setup/',
        alt: 'Microsoft Store',
      },
      copyRight: `© ${new Date().getFullYear()} Quick Resto`,
      copyRightYear: `${new Date().getFullYear()}`,
      copyRightCompany: `Quick Resto`,
      licenseAgreement: pagesLinks.lisenceAgreementBy as PageLinkItem,
      privacyPolicy: pagesLinks.privacyPolicyBy as PageLinkItem,
    }
  }

  return {
    companyInfo: {
      title: 'Компания',
      links: [
        pagesLinks.support,
        pagesLinks.capability.index,
        pagesLinks.bo,
        pagesLinks.price.index,
        pagesLinks.discounts.index,
        pagesLinks.shop.index,
        pagesLinks.partners,
        pagesLinks.blog,
      ],
    },
    businessTypes: {
      title: 'Типы бизнеса',
      links: [
        pagesLinks.businessTypes.bar,
        pagesLinks.businessTypes.cafe,
        pagesLinks.businessTypes.cookery,
        pagesLinks.businessTypes.restaurant,
        pagesLinks.businessTypes.refectory,
        pagesLinks.businessTypes.fastfood,
        pagesLinks.businessTypes.foodtruck,
        pagesLinks.businessTypes.coffeehouse,
      ],
    },
    clientsInfo: {
      title: 'Клиентам',
      links: [
        pagesLinks.about,
        {
          text: 'Наши приложения',
          href: externalLinks.appstoreAllApps,
          useGatsbyLink: false,
        },
        pagesLinks.integrations,
        pagesLinks.api,
        pagesLinks.contacts,
        pagesLinks.deliveryAndPayment,
      ],
    },
    email: contacts.supportEmail,
    phone: {
      title: contacts.phone.text,
      value: contacts.phone.value,
    },
    vk: {
      href: externalLinks.vk,
      ariaLabel: 'VK',
    },
    youtube: {
      href: externalLinks.youtube,
      ariaLabel: 'Youtube',
    },
    oneC: {
      link: externalLinks.oneC,
      alt: 'Интеграция 1С и Quick Resto',
    },
    skolkovo: {
      link: externalLinks.skolkovo,
      alt: 'Фонд Сколково',
    },
    appstore: {
      href: externalLinks.appstoreAllApps,
      ariaLabel: 'Appstore',
    },
    googleplay: {
      link: externalLinks.googlePlayClientsCrm,
      alt: 'Google Play Market',
    },
    microsoftStore: {
      link: 'https://quickresto.ru/support/wt/wt_setup/',
      alt: 'Microsoft Store',
    },
    copyRight: `© ${new Date().getFullYear()} Quick Resto`,
    copyRightYear: `${new Date().getFullYear()}`,
    copyRightCompany: `Quick Resto`,
    licenseAgreement: pagesLinks.lisenceAgreement as PageLinkItem,
    privacyPolicy: pagesLinks.privacyPolicy as PageLinkItem,
  }
}

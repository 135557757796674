// Ссылки на типы бизнеса вынесены в отдельный файл для консистентности,
// потому что используются в 2 разных компонентах меню,
// в `/contentSections/Solutions` и в `NavigationMobile`

import { pagesLinks, externalLinks } from '../../../../pages-data/_V2/common/links'
import type { PageLinkItem } from '../../../../pages-data/_V2/common/links'
import colors from '../../../../styles/_V2/export.module.scss'
import {Locales} from "../../../../localization/types";

export const businessTypesIconColor = colors.orange
export const businessTypesItemHoverColor = colors.orangeLightBg

const getNotRuLocaleLinkProps = (linkType: keyof typeof pagesLinks.businessTypes) => {
  const linkInfo: PageLinkItem = pagesLinks.businessTypes[linkType];

  return {
    ...linkInfo,
    href: `${externalLinks.quickrestoRU.href}${linkInfo.href.slice(1)}`,
    useGatsbyLink: false,
    target: '_blank'
  }
}

export const getBusinessTypesLinks = (locale: Locales) => {
  if (locale === 'kz-KZ') {
    return {
      restaurant: {
        name: 'Мейрамхана',
        link: getNotRuLocaleLinkProps('restaurant')
      },
      cafe: {
        name: 'Кафе',
        link: getNotRuLocaleLinkProps('cafe')
      },
      gastrobar: {
        name: 'Гастробар',
        link: getNotRuLocaleLinkProps('gastrobar'),
      },
      bar: {
        name: 'Бар',
        link: getNotRuLocaleLinkProps('bar'),
      },
      pub: {
        name: 'Паб',
        link: getNotRuLocaleLinkProps('pub'),
      },
      hookah: {
        name: 'Қорқорға арналған',
        link: getNotRuLocaleLinkProps('hookah'),
      },
      forAdults: {
        name: '18+',
        link: getNotRuLocaleLinkProps('forAdults'),
      },
      stadium: {
        name: 'Стадион',
        link: getNotRuLocaleLinkProps('stadium'),
      },
      festival: {
        name: 'Фестиваль',
        link: getNotRuLocaleLinkProps('festival'),
      },
      darkKitchen: {
        name: 'Дарк китчен',
        link: getNotRuLocaleLinkProps('darkKitchen'),
      },
      coffeehouse: {
        name: 'Кофехана',
        link: getNotRuLocaleLinkProps('coffeehouse'),
      },
      bakery: {
        name: 'Наубайхана',
        link: getNotRuLocaleLinkProps('bakery'),
      },
      confectionery: {
        name: 'Кондитерлік',
        link: getNotRuLocaleLinkProps('confectionery'),
      },
      carWash: {
        name: 'Автомойка',
        link: getNotRuLocaleLinkProps('carWash'),
      },
      multiformats: {
        name: 'Мультиформат',
        link: getNotRuLocaleLinkProps('multiformats'),
      },
      refectory: {
        name: 'Асхана',
        link: getNotRuLocaleLinkProps('refectory'),
      },
      cookery: {
        name: 'Аспаздық',
        link: getNotRuLocaleLinkProps('cookery'),
      },
      pizzeria: {
        name: 'Пиццерия',
        link: getNotRuLocaleLinkProps('pizzeria'),
      },
      sushi: {
        name: 'Суши',
        link: getNotRuLocaleLinkProps('sushi'),
      },
      fastfood: {
        name: 'Фастфуд',
        link: getNotRuLocaleLinkProps('fastfood')
      },
      foodtruck: {
        name: 'Фудтрак',
        link: getNotRuLocaleLinkProps('foodtruck')
      },
      publicEvents: {
        name: 'Көпшілік іс-шаралар',
        link: getNotRuLocaleLinkProps('stadiums')
      },
    }
  } else if (locale !== 'ru-RU') {
    return {
      restaurant: {
        name: 'Ресторан',
        link: getNotRuLocaleLinkProps('restaurant')
      },
      cafe: {
        name: 'Кафе',
        link: getNotRuLocaleLinkProps('cafe')
      },
      gastrobar: {
        name: 'Гастробар',
        link: getNotRuLocaleLinkProps('gastrobar'),
      },
      bar: {
        name: 'Бар',
        link: getNotRuLocaleLinkProps('bar'),
      },
      pub: {
        name: 'Паб',
        link: getNotRuLocaleLinkProps('pub'),
      },
      hookah: {
        name: 'Кальянная',
        link: getNotRuLocaleLinkProps('hookah'),
      },
      forAdults: {
        name: '18+',
        link: getNotRuLocaleLinkProps('forAdults'),
      },
      stadium: {
        name: 'Стадион',
        link: getNotRuLocaleLinkProps('stadium'),
      },
      festival: {
        name: 'Фестиваль',
        link: getNotRuLocaleLinkProps('festival'),
      },
      darkKitchen: {
        name: 'Дарк китчен',
        link: getNotRuLocaleLinkProps('darkKitchen'),
      },
      coffeehouse: {
        name: 'Кофейня',
        link: getNotRuLocaleLinkProps('coffeehouse'),
      },
      bakery: {
        name: 'Пекарня',
        link: getNotRuLocaleLinkProps('bakery'),
      },
      confectionery: {
        name: 'Кондитерская',
        link: getNotRuLocaleLinkProps('confectionery'),
      },
      carWash: {
        name: 'Автомойка',
        link: getNotRuLocaleLinkProps('carWash'),
      },
      multiformats: {
        name: 'Мультиформат',
        link: getNotRuLocaleLinkProps('multiformats'),
      },
      refectory: {
        name: 'Столовая',
        link: getNotRuLocaleLinkProps('refectory'),
      },
      cookery: {
        name: 'Кулинария',
        link: getNotRuLocaleLinkProps('cookery'),
      },
      pizzeria: {
        name: 'Пиццерия',
        link: getNotRuLocaleLinkProps('pizzeria'),
      },
      sushi: {
        name: 'Суши',
        link: getNotRuLocaleLinkProps('sushi'),
      },
      fastfood: {
        name: 'Фастфуд',
        link: getNotRuLocaleLinkProps('fastfood')
      },
      foodtruck: {
        name: 'Фудтрак',
        link: getNotRuLocaleLinkProps('foodtruck')
      },
      publicEvents: {
        name: 'Массовые мероприятия',
        link: getNotRuLocaleLinkProps('stadiums')
      },
    }
  }

  return {
    restaurant: {
      name: 'Ресторан',
      link: pagesLinks.businessTypes.restaurant,
    },
    cafe: {
      name: 'Кафе',
      link: pagesLinks.businessTypes.cafe,
    },
    gastrobar: {
      name: 'Гастробар',
      link: pagesLinks.businessTypes.gastrobar,
    },
    bar: {
      name: 'Бар',
      link: pagesLinks.businessTypes.bar,
    },
    pub: {
      name: 'Паб',
      link: pagesLinks.businessTypes.pub,
    },
    hookah: {
      name: 'Кальянная',
      link: pagesLinks.businessTypes.hookah,
    },
    forAdults: {
      name: '18+',
      link: pagesLinks.businessTypes.forAdults,
    },
    stadium: {
      name: 'Стадион',
      link: pagesLinks.businessTypes.stadium,
    },
    festival: {
      name: 'Фестиваль',
      link: pagesLinks.businessTypes.festival,
    },
    darkKitchen: {
      name: 'Дарк китчен',
      link: pagesLinks.businessTypes.darkKitchen,
    },
    coffeehouse: {
      name: 'Кофейня',
      link: pagesLinks.businessTypes.coffeehouse,
    },
    bakery: {
      name: 'Пекарня',
      link: pagesLinks.businessTypes.bakery,
    },
    confectionery: {
      name: 'Кондитерская',
      link: pagesLinks.businessTypes.confectionery,
    },
    carWash: {
      name: 'Автомойка',
      link: pagesLinks.businessTypes.carWash,
    },
    multiformats: {
      name: 'Мультиформат',
      link: pagesLinks.businessTypes.multiformats,
    },
    refectory: {
      name: 'Столовая',
      link: pagesLinks.businessTypes.refectory,
    },
    cookery: {
      name: 'Кулинария',
      link: pagesLinks.businessTypes.cookery,
    },
    pizzeria: {
      name: 'Пиццерия',
      link: pagesLinks.businessTypes.pizzeria,
    },
    sushi: {
      name: 'Суши',
      link: pagesLinks.businessTypes.sushi,
    },
    fastfood: {
      name: 'Фастфуд',
      link: pagesLinks.businessTypes.fastfood
    },
    foodtruck: {
      name: 'Фудтрак',
      link: pagesLinks.businessTypes.foodtruck
    },
    publicEvents: {
      name: 'Массовые мероприятия',
      link: pagesLinks.businessTypes.stadiums
    },
  }

}

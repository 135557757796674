import React from "react"

import { MenuData } from "../../components/Menu"
import MenuItemDesktopCustomTile from "../../components/MenuItemDesktopCustomTile"

import {
	hallStaffIconColor,
	hallStaffItemHoverColor,
	hallStaffItems,
    hallStaffItemsKz
} from "../../navMenuData/hallStaff"
import {
	guestsIconColor,
	guestsItemHoverColor,
	guestsItems,
	guestsItemsKz,
} from "../../navMenuData/guests"
import {
	backOfficeStaffIconColor,
	backOfficeStaffItemHoverColor,
	backOfficeStaffItems,
	backOfficeStaffItemsKz,
} from "../../navMenuData/backOfficeStaff"
import {
	managersItemHoverColor,
	managersItems,
	managersItemsKz,
	managersIconColor,
	managersTitleClassName,
} from "../../navMenuData/managers"

import { pagesLinks } from "../../../../../pages-data/_V2/common/links";
import FranchiseIcon from "../../assets/FranchiseIcon";
import {Locales} from "../../../../../localization/types";

export const getData = (locale: Locales) => {
    if (locale === "ru-KZ") {
        return ({
            hallStaff: {
                id: "hallStaff",
                title: "Сотрудникам зала",
                iconColor: hallStaffIconColor,
                itemHoverColor: hallStaffItemHoverColor,
                items: [
                    hallStaffItems.terminal,
                    hallStaffItems.delivery,
                    hallStaffItems.indexKDS,
                ],
            },
            guests: {
                id: "guests",
                title: "Гостям",
                iconColor: guestsIconColor,
                itemHoverColor: guestsItemHoverColor,
                items: [
                    guestsItems.indexWL,
                    guestsItems.passkit,
                    guestsItems.wlShop,
                    guestsItems.line,
                    guestsItems.customersDisplay,
                ],
            },
            backOfficeStaff: {
                id: "backOfficeStaff",
                title: "Cотрудникам бэк-офиса",
                iconColor: backOfficeStaffIconColor,
                itemHoverColor: backOfficeStaffItemHoverColor,
                items: [
                    backOfficeStaffItems.menu,
                    backOfficeStaffItems.warehouse,
                    backOfficeStaffItems.staff,
                    backOfficeStaffItems.marketing,
                    backOfficeStaffItems.reports,
                ],
            },
            managers: {
                id: "managers",
                title: "Руководителям",
                titleClassName: managersTitleClassName,
                // iconColor: managersIconColor,
                // itemHoverColor: managersItemHoverColor,
                items: [
                    managersItems.indexQRM,
                    managersItems.reportsNotifications,
                    managersItems.finance,
                    {
                        content: (
                            <MenuItemDesktopCustomTile
                                link={pagesLinks.franchise}
                                icon={<FranchiseIcon/>}
                                title="Управление франшизой"
                                text="Развитие бизнеса"
                            />
                        ),
                    },
                ],
            },
        } as unknown) as {
            hallStaff: MenuData
            guests: MenuData
            backOfficeStaff: MenuData
            managers: MenuData
        }
    }

    if (locale === "ru-BY") {
        return ({
            hallStaff: {
                id: "hallStaff",
                title: "Сотрудникам зала",
                iconColor: hallStaffIconColor,
                itemHoverColor: hallStaffItemHoverColor,
                items: [
                    hallStaffItems.terminal,
                    hallStaffItems.delivery,
                    hallStaffItems.indexKDS,
                ],
            },
            guests: {
                id: "guests",
                title: "Гостям",
                iconColor: guestsIconColor,
                itemHoverColor: guestsItemHoverColor,
                items: [
                    guestsItems.indexWL,
                    guestsItems.passkit,
                    guestsItems.wlShop,
                    guestsItems.line,
                    guestsItems.customersDisplay,
                ],
            },
            backOfficeStaff: {
                id: "backOfficeStaff",
                title: "Cотрудникам бэк-офиса",
                iconColor: backOfficeStaffIconColor,
                itemHoverColor: backOfficeStaffItemHoverColor,
                items: [
                    backOfficeStaffItems.menu,
                    backOfficeStaffItems.warehouse,
                    backOfficeStaffItems.staff,
                    backOfficeStaffItems.marketing,
                    backOfficeStaffItems.reports,
                ],
            },
            managers: {
                id: "managers",
                title: "Руководителям",
                titleClassName: managersTitleClassName,
                // iconColor: managersIconColor,
                // itemHoverColor: managersItemHoverColor,
                items: [
                    managersItems.indexQRM,
                    managersItems.reportsNotifications,
                    managersItems.finance,
                    {
                        content: (
                            <MenuItemDesktopCustomTile
                                link={pagesLinks.franchise}
                                icon={<FranchiseIcon/>}
                                title="Управление франшизой"
                                text="Развитие бизнеса"
                            />
                        ),
                    },
                ],
            },
        } as unknown) as {
            hallStaff: MenuData
            guests: MenuData
            backOfficeStaff: MenuData
            managers: MenuData
        }
    }

    if (locale === "kz-KZ") {
        return ({
            hallStaff: {
                id: "hallStaff",
                title: "ЗАЛ ҚЫЗМЕТКЕРЛЕРІНЕ",
                iconColor: hallStaffIconColor,
                itemHoverColor: hallStaffItemHoverColor,
                items: [
                    hallStaffItemsKz.terminal,
                    hallStaffItemsKz.delivery,
                    hallStaffItemsKz.indexKDS,
                ],
            },
            guests: {
                id: "guests",
                title: "ҚОНАҚТАРҒА",
                iconColor: guestsIconColor,
                itemHoverColor: guestsItemHoverColor,
                items: [
                    guestsItemsKz.indexWL,
                    guestsItemsKz.passkit,
                    guestsItemsKz.wlShop,
                    guestsItemsKz.line,
                    guestsItemsKz.customersDisplay,
                ],
            },
            backOfficeStaff: {
                id: "backOfficeStaff",
                title: "БЭК-ОФИС ҚЫЗМЕТКЕРЛЕРІНЕ",
                iconColor: backOfficeStaffIconColor,
                itemHoverColor: backOfficeStaffItemHoverColor,
                items: [
                    backOfficeStaffItemsKz.menu,
                    backOfficeStaffItemsKz.warehouse,
                    backOfficeStaffItemsKz.staff,
                    backOfficeStaffItemsKz.marketing,
                    backOfficeStaffItemsKz.reports,
                ],
            },
            managers: {
                id: "managers",
                title: "БАСШЫЛАРҒА",
                titleClassName: managersTitleClassName,
                // iconColor: managersIconColor,
                // itemHoverColor: managersItemHoverColor,
                items: [
                    managersItemsKz.indexQRM,
                    managersItemsKz.reportsNotifications,
                    managersItemsKz.finance,
                    {
                        content: (
                            <MenuItemDesktopCustomTile
                                link={pagesLinks.franchise}
                                icon={<FranchiseIcon/>}
                                title="Франшизаны басқару"
                                text="Бизнесті дамыту"
                            />
                        ),
                    },
                ],
            },
        } as unknown) as {
            hallStaff: MenuData
            guests: MenuData
            backOfficeStaff: MenuData
            managers: MenuData
        }
    }

    return ({
        hallStaff: {
            id: "hallStaff",
            title: "Сотрудникам зала",
            iconColor: hallStaffIconColor,
            itemHoverColor: hallStaffItemHoverColor,
            items: [
                hallStaffItems.terminal,
                hallStaffItems.delivery,
                hallStaffItems.indexKDS,
            ],
        },
        guests: {
            id: "guests",
            title: "Гостям",
            iconColor: guestsIconColor,
            itemHoverColor: guestsItemHoverColor,
            items: [
                guestsItems.indexWL,
                guestsItems.passkit,
                guestsItems.wlShop,
                guestsItems.line,
                guestsItems.customersDisplay,
            ],
        },
        backOfficeStaff: {
            id: "backOfficeStaff",
            title: "Cотрудникам бэк-офиса",
            iconColor: backOfficeStaffIconColor,
            itemHoverColor: backOfficeStaffItemHoverColor,
            items: [
                backOfficeStaffItems.menu,
                backOfficeStaffItems.warehouse,
                backOfficeStaffItems.staff,
                backOfficeStaffItems.marketing,
                backOfficeStaffItems.reports,
            ],
        },
        managers: {
            id: "managers",
            title: "Руководителям",
            titleClassName: managersTitleClassName,
            // iconColor: managersIconColor,
            // itemHoverColor: managersItemHoverColor,
            items: [
                managersItems.indexQRM,
                managersItems.reportsNotifications,
                managersItems.finance,
                {
                    content: (
                        <MenuItemDesktopCustomTile
                            link={pagesLinks.franchise}
                            icon={<FranchiseIcon/>}
                            title="Управление франшизой"
                            text="Развитие бизнеса"
                        />
                    ),
                },
            ],
        },
    } as unknown) as {
        hallStaff: MenuData
        guests: MenuData
        backOfficeStaff: MenuData
        managers: MenuData
    }
}

// $green - зелень

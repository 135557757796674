import * as React from "react"

const ReportsAnalytics = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		width="25"
		height="25"
		viewBox="0 0 25 25"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M12.498 15.4904V11.4904"
			stroke="#1BC594"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M15.998 15.4904V9.49036"
			stroke="#1BC594"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M8.99805 15.4904V13.4904"
			stroke="#1BC594"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M12.498 20.9904C17.1925 20.9904 20.998 17.1848 20.998 12.4904C20.998 7.79594 17.1925 3.99036 12.498 3.99036C7.80363 3.99036 3.99805 7.79594 3.99805 12.4904C3.99805 17.1848 7.80363 20.9904 12.498 20.9904Z"
			stroke="#1BC495"
			strokeWidth="2"
			strokeMiterlimit="10"
			strokeLinecap="round"
		/>
		<path
			d="M22.0267 22.019L19.1982 19.1906"
			stroke="#1BC594"
			strokeWidth="2"
			strokeLinecap="round"
		/>
	</svg>
)

export default ReportsAnalytics

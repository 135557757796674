import * as React from "react"

const TakeawayOnly = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M5 9.00002V6.99902C5 6.44674 5.44772 5.99902 6 5.99902H18C18.5523 5.99902 19 6.44674 19 6.99902V19.999C19 20.5513 18.5523 20.999 18 20.999H16"
			stroke="#FF9500"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M12.0024 20.999L5 20.999"
			stroke="#FF9500"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M10.5 16.9995H5"
			stroke="#FF9500"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M9.00241 13L5 13"
			stroke="#FF9500"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M9 9V5C9 4.20435 9.31607 3.44129 9.87868 2.87868C10.4413 2.31607 11.2044 2 12 2C12.7956 2 13.5587 2.31607 14.1213 2.87868C14.6839 3.44129 15 4.20435 15 5V9"
			stroke="#FF9500"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<rect
			x="8.00031"
			y="9.40851"
			width="0.000610352"
			height="0.086972"
			fill="#C4C4C4"
			stroke="#FF9500"
			strokeWidth="0.000610352"
		/>
	</svg>
)

export default TakeawayOnly

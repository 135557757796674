import * as React from "react"
import { SVGProps } from "react"

const ProductDemo = (props: SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 18 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<rect
			x={1.5}
			y={3}
			width={15}
			height={12}
			rx={1.5}
			fill={"none"}
			strokeWidth={1.5}
		/>
		<path
			d="M1.65918 6.03809H16.3406"
			strokeWidth={1.5}
			strokeLinecap="round"
		/>
		<path d="M5.24902 12.75V11.25" strokeWidth={1.5} strokeLinecap="round" />
		<path d="M9.75 12.75V11.25" strokeWidth={1.5} strokeLinecap="round" />
		<path d="M7.5 12.75V9" strokeWidth={1.5} strokeLinecap="round" />
		<path d="M12 12.75V9" strokeWidth={1.5} strokeLinecap="round" />
	</svg>
)

export default ProductDemo

import React from "react"
import { MenuData } from "../../components/Menu"

import {
	knowledgeBaseIconColor,
	knowledgeBaseItemHoverColor,
	getKnowledgeBaseItems,
} from "../../navMenuData/knowledgeBase"

import {Locales} from "../../../../../localization/types";

export const getKnowledgeBaseData = (locale: Locales) => {
	const knowledgeBaseItems = getKnowledgeBaseItems(locale);

	return {
		menu: {
			id: "menu",
			iconColor: knowledgeBaseIconColor,
			itemHoverColor: knowledgeBaseItemHoverColor,
			items: [
				knowledgeBaseItems.support,
				knowledgeBaseItems.webinars,
				knowledgeBaseItems.youtube,
				knowledgeBaseItems.demoCloud,
				knowledgeBaseItems.blog,
				knowledgeBaseItems.service,
				knowledgeBaseItems.tgRestoChanel,
				knowledgeBaseItems.restoGuide,
				knowledgeBaseItems.secretIngredient,
			],
		},
	} as {
		menu: MenuData
	}
}
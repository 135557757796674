import * as React from "react"
import { SVGProps } from "react"

const ProfileIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
			fill="white"
		/>
		<path
			d="M10 13C12.2091 13 14 11.2091 14 9C14 6.79086 12.2091 5 10 5C7.79086 5 6 6.79086 6 9C6 11.2091 7.79086 13 10 13Z"
			stroke="#5D85FE"
			strokeWidth="1.5"
			strokeMiterlimit="10"
			strokeLinecap="round"
		/>
		<path
			d="M4.50903 17.9482C4.9499 15.5412 7.24044 13 9.99986 13C12.7593 13 15.0498 15.5412 15.4907 17.9482"
			stroke="#5D85FE"
			strokeWidth="1.5"
			strokeMiterlimit="10"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default ProfileIcon

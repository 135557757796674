import * as React from "react"

const MarketingAndCRM = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g clipPath="url(#MarketingAndCRM_clip_path_1)">
			<g clipPath="url(#MarketingAndCRM_clip_path_2)">
				<path
					d="m7.122 7.902.707-.707a1 1 0 0 0-1.655.391l.948.316Zm8.958 8.958.316.949a1 1 0 0 0 .391-1.656l-.707.707ZM2.644 21.339l-.95-.316a1 1 0 0 0 1.266 1.265l-.316-.95Zm3.865-10.17a1 1 0 0 0-1.42 1.408l1.42-1.408ZM11.36 18.9a1 1 0 0 0 1.42-1.409l-1.42 1.409ZM10.254 6.62a1 1 0 1 0 1.994.152l-1.994-.152Zm2.324-4.17a1 1 0 1 0-1.994-.152l1.994.152Zm5.076 9.477a1 1 0 0 0 .056 2l-.056-2Zm4.578 1.871a1 1 0 1 0-.057-1.999l.057 2Zm-8.187-5.305a1 1 0 1 0 1.41 1.419l-1.41-1.42Zm5.726-2.868a1 1 0 0 0-1.41-1.419l1.41 1.419ZM6.415 8.609l8.958 8.958 1.414-1.414L7.83 7.195 6.415 8.61Zm9.349 7.302-13.437 4.48.633 1.897 13.436-4.48-.632-1.897ZM3.592 21.655l4.48-13.436-1.898-.633-4.48 13.437 1.898.632Zm1.497-9.078 6.272 6.324 1.42-1.409-6.272-6.323-1.42 1.408Zm7.159-5.804.33-4.322-1.994-.152-.33 4.322 1.994.152Zm5.462 7.154 4.522-.128-.057-1.999-4.521.128.056 2Zm-2.256-4.014 4.317-4.287-1.41-1.419-4.316 4.287 1.41 1.419Z"
					stroke={"none"}
				/>
			</g>
		</g>
		<defs>
			<clipPath id="MarketingAndCRM_clip_path_1">
				<path fill="#fff" d="M0 0h24v24H0z" />
			</clipPath>
			<clipPath id="MarketingAndCRM_clip_path_2">
				<path fill="#fff" transform="translate(-.002)" d="M0 0h24v24H0z" />
			</clipPath>
		</defs>
	</svg>
)

export default MarketingAndCRM

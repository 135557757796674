import * as React from "react"

const BusinessAudit = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 18 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M9.31294 12.6686L9.56516 13.3749L9.31294 12.6686ZM2.32343 9.35738L3.02975 9.10516L2.32343 9.35738ZM5.63464 2.36787L5.38242 1.66155L5.63464 2.36787ZM9.74639 3.38256C10.1095 3.58189 10.5654 3.44913 10.7648 3.08603C10.9641 2.72293 10.8313 2.26699 10.4682 2.06766L9.74639 3.38256ZM13.6428 6.72305C13.5895 6.31228 13.2133 6.0225 12.8025 6.0758C12.3917 6.1291 12.102 6.50531 12.1553 6.91608L13.6428 6.72305ZM9.06072 11.9623C6.60635 12.8387 3.90619 11.5595 3.02975 9.10516L1.61711 9.6096C2.77215 12.8441 6.33061 14.5299 9.56516 13.3749L9.06072 11.9623ZM3.02975 9.10516C2.15331 6.65078 3.43248 3.95062 5.88686 3.07418L5.38242 1.66155C2.14786 2.81658 0.462081 6.37504 1.61711 9.6096L3.02975 9.10516ZM5.88686 3.07418C7.20984 2.60176 8.60322 2.75501 9.74639 3.38256L10.4682 2.06766C8.965 1.24245 7.12614 1.03888 5.38242 1.66155L5.88686 3.07418ZM12.1553 6.91608C12.4347 9.06992 11.1889 11.2023 9.06072 11.9623L9.56516 13.3749C12.3719 12.3727 14.0112 9.562 13.6428 6.72305L12.1553 6.91608Z"
			stroke={"none"}
		/>
		<path
			d="M16.0691 16.1418L11.4019 11.4746"
			fill={"none"}
			strokeWidth={1.5}
			strokeLinecap="round"
		/>
		<path
			d="M5.15388 6.39518C4.861 6.10228 4.38612 6.10227 4.09322 6.39515C3.80032 6.68804 3.8003 7.16291 4.09319 7.45581L5.15388 6.39518ZM6.93598 9.23808L6.40563 9.76839C6.69837 10.0612 7.17296 10.0613 7.46592 9.7688L6.93598 9.23808ZM13.0441 4.19868C13.3372 3.906 13.3376 3.43113 13.0449 3.13802C12.7522 2.84492 12.2773 2.84457 11.9842 3.13725L13.0441 4.19868ZM4.09319 7.45581L6.40563 9.76839L7.46632 8.70777L5.15388 6.39518L4.09319 7.45581ZM7.46592 9.7688L13.0441 4.19868L11.9842 3.13725L6.40603 8.70737L7.46592 9.7688Z"
			stroke={"none"}
		/>
	</svg>
)

export default BusinessAudit

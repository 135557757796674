import * as React from "react"

const KitchenDisplay = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M5 5H3c-.552 0-1 .418-1 .933v12.134c0 .515.448.933 1 .933h18c.552 0 1-.418 1-.933V5.933C22 5.418 21.552 5 21 5h-2"
			strokeWidth={2}
			strokeLinecap="round"
			fill={"none"}
		/>
		<path
			d="M7 11h10v4a4 4 0 0 1-4 4h-2a4 4 0 0 1-4-4v-4Z"
			strokeWidth={2}
			strokeLinejoin="round"
			fill={"none"}
		/>
		<path
			d="M7 13.025H5M19 13.025h-2"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
			fill={"none"}
		/>
		<path
			d="M16 8.055V5M12 8.055V5M8 8.055V5"
			strokeWidth={2}
			strokeLinecap="round"
			fill={"none"}
		/>
	</svg>
)

export default KitchenDisplay

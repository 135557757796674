import * as React from "react"

const Backdrop = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		width={2490}
		height={1859}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2148.17 1858.17H334.277L67.812 1450.7c-76.763-117.39-89.147-265.57-32.934-394.09l332.948-761.2C430.727 151.601 569.261 55.462 725.991 46.852L1557.03 1.199c139.86-7.682 274.17 55.564 357.31 168.257l493.75 669.206c93.24 126.369 107.29 294.498 36.32 434.618l-296.24 584.89Z"
			fill="url(#FooterBackdrop__a)"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2148.17 1858.17H334.277L67.812 1450.7c-76.763-117.39-89.147-265.57-32.934-394.09l332.948-761.2C430.727 151.601 569.261 55.462 725.991 46.852L1557.03 1.199c139.86-7.682 274.17 55.564 357.31 168.257l493.75 669.206c93.24 126.369 107.29 294.498 36.32 434.618l-296.24 584.89Z"
			fill="url(#FooterBackdrop__b)"
			fillOpacity={0.2}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2148.17 1858.17H334.277L67.812 1450.7c-76.763-117.39-89.147-265.57-32.934-394.09l332.948-761.2C430.727 151.601 569.261 55.462 725.991 46.852L1557.03 1.199c139.86-7.682 274.17 55.564 357.31 168.257l493.75 669.206c93.24 126.369 107.29 294.498 36.32 434.618l-296.24 584.89Z"
			fill="url(#FooterBackdrop__c)"
		/>
		<defs>
			<linearGradient
				id="FooterBackdrop__a"
				x1={1065.87}
				y1={63.354}
				x2={1520.49}
				y2={1334.55}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#6696FF" />
				<stop offset={1} stopColor="#513BFE" />
			</linearGradient>
			<linearGradient
				id="FooterBackdrop__c"
				x1={1065.87}
				y1={63.354}
				x2={1520.49}
				y2={1334.55}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#6696FF" stopOpacity={0.2} />
				<stop offset={1} stopColor="#513BFE" stopOpacity={0.3} />
			</linearGradient>
			<pattern
				id="FooterBackdrop__b"
				patternContentUnits="objectBoundingBox"
				width={0.008}
				height={0.011}
			>
				<use xlinkHref="#FooterBackdrop__d" transform="scale(.0002)" />
			</pattern>
			<image
				id="FooterBackdrop__d"
				width={40}
				height={41}
				xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAApCAYAAABHomvIAAAAf0lEQVRYCe3SwQnAIBBE0ek1ahuxg9iLbFKWJSRIPAi5eBIhX1jw4OL4XImFAAIIIIAAAggggAACiwgEXbuTlVpB57FIrDdGDeRld19BFpcJ6WWlD9f2hYCjAk4Wv4I5jfZPObcpp/bVxSmvM39TXs8lCCCAAAIIIIAAAr8UeAAiHz7ihAEDdwAAAABJRU5ErkJggg=="
			/>
		</defs>
	</svg>
)

export default Backdrop

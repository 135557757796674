import React, { useContext } from "react"
import classNames from "classnames"

import CloseCross from "../../../../../assets/_V2/svg/CloseCross"

import { HeaderUIStateContext } from "../../hooks/headerUIState"

import NavigationMobile from "../NavigationMobile"
import ButtonsSet from "../ButtonsSet"

import styles from "./styles.module.scss"

interface Props {
	className?: string

	phoneComponent: JSX.Element
}

export default function MobilePopup(props: Props) {
	const { setMobileHeaderExpandState } = useContext(HeaderUIStateContext)

	return (
		<div className={classNames(styles.popup, props.className)}>
			<div className={styles.popup__wrapper}>
				<CloseCross
					onClick={() => setMobileHeaderExpandState(false)}
					className={styles.closeIcon}
				/>

				<NavigationMobile className={styles.popup__navigation} />

				<ButtonsSet
					className={styles.popup__buttons}
					onWhiteBackground={true}
					inFooter={true}
				/>
			</div>
		</div>
	)
}

import React from "react"

import Menu from "../../components/Menu"
import stylesMenu from "../../components/Menu/styles.module.scss"

import styles from "./styles.module.scss"

import { getData } from "./data"
import classNames from "classnames"
import {useLocalizationContext} from "../../../../../localization/useLocalizationContext";

export default function BusinessTypes() {
	const localizationContext = useLocalizationContext();
	const data = getData(localizationContext.locale);

	return (
		<div className={styles.container}>
			<Menu
				className={classNames(styles.menu, stylesMenu.menu_reverse)}
				data={data.businessTypes}
			/>
		</div>
	)
}

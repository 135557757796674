import * as React from "react"

const Community = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 18 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<circle cx={9} cy={4.40625} r={1.875} fill={"none"} strokeWidth={1.5} />
		<path
			d="M5.81471 7.38598C5.61008 7.74612 5.73615 8.20395 6.09629 8.40858C6.45643 8.6132 6.91426 8.48714 7.11889 8.127L5.81471 7.38598ZM10.881 8.127C11.0856 8.48714 11.5434 8.6132 11.9036 8.40858C12.2637 8.20395 12.3898 7.74612 12.1852 7.38598L10.881 8.127ZM7.11889 8.127C7.49161 7.47101 8.19489 7.03125 8.99993 7.03125V5.53125C7.63361 5.53125 6.44311 6.28 5.81471 7.38598L7.11889 8.127ZM8.99993 7.03125C9.80497 7.03125 10.5082 7.47101 10.881 8.127L12.1852 7.38598C11.5567 6.28 10.3663 5.53125 8.99993 5.53125V7.03125Z"
			stroke={"none"}
		/>
		<circle
			cx={4.7832}
			cy={11.6499}
			r={1.875}
			fill={"none"}
			strokeWidth={1.5}
		/>
		<path
			d="M1.59791 14.6296C1.39328 14.9898 1.51935 15.4476 1.87949 15.6522C2.23963 15.8569 2.69746 15.7308 2.90209 15.3707L1.59791 14.6296ZM6.66417 15.3707C6.8688 15.7308 7.32663 15.8569 7.68677 15.6522C8.04691 15.4476 8.17298 14.9898 7.96835 14.6296L6.66417 15.3707ZM2.90209 15.3707C3.27482 14.7147 3.97809 14.2749 4.78313 14.2749V12.7749C3.41681 12.7749 2.22631 13.5237 1.59791 14.6296L2.90209 15.3707ZM4.78313 14.2749C5.58817 14.2749 6.29145 14.7147 6.66417 15.3707L7.96835 14.6296C7.33995 13.5237 6.14946 12.7749 4.78313 12.7749V14.2749Z"
			stroke={"none"}
		/>
		<circle
			cx={13.2173}
			cy={11.6499}
			r={1.875}
			fill={"none"}
			strokeWidth={1.5}
		/>
		<path
			d="M10.032 14.6296C9.82736 14.9898 9.95343 15.4476 10.3136 15.6522C10.6737 15.8569 11.1315 15.7308 11.3362 15.3707L10.032 14.6296ZM15.0983 15.3707C15.3029 15.7308 15.7607 15.8569 16.1209 15.6522C16.481 15.4476 16.6071 14.9898 16.4024 14.6296L15.0983 15.3707ZM11.3362 15.3707C11.7089 14.7147 12.4122 14.2749 13.2172 14.2749V12.7749C11.8509 12.7749 10.6604 13.5237 10.032 14.6296L11.3362 15.3707ZM13.2172 14.2749C14.0223 14.2749 14.7255 14.7147 15.0983 15.3707L16.4024 14.6296C15.774 13.5237 14.5835 12.7749 13.2172 12.7749V14.2749Z"
			stroke={"none"}
		/>
	</svg>
)

export default Community

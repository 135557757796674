import * as React from "react"

const CustomersDisplay = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<rect
			x={4.125}
			y={6.47644}
			width={16.75}
			height={13.0278}
			rx={0.930556}
			stroke="#0252CB"
			strokeWidth={2}
			fill="none"
		/>
		<path
			d="M13.6723 10.9056C14.0628 10.5151 14.0628 9.8819 13.6723 9.49138C13.2818 9.10086 12.6486 9.10086 12.2581 9.49138L13.6723 10.9056ZM6.67473 15.0747C6.2842 15.4652 6.2842 16.0984 6.67473 16.4889C7.06525 16.8795 7.69842 16.8795 8.08894 16.4889L6.67473 15.0747ZM12.2581 9.49138L6.67473 15.0747L8.08894 16.4889L13.6723 10.9056L12.2581 9.49138Z"
			fill="#0252CB"
			strokeWidth={0.5}
		/>
		<path
			d="M18.3251 10.9055C18.7156 10.5149 18.7156 9.88178 18.3251 9.49126C17.9346 9.10073 17.3014 9.10073 16.9109 9.49126L18.3251 10.9055ZM11.3276 15.0746C10.937 15.4651 10.937 16.0983 11.3276 16.4888C11.7181 16.8793 12.3513 16.8793 12.7418 16.4888L11.3276 15.0746ZM16.9109 9.49126L11.3276 15.0746L12.7418 16.4888L18.3251 10.9055L16.9109 9.49126Z"
			fill="#0252CB"
			strokeWidth={0.5}
		/>
	</svg>
)

export default CustomersDisplay

import * as React from "react"

const RightVignette = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		width={16}
		height={16}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M0 16V0a16 16 0 0 0 16 16H0Z"
			fill="#fff"
		/>
	</svg>
)

export default RightVignette

import React from 'react'

import { pagesLinks } from '../../../../pages-data/_V2/common/links'

import CashTerminal from '../assets/dropdownItemsIcons/CashTerminal'
import DeliveryModule from '../assets/dropdownItemsIcons/DeliveryModule'
import KitchenDisplay from '../assets/dropdownItemsIcons/KitchenDisplay'
import { useLocalizationContext } from '../../../../localization/useLocalizationContext'
import colors from '../../../../styles/_V2/export.module.scss'
import { MenuData } from '../components/Menu'

export const hallStaffIconColor = colors.fuchsia
export const hallStaffItemHoverColor = colors.fuchsiaLightBg

export const hallStaffItems = {
  terminal: {
    icon: <CashTerminal />,
    name: 'Кассовый терминал',
    mobileName: 'Кассовый\nтерминал',
    description: 'Схема зала, заказы, бонусы в CRM, чеки, 3 типа оплат',
    link: pagesLinks.terminal,
  },
  delivery: {
    icon: <DeliveryModule />,
    name: 'Модуль доставки',
    mobileName: 'Модуль\nдоставки',
    description: 'Работа с собственными курьерами\nи интеграция',
    link: pagesLinks.delivery,
  },
  indexKDS: {
    icon: <KitchenDisplay />,
    name: 'Кухонный экран повара',
    mobileName: 'Кухонный \nэкран повара',
    description: 'Заказ от столика в кухню. Очередь заказов, техкарты к блюдам',
    link: pagesLinks.indexKDS,
  },
}

export const hallStaffItemsKz = {
  terminal: {
    icon: <CashTerminal />,
    name: 'Кассалық терминал',
    mobileName: 'Кассалық\nтерминал',
    description: 'Зал сұлбасы, тапсырыстар, CRM-дегі бонустар, чектер, төлемдердің 3 түрі',
    link: pagesLinks.terminal,
  },
  delivery: {
    icon: <DeliveryModule />,
    name: 'Жеткізу модулі',
    mobileName: 'Жеткізу\nмодулі',
    description: 'Өз курьерлерімен\nжұмыс істеу',
    link: pagesLinks.delivery,
  },
  indexKDS: {
    icon: <KitchenDisplay />,
    name: 'Аспаздың асүй экраны',
    mobileName: 'Аспаздың\nасүй экраны',
    description: 'Үстелшеден асүйге тапсырыс. Тапсырыстар кезегі, тағамдарға арналған техкарталар',
    link: pagesLinks.indexKDS,
  },
}

import React, { useContext } from 'react'
import classNames from 'classnames'

import { getData } from '../../data'
import data_kz from '../../data_kz'
import { HeaderUIStateContext } from '../../hooks/headerUIState'
import {useLocalizationContext} from "../../../../../localization/useLocalizationContext";

import ChevronTop from '../../../../../assets/_V2/svg/ChevronTop'
import LeftVignette from '../../assets/LeftVignette'
import RightVignette from '../../assets/RightVignette'

import styles from './styles.module.scss'
import {Link} from "gatsby";


interface Props {

    className? : string

    isDarkTheme?: boolean;

}


export default function NavigationDesktop(props : Props) {

    const localizationContext = useLocalizationContext()
    const data = getData(localizationContext.locale)
    const isDarkTheme = props.isDarkTheme;

    const {
        mobileHeaderExpanded,
        isDropdownMenuOpen,
        setDropdownMenuOpenState,
        selectedContentSection,
        setSelectedContentSections,
        isSticky,
    } = useContext(HeaderUIStateContext)


    return (
        // Обертка тут нужна длясемантики
        <nav className={classNames(
            styles.navigationWrapper,
            props.className,
        )}
        >
            <ul
                className={classNames(
                    styles.navigation,
                    {
                        [styles.__onWhiteBackground]: isSticky || mobileHeaderExpanded,
                        [styles.navigation_theme_dark]: isDarkTheme
                    },
                )}
                onMouseLeave={(event : React.MouseEvent<HTMLElement>) => {
                    if (!(event.nativeEvent.relatedTarget as HTMLElement)?.parentElement?.dataset?.isPopup) {
                        setDropdownMenuOpenState(false)
                    }
                }}
            >
                {data.navigation.map(item => {

                    if ('link' in item) {
                        return (
                            <li
                                key={item.title}
                                onMouseEnter={() => {
                                    setDropdownMenuOpenState(false)
                                }}
                                className={classNames(
                                    styles.navigation__item,
                                    styles.__link,
                                    {[styles.navigation__item__kz]: localizationContext.locale === 'ru-KZ' || localizationContext.locale === 'kz-KZ'}
                                )}
                            >
                                <Link
                                    className={styles.navigation__link}
                                    to={item.link}
                                >
                                    {item.title}
                                </Link>
                            </li>
                        )
                    } else {
                        return (
                            <li
                                onMouseEnter={() => {
                                    setSelectedContentSections(data.contentSections[item.contentSectionName])
                                    setDropdownMenuOpenState(true)
                                }}
                                key={item.title}
                                className={classNames(
                                    styles.navigation__item,
                                    {[styles.navigation__item__kz]: localizationContext.locale === 'ru-KZ' || localizationContext.locale === 'kz-KZ'},
                                    {
                                        [styles.__selected]: (
                                            isDropdownMenuOpen &&
                                            selectedContentSection?.name === item.contentSectionName
                                        )
                                    }
                                )}

                            >
                                {item.title}

                                <ChevronTop className={styles.navigation__itemIcon} />


                                {(
                                    isDropdownMenuOpen &&
                                    selectedContentSection?.name === item.contentSectionName
                                ) && (
                                    <>
                                        <LeftVignette className={styles.navigation__itemLeftVignette} />
                                        <RightVignette className={styles.navigation__itemRightVignette} />
                                    </>
                                )}
                            </li>

                        )
                    }

                })}
            </ul>
        </nav>
    )

}

import * as React from "react"

const SecretIngredient = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M9.51837 2.66602V1.66602C8.96609 1.66602 8.51837 2.11373 8.51837 2.66602H9.51837ZM14.4809 2.66602H15.4809C15.4809 2.11373 15.0332 1.66602 14.4809 1.66602V2.66602ZM9.51837 11.507L10.3844 12.007C10.4722 11.855 10.5184 11.6825 10.5184 11.507H9.51837ZM5.58343 18.3225L4.71741 17.8225H4.71741L5.58343 18.3225ZM18.4163 18.3225L17.5503 18.8225L18.4163 18.3225ZM14.4809 11.5062H13.4809C13.4809 11.6818 13.5271 11.8542 13.6149 12.0062L14.4809 11.5062ZM9.51837 3.66602H14.4809V1.66602H9.51837V3.66602ZM10.5184 11.507V2.66602H8.51837V11.507H10.5184ZM6.44946 18.8225L10.3844 12.007L8.65235 11.007L4.71741 17.8225L6.44946 18.8225ZM7.31548 20.3225C6.54568 20.3225 6.06456 19.4892 6.44946 18.8225L4.71741 17.8225C3.56271 19.8225 5.00608 22.3225 7.31548 22.3225V20.3225ZM16.6843 20.3225H7.31548V22.3225H16.6843V20.3225ZM17.5503 18.8225C17.9352 19.4892 17.4541 20.3225 16.6843 20.3225V22.3225C18.9937 22.3225 20.437 19.8225 19.2823 17.8225L17.5503 18.8225ZM13.6149 12.0062L17.5503 18.8225L19.2823 17.8225L15.347 11.0062L13.6149 12.0062ZM13.4809 2.66602V11.5062H15.4809V2.66602H13.4809Z"
			fill="#00C7D9"
		/>
		<path
			d="M7.51855 2.66699H16.4824"
			stroke="#00C7D9"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M9.59863 11.0117H14.4023"
			stroke="#00C7D9"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<circle cx="12.6943" cy="17.0205" r="1" fill="#00C7D9" />
		<circle cx="10.3867" cy="18.5205" r="1" fill="#00C7D9" />
		<circle cx="10.3867" cy="15.5205" r="1" fill="#00C7D9" />
	</svg>
)

export default SecretIngredient

import * as React from "react"

const Coffeehouse = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 19 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M5.375 7.646h8.25l-1.074 8.25H6.45l-1.074-8.25ZM4.25 4.646h10.5v3H4.25zM12.875 4.646 12.2 2.104H6.8l-.675 2.542"
			strokeWidth={1.5}
			strokeLinejoin="round"
			fill={"none"}
		/>
	</svg>
)

export default Coffeehouse

import * as React from "react"

const TableService = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		width="20"
		height="18"
		viewBox="0 0 20 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M9 1C9.55228 1 10 1.44772 10 2L10 6C10 6.55228 9.55228 7 9 7L2 7C1.44771 7 1 6.55228 1 6L1 2C1 1.44771 1.44772 1 2 1L9 1Z"
			stroke="#FF9500"
			strokeWidth="2"
		/>
		<path
			d="M18 1C18.5523 1 19 1.44772 19 2L19 6C19 6.55228 18.5523 7 18 7L15 7C14.4477 7 14 6.55228 14 6L14 2C14 1.44772 14.4477 1 15 1L18 1Z"
			stroke="#FF9500"
			strokeWidth="2"
		/>
		<path
			d="M11 17C10.4477 17 10 16.5523 10 16L10 12C10 11.4477 10.4477 11 11 11L18 11C18.5523 11 19 11.4477 19 12L19 16C19 16.5523 18.5523 17 18 17L11 17Z"
			stroke="#FF9500"
			strokeWidth="2"
		/>
		<path
			d="M2 17C1.44772 17 1 16.5523 1 16L1 12C1 11.4477 1.44772 11 2 11L5 11C5.55229 11 6 11.4477 6 12L6 16C6 16.5523 5.55228 17 5 17L2 17Z"
			stroke="#FF9500"
			strokeWidth="2"
		/>
	</svg>
)

export default TableService

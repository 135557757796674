import React from "react"

import { pagesLinks } from "../../../../pages-data/_V2/common/links"

import colors from "../../../../styles/_V2/export.module.scss"

import ApplicationAndWebsite from "../assets/dropdownItemsIcons/ApplicationAndWebsite"
import LoyaltyCards from "../assets/dropdownItemsIcons/LoyaltyCards"
import OnlineMenu from "../assets/dropdownItemsIcons/OnlineMenu"
import ElectronicQueue from "../assets/dropdownItemsIcons/ElectronicQueue"
import CustomersDisplay from "../assets/dropdownItemsIcons/CustomersDisplay"

export const guestsIconColor = colors.blueSky
export const guestsItemHoverColor = colors.blueSkyLightBg

export const guestsItems = {
	indexWL: {
		icon: <ApplicationAndWebsite />,
		name: "Приложение и сайт",
		mobileName: "Приложение\nи сайт",
		description: "Выбор по меню, оплата, доставка, самовывоз",
		link: pagesLinks.indexWL,
	},
	passkit: {
		icon: <LoyaltyCards />,
		name: "Карты лояльности",
		mobileName: "Карты\nлояльности",
		description: "Бонусные программы, акционные предложения, комбо",
		link: pagesLinks.passkit,
	},
	wlShop: {
		icon: <OnlineMenu />,
		name: "Электронное меню",
		mobileName: "Электронное меню",
		description: "Для случаев, когде не нужно бумажное меню",
		link: pagesLinks.wlShop.index,
	},
	line: {
		icon: <ElectronicQueue />,
		name: "Электронная очередь",
		mobileName: "Электронная\nочередь",
		description: "Обслуживание клиентов \nв пиковые часы",
		link: pagesLinks.line,
	},
	customersDisplay: {
		icon: <CustomersDisplay />,
		name: "Экран покупателя",
		mobileName: "Экран\nпокупателя",
		description: "Наглядная информация о заказе для гостя",
		link: pagesLinks.customerDisplay,
	},
}

export const guestsItemsKz = {
	indexWL: {
		icon: <ApplicationAndWebsite />,
		name: "Қосымша және сайт",
		mobileName: "Қосымша\nжәне сайт",
		description: "Мәзір бойынша таңдау, төлеу, жеткізу, өздігінен әкету",
		link: pagesLinks.indexWL,
	},
	passkit: {
		icon: <LoyaltyCards />,
		name: "Адалдық карталары",
		mobileName: "Адалдық\nкарталары",
		description: "Бонустық бағдарламалар, акциялық ұсыныстар, комбо",
		link: pagesLinks.passkit,
	},
	wlShop: {
		icon: <OnlineMenu />,
		name: "Электрондық мәзірлер",
		mobileName: "Электрондық мәзірлер",
		description: "Қағаз мәзір қажет болмаған жағдайлар үшін",
		link: pagesLinks.wlShop.index,
	},
	line: {
		icon: <ElectronicQueue />,
		name: "Электрондық кезек",
		mobileName: "Электрондық\nкезек",
		description: "Шыңдық сағаттары\nклиенттерге қызмет көрсету",
		link: pagesLinks.line,
	},
	customersDisplay: {
		icon: <CustomersDisplay />,
		name: "Сатып алушының экраны",
		mobileName: "Сатып\nалушының экраны",
		description: "Қонақ үшін тапсырыс туралы көрнекі ақпарат",
		link: pagesLinks.customerDisplay,
	},
}

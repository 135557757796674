import * as React from "react"

const Fastfood = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		width="18"
		height="21"
		viewBox="0 0 18 21"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M1.28174 11.4497C2.20358 9.10104 4.30884 7.45972 6.75848 7.45972C9.20812 7.45972 11.3134 9.10104 12.2352 11.4497"
			stroke="#FF9500"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M1 20H12.5176"
			stroke="#FF9500"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M1 17.1499H12.5176"
			stroke="#FF9500"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M1 14.3H12.5176"
			stroke="#FF9500"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M7.6123 4.80688H16.9998L15.4354 19.9995"
			stroke="#FF9500"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M12.2664 4.39204V1"
			stroke="#FF9500"
			strokeWidth="2"
			strokeLinecap="round"
		/>
	</svg>
)

export default Fastfood

import * as React from "react"

const Bar = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 19 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M4.25 2.625h10.5L14 15.375H5l-.75-12.75Z"
			strokeWidth={1.5}
			strokeLinejoin="round"
			fill={"none"}
		/>
		<path
			d="M13.816 6.758c-4.373 0-4.373 1.5-8.745 1.5"
			strokeWidth={1.5}
			fill={"none"}
		/>
	</svg>
)

export default Bar

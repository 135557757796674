import * as React from "react"

const FranchiseIcon = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			width="69"
			height="62"
			viewBox="0 0 69 62"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M56.8303 18.2195C59.2307 17.8202 60.2383 19.0054 60.4421 19.648H59.706L58.9824 20.1532L58.7953 21.2948L57.7161 21.039L56.8303 18.2195Z"
				fill="#FEEA00"
			/>
			<path
				d="M62.817 19.1975L62.9456 20.0566L62.1094 20.4379L61.4386 20.9203L61.3605 20.4379L61.1446 20.0566L60.5566 19.6661L60.5106 19.5513L60.5566 19.1562L60.8322 18.6692L61.2181 18.4165L61.8062 18.3384L62.3024 18.49L62.6607 18.8392L62.817 19.1975Z"
				fill="#FEEA00"
			/>
			<path
				d="M59.5014 21.6185L58.7534 21.2832L58.9635 20.163L59.7041 19.625L60.5332 19.6692L61.1339 20.0488L61.3697 20.4431L61.4434 20.911L61.1523 21.1174L60.6843 21.5706L60.2495 22.057L59.5014 21.6185Z"
				fill="#FEEA00"
			/>
			<path
				d="M62.9257 19.9134L62.8275 19.2637C62.7367 18.6631 62.1495 18.2538 61.5161 18.3496C60.8827 18.4454 60.4429 19.01 60.5337 19.6106"
				stroke="#2A2D33"
				strokeLinecap="round"
			/>
			<path
				d="M59.1606 14.5072C59.9927 14.3892 60.7868 14.6987 61.3207 15.2688C61.4111 15.2401 61.5052 15.2185 61.6022 15.2048C62.5467 15.0708 63.4209 15.7278 63.5549 16.6722L63.8079 18.3447"
				stroke="#2A2D33"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M59.0801 14.5189C58.2503 14.6522 57.5833 15.1827 57.2418 15.8852C57.147 15.8845 57.0508 15.8917 56.954 15.9073C56.0121 16.0586 55.3713 16.9447 55.5225 17.8866L55.7755 19.559"
				stroke="#2A2D33"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M65.38 34.0323L65.2612 32.5285M59.1806 23.8323C60.423 21.031 62.5982 19.9219 64.2544 19.7031L64.6057 24.1443"
				stroke="#2A2D33"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M65.3794 34.0328C65.3794 34.0328 64.0118 35.0889 62.2641 35.3533C60.5163 35.6176 58.8976 35.013 58.8976 35.013L56.7538 25.5067M60.2534 22.0518C58.6443 20.9641 56.975 20.7806 55.7356 20.9919L56.2918 23.4582"
				stroke="#2A2D33"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M61.4464 20.7527C61.3329 20.0021 60.6324 19.4857 59.8818 19.5992C59.1312 19.7127 58.6147 20.4132 58.7282 21.1638"
				stroke="#2A2D33"
				strokeLinecap="round"
			/>
			<path
				d="M57.6769 20.8928L56.8337 18.2233C58.2912 17.7894 59.7893 18.384 60.4399 19.6282"
				stroke="#2A2D33"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M8.45045 31.1378C6.7809 30.9437 5.2334 29.9531 4.39713 28.3506C3.56087 26.7481 3.63324 24.912 4.42889 23.4315C6.09843 23.6256 7.64593 24.6163 8.4822 26.2188C9.31846 27.8213 9.24609 29.6573 8.45045 31.1378Z"
				fill="#FEEA00"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M50.3378 30.9869L54.2578 28.7197C54.3326 28.6007 54.4032 28.4778 54.4694 28.351C54.485 28.3211 54.5003 28.2911 54.5152 28.2611C54.1462 27.4891 53.8342 25.9676 54.8793 24.5407L54.8807 24.543C54.7758 24.1578 54.6272 23.7848 54.4376 23.4319C52.7681 23.626 51.2206 24.6167 50.3843 26.2192C49.5763 27.7675 49.6166 29.5338 50.3378 30.9869Z"
				fill="#FEEA00"
			/>
			<path
				d="M9.06849 36.9362C7.30523 37.2871 5.40453 36.787 4.02686 35.4304C2.64919 34.0738 2.11976 32.1811 2.44335 30.4126C4.20662 30.0618 6.10732 30.5619 7.48499 31.9185C8.86266 33.275 9.39209 35.1678 9.06849 36.9362Z"
				fill="#FEEA00"
			/>
			<path
				d="M11.1541 42.5447C9.48294 43.4796 7.3929 43.5975 5.55135 42.6758C3.70979 41.7541 2.55143 40.0105 2.29815 38.1124C3.9693 37.1775 6.05934 37.0596 7.90089 37.9813C9.74245 38.903 10.9008 40.6467 11.1541 42.5447Z"
				fill="#FEEA00"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M47.7141 42.5329C49.3555 41.9745 52.6254 39.1485 54.4786 37.4069C55.2122 37.511 55.9217 37.7505 56.5685 38.1123C56.3152 40.0103 55.1568 41.754 53.3153 42.6757C51.4737 43.5974 49.3837 43.4795 47.7125 42.5446C47.713 42.5407 47.7136 42.5368 47.7141 42.5329Z"
				fill="#FEEA00"
			/>
			<path
				d="M14.9869 47.3643C13.6025 48.8517 11.5296 49.6498 9.36983 49.3171C7.21011 48.9844 5.47346 47.5994 4.60092 45.7643C5.9853 44.277 8.05827 43.4788 10.218 43.8116C12.3777 44.1443 14.1144 45.5293 14.9869 47.3643Z"
				fill="#FEEA00"
			/>
			<path
				d="M43.8794 47.3643C45.2638 48.8517 47.3368 49.6498 49.4965 49.3171C51.6562 48.9844 53.3929 47.5994 54.2654 45.7643C52.881 44.277 50.8081 43.4788 48.6483 43.8116C46.4886 44.1443 44.752 45.5293 43.8794 47.3643Z"
				fill="#FEEA00"
			/>
			<path
				d="M20.4598 50.8665C19.5512 52.814 17.7252 54.2921 15.4437 54.6607C13.1622 55.0293 10.9637 54.2012 9.48816 52.6389C10.3968 50.6915 12.2227 49.2133 14.5042 48.8447C16.7857 48.4762 18.9842 49.3042 20.4598 50.8665Z"
				fill="#FEEA00"
			/>
			<path
				d="M38.4067 50.8665C39.3153 52.814 41.1412 54.2921 43.4227 54.6607C45.7042 55.0293 47.9027 54.2012 49.3783 52.6389C48.4697 50.6915 46.6438 49.2133 44.3623 48.8447C42.0808 48.4762 39.8823 49.3042 38.4067 50.8665Z"
				fill="#FEEA00"
			/>
			<path
				d="M27.0403 52.6737C26.7575 54.9225 25.402 56.9966 23.2306 58.104C21.0592 59.2113 18.5844 59.0905 16.5981 57.9988C16.8809 55.7501 18.2364 53.6759 20.4078 52.5686C22.5792 51.4613 25.054 51.5821 27.0403 52.6737Z"
				fill="#FEEA00"
			/>
			<path
				d="M31.8261 52.6737C32.109 54.9225 33.4644 56.9966 35.6359 58.104C37.8073 59.2113 40.2821 59.0905 42.2684 57.9988C41.9855 55.7501 40.6301 53.6759 38.4587 52.5686C36.2872 51.4613 33.8124 51.5821 31.8261 52.6737Z"
				fill="#FEEA00"
			/>
			<path
				d="M9.20341 25.3654C7.78081 24.7162 6.69405 23.3966 6.41191 21.7387C6.12977 20.0809 6.71885 18.4761 7.84661 17.3928C9.26922 18.042 10.356 19.3616 10.6381 21.0195C10.9203 22.6774 10.3312 24.2821 9.20341 25.3654Z"
				fill="#FEEA00"
			/>
			<path
				d="M49.663 25.3654C51.0856 24.7162 52.1724 23.3966 52.4545 21.7387C52.7367 20.0809 52.1476 18.4761 51.0198 17.3928C49.5972 18.042 48.5105 19.3616 48.2283 21.0195C47.9462 22.6774 48.5353 24.2821 49.663 25.3654Z"
				fill="#FEEA00"
			/>
			<path
				d="M8.4502 31.5421C6.78066 31.348 5.23316 30.3574 4.39689 28.7549C3.56062 27.1524 3.633 25.3163 4.42864 23.8358C6.09819 24.0299 7.64569 25.0206 8.48195 26.6231C9.31822 28.2255 9.24584 30.0616 8.4502 31.5421Z"
				stroke="#2A2D33"
				strokeLinejoin="round"
			/>
			<path
				d="M54.4374 23.8358C52.7679 24.0299 51.2204 25.0206 50.3841 26.6231C49.6339 28.0607 49.615 29.6863 50.1935 31.0746"
				stroke="#2A2D33"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9.06849 37.3401C7.30523 37.6909 5.40453 37.1908 4.02686 35.8342C2.64919 34.4777 2.11976 32.5849 2.44335 30.8164C4.20662 30.4656 6.10732 30.9657 7.48499 32.3223C8.86266 33.6788 9.39209 35.5716 9.06849 37.3401Z"
				stroke="#2A2D33"
				strokeLinejoin="round"
			/>
			<path
				d="M11.1541 42.9485C9.48294 43.8834 7.3929 44.0012 5.55135 43.0795C3.70979 42.1579 2.55143 40.4142 2.29815 38.5161C3.9693 37.5813 6.05934 37.4634 7.90089 38.3851C9.74245 39.3068 10.9008 41.0504 11.1541 42.9485Z"
				stroke="#2A2D33"
				strokeLinejoin="round"
			/>
			<path
				d="M47.712 42.9486C49.3832 43.8835 51.4732 44.0014 53.3148 43.0797C55.1563 42.158 56.3147 40.4143 56.568 38.5163C55.7998 38.0866 54.9432 37.8295 54.0629 37.7666"
				stroke="#2A2D33"
				strokeLinejoin="round"
			/>
			<path
				d="M47.7121 42.9483C49.3833 43.8832 51.4733 44.0011 53.3149 43.0794C55.1564 42.1577 56.3148 40.4141 56.5681 38.516"
				stroke="#2A2D33"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M14.9867 47.7686C13.6023 49.256 11.5293 50.0541 9.36959 49.7214C7.20987 49.3887 5.47322 48.0037 4.60067 46.1686C5.98505 44.6813 8.05803 43.8831 10.2178 44.2159C12.3775 44.5486 14.1141 45.9336 14.9867 47.7686Z"
				stroke="#2A2D33"
				strokeLinejoin="round"
			/>
			<path
				d="M43.8794 47.7686C45.2638 49.256 47.3368 50.0541 49.4965 49.7214C51.6562 49.3887 53.3929 48.0037 54.2654 46.1686C52.881 44.6813 50.8081 43.8831 48.6483 44.2159C46.4886 44.5486 44.752 45.9336 43.8794 47.7686Z"
				stroke="#2A2D33"
				strokeLinejoin="round"
			/>
			<path
				d="M20.4595 51.2703C19.5509 53.2178 17.725 54.696 15.4435 55.0645C13.162 55.4331 10.9635 54.605 9.48792 53.0427C10.3965 51.0953 12.2225 49.6171 14.504 49.2485C16.7855 48.88 18.984 49.708 20.4595 51.2703Z"
				stroke="#2A2D33"
				strokeLinejoin="round"
			/>
			<path
				d="M38.4067 51.2703C39.3153 53.2178 41.1412 54.696 43.4227 55.0645C45.7042 55.4331 47.9027 54.605 49.3783 53.0427C48.4697 51.0953 46.6438 49.6171 44.3623 49.2485C42.0808 48.88 39.8823 49.708 38.4067 51.2703Z"
				stroke="#2A2D33"
				strokeLinejoin="round"
			/>
			<path
				d="M27.0401 53.078C26.7572 55.3268 25.4018 57.4009 23.2304 58.5082C21.0589 59.6155 18.5841 59.4947 16.5978 58.4031C16.8807 56.1543 18.2361 54.0802 20.4075 52.9728C22.579 51.8655 25.0538 51.9864 27.0401 53.078Z"
				stroke="#2A2D33"
				strokeLinejoin="round"
			/>
			<path
				d="M31.8261 53.078C32.109 55.3268 33.4644 57.4009 35.6359 58.5082C37.8073 59.6155 40.2821 59.4947 42.2684 58.4031C41.9855 56.1543 40.6301 54.0802 38.4587 52.9728C36.2872 51.8655 33.8124 51.9864 31.8261 53.078Z"
				stroke="#2A2D33"
				strokeLinejoin="round"
			/>
			<path
				d="M9.20341 25.7692C7.78081 25.12 6.69405 23.8004 6.41191 22.1425C6.12977 20.4847 6.71885 18.8799 7.84661 17.7966C9.26922 18.4459 10.356 19.7654 10.6381 21.4233C10.9203 23.0812 10.3312 24.686 9.20341 25.7692Z"
				stroke="#2A2D33"
				strokeLinejoin="round"
			/>
			<path
				d="M49.6628 25.7692C51.0854 25.12 52.1722 23.8004 52.4543 22.1425C52.7364 20.4847 52.1474 18.8799 51.0196 17.7966C49.597 18.4459 48.5102 19.7654 48.2281 21.4233C47.946 23.0812 48.535 24.686 49.6628 25.7692Z"
				stroke="#2A2D33"
				strokeLinejoin="round"
			/>
			<path
				d="M54.799 24.6765C54.7032 24.3868 54.5823 24.1054 54.4374 23.8358C52.7679 24.0299 51.2204 25.0206 50.3841 26.6231C49.6326 28.0631 49.6149 29.6918 50.1964 31.0816"
				stroke="#2A2D33"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M16.8898 43.8462C18.8399 43.1667 22.3904 38.7187 20.8155 30.7457"
				stroke="#2A2D33"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M27.478 24.8011C24.0384 24.878 20.8547 25.4664 18.7979 26.4064C19.8907 27.8708 20.3154 28.8695 20.8118 30.7442L23.1549 30.5847C23.3034 31.2112 24.0601 33.0473 26.0737 32.6238C28.2863 32.1584 28.3032 29.9537 25.7321 25.1224"
				stroke="#2A2D33"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M27.0157 27.6456L28.1219 21.7931"
				stroke="#2A2D33"
				strokeLinecap="round"
			/>
			<path
				d="M36.2546 30.6675L36.9013 27.9155C37.3732 25.8416 40.2238 25.8122 40.0965 23.9216"
				stroke="#2A2D33"
				strokeLinecap="round"
			/>
			<path
				d="M56.7573 25.5115L59.4781 26.6679C60.1485 26.9656 60.9292 26.6183 61.1571 25.9211C61.3561 25.3121 61.0575 24.6518 60.4688 24.3991L59.1617 23.8381C58.167 23.4111 57.0382 23.0793 56.0729 23.5691C55.7471 23.7344 55.4464 23.9512 55.1832 24.2143C54.5279 24.8696 54.1598 25.7584 54.1598 26.6851C54.1598 27.6118 54.5279 28.5006 55.1832 29.1559C55.3691 29.3418 55.5738 29.5046 55.7928 29.6424"
				stroke="#2A2D33"
				strokeLinecap="round"
			/>
			<path
				d="M30.9049 19.0594C30.6527 17.8288 29.4506 17.0356 28.22 17.2878C26.9893 17.5399 26.1961 18.742 26.4483 19.9727C26.7005 21.2033 27.9026 21.9965 29.1332 21.7443"
				stroke="#2A2D33"
				strokeLinecap="round"
			/>
			<path
				d="M19.1549 26.9531C16.738 27.4565 13.3586 28.3828 10.9264 30.5913C11.4638 32.7237 14.0664 37.5362 20.2873 39.7002"
				stroke="#2A2D33"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11.2273 31.5082C10.4822 32.4917 9.68192 33.5552 9.03796 34.7664"
				stroke="#2A2D33"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11.8202 32.6383C11.8202 32.6383 16.2903 30.0072 20.1814 29.2911"
				stroke="#2A2D33"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M46.3687 33.3061L54.4868 28.5834"
				stroke="#2A2D33"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M58.3128 34.1014C49.9064 40.9667 47.1429 45.8726 42.5165 40.4078L42.2097 40.0365"
				stroke="#2A2D33"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M38.1591 20.8368C38.5676 20.8782 39.2202 20.7968 39.8074 20.6965C40.5661 20.5669 41.5681 19.8573 41.2972 19.1368C41.1286 18.6886 40.8585 18.2142 40.4261 17.8763C39.8983 17.464 39.4231 16.7932 39.043 16.1967C38.8369 15.8731 38.6904 15.4992 38.779 15.1259C38.821 14.949 38.8931 14.7543 39.011 14.5659"
				stroke="#2A2D33"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M41.1137 20.3415C41.3845 20.8493 42.1102 21.8972 42.5706 22.4118C43.1067 23.011 40.2809 25.1416 34.2626 23.0412"
				stroke="#2A2D33"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M34.3006 22.2775C34.3049 23.0655 34.056 23.5716 33.8159 23.85"
				stroke="#2A2D33"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<rect
				x="61.3475"
				y="31.4034"
				width="4.93962"
				height="2.62301"
				rx="1.3115"
				transform="rotate(-24.4158 61.3475 31.4034)"
				stroke="#2A2D33"
			/>
			<rect
				x="61.0155"
				y="28.6677"
				width="5.3358"
				height="2.62301"
				rx="1.3115"
				transform="rotate(-24.4158 61.0155 28.6677)"
				stroke="#2A2D33"
			/>
			<rect
				x="61.0693"
				y="25.7704"
				width="5.3358"
				height="2.62301"
				rx="1.3115"
				transform="rotate(-24.4158 61.0693 25.7704)"
				stroke="#2A2D33"
			/>
			<path
				d="M32.4351 14.8079L32.4167 14.3083C32.2148 14.3157 32.0372 14.4439 31.9666 14.6332L32.4351 14.8079ZM37.0011 14.6399L37.4554 14.431C37.371 14.2475 37.1845 14.1328 36.9827 14.1402L37.0011 14.6399ZM32.78 15.6718C32.78 15.4284 32.8238 15.1965 32.9036 14.9826L31.9666 14.6332C31.8458 14.9572 31.78 15.3074 31.78 15.6718H32.78ZM34.753 17.6447C33.6634 17.6447 32.78 16.7614 32.78 15.6718H31.78C31.78 17.3137 33.1111 18.6447 34.753 18.6447V17.6447ZM36.726 15.6718C36.726 16.7614 35.8427 17.6447 34.753 17.6447V18.6447C36.3949 18.6447 37.726 17.3137 37.726 15.6718H36.726ZM36.5468 14.8488C36.6617 15.0986 36.726 15.377 36.726 15.6718H37.726C37.726 15.2299 37.6293 14.8093 37.4554 14.431L36.5468 14.8488ZM36.9827 14.1402L32.4167 14.3083L32.4535 15.3076L37.0195 15.1396L36.9827 14.1402Z"
				fill="#2A2D33"
			/>
			<path
				d="M39.0079 14.5651L42.2814 14.4484C42.507 14.7672 42.6395 15.1566 42.6395 15.5769C42.6395 16.6575 41.7635 17.5335 40.6829 17.5335C40.4061 17.5335 40.1427 17.476 39.9041 17.3723"
				stroke="#2A2D33"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M42.2732 14.4548C42.3584 13.9767 42.2876 12.803 41.3231 11.9328C41.4668 11.4292 41.41 9.58941 40.9659 8.54596"
				stroke="#2A2D33"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M32.4358 14.8089L37.5391 14.6196C37.5699 14.3091 37.4984 13.4937 36.9664 12.7166"
				stroke="#2A2D33"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<circle cx="35.4599" cy="15.9349" r="0.588804" fill="#2A2D33" />
			<circle cx="25.6382" cy="30.8398" r="0.588804" fill="#2A2D33" />
			<circle cx="39.512" cy="32.1573" r="0.588804" fill="#2A2D33" />
			<circle cx="41.2717" cy="15.7385" r="0.588804" fill="#2A2D33" />
			<path
				d="M30.0888 17.3557C30.5542 16.2622 31.192 13.4125 30.0752 10.844L32.6229 11.6771L32.1129 8.89807"
				stroke="#2A2D33"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M26.2618 18.3388C22.2903 15.0706 21.1918 11.402 24.1676 9.51151L22.0671 7.06092H24.4473C24.7205 7.06092 24.9149 6.79537 24.8323 6.53498L24.1676 4.43862L26.6972 5.4746C30.3205 -0.375835 39.5552 -1.89899 44.9473 7.95108C43.5474 7.56712 40.9652 8.46273 38.4485 9.41782C36.5925 10.1222 33.5712 9.34883 32.1129 8.89428"
				stroke="#2A2D33"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M13.666 35.2967C13.666 35.2967 17.3084 33.2445 20.9624 32.3158"
				stroke="#2A2D33"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M16.3428 37.6764L21.0907 35.4071"
				stroke="#2A2D33"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M13.6763 45.5092L18.4478 39.0494"
				stroke="#2A2D33"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M16.4402 48.8013L17.082 40.9295"
				stroke="#2A2D33"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M27.5809 31.5685C28.1808 32.2495 30.0811 33.6114 32.8829 33.6114C35.6847 33.6114 37.2783 32.3049 37.6107 31.7779"
				stroke="#2A2D33"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M37.642 31.623C37.8823 29.7551 37.6768 27.8553 37.483 26.908L41.2602 28.5126C41.5362 29.7975 41.4306 31.3095 41.2454 32.4764C41.1069 33.3485 40.3258 33.9348 39.4429 33.9348C38.3088 33.9348 37.4972 32.7478 37.642 31.623Z"
				stroke="#2A2D33"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M31.7353 43.8729C30.6748 45.7369 27.9693 45.7386 25.4041 45.6405C23.7703 45.5779 21.9092 44.9881 20.7069 44.4033"
				stroke="#2A2D33"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M32.3503 41.725L32.6339 37.5233"
				stroke="#2A2D33"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M29.6318 55.1769L30.3121 52.4184"
				stroke="#2A2D33"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M21.0911 49.7071C23.6648 50.6461 26.3623 50.9094 29.6144 50.237C30.033 50.1505 30.418 49.9411 30.5987 49.5537C30.8091 49.1025 31.0532 48.3579 31.2342 47.2233"
				stroke="#2A2D33"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M32.8955 50.099C33.242 50.3053 34.2541 50.6712 35.5301 50.4849"
				stroke="#2A2D33"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M33.4308 45.0921C34.3262 45.4821 36.7489 46.0199 39.5788 45.2373C39.8996 45.1485 40.2021 44.9948 40.4153 44.7392C41.8544 43.0151 43.4386 37.6318 41.3884 31.5416"
				stroke="#2A2D33"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M41.4454 29.2755L46.3662 33.3041C46.3662 33.3041 45.7758 35.5386 42.3684 38.402"
				stroke="#2A2D33"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M45.5946 34.7959L41.4454 31.6777"
				stroke="#2A2D33"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M44.4986 36.2757L42.1906 34.4854"
				stroke="#2A2D33"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M37.8397 50.9704C38.6789 50.2953 40.2301 48.2143 39.7208 45.205"
				stroke="#2A2D33"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<rect
				x="28.2942"
				y="9.09161"
				width="2.50591"
				height="2.50591"
				fill="#2A2D33"
			/>
			<path
				d="M31.8271 4.65705C32.5228 5.38606 32.8572 6.43576 32.6291 7.49525C32.401 8.55473 31.6644 9.3739 30.7303 9.75206C30.0345 9.02305 29.7002 7.97335 29.9283 6.91386C30.1563 5.85438 30.893 5.03522 31.8271 4.65705Z"
				fill="#FEEA00"
				stroke="#2A2D33"
				strokeLinejoin="round"
			/>
			<path
				d="M25.6716 9.79474C26.5771 10.2369 27.255 11.1053 27.4089 12.1781C27.5628 13.2508 27.1563 14.2748 26.4115 14.9536C25.506 14.5115 24.8281 13.6431 24.6742 12.5703C24.5203 11.4976 24.9268 10.4736 25.6716 9.79474Z"
				fill="#FEEA00"
				stroke="#2A2D33"
				strokeLinejoin="round"
			/>
			<path
				d="M37.0323 5.60102C37.052 6.60856 36.5887 7.60803 35.7043 8.23444C34.8199 8.86086 33.7233 8.96636 32.7794 8.61343C32.7597 7.60589 33.223 6.60642 34.1074 5.98C34.9918 5.35358 36.0884 5.24808 37.0323 5.60102Z"
				fill="#FEEA00"
				stroke="#2A2D33"
				strokeLinejoin="round"
			/>
			<path
				d="M35.1626 10.5908C35.3706 10.333 35.3364 9.96019 35.085 9.74451C34.4615 9.2094 33.6303 8.91745 32.7474 8.99841C31.8645 9.07936 31.1003 9.51757 30.5844 10.1572C30.3765 10.415 30.4106 10.7878 30.662 11.0035C31.2856 11.5386 32.1167 11.8305 32.9996 11.7496C33.8826 11.6686 34.6467 11.2304 35.1626 10.5908Z"
				fill="#FEEA00"
				stroke="#2A2D33"
				strokeLinejoin="round"
			/>
			<path
				d="M28.7781 6.86872C29.5519 7.5144 30.0027 8.51958 29.8959 9.59806C29.7891 10.6765 29.1497 11.5737 28.2644 12.0551C27.4907 11.4094 27.0398 10.4042 27.1466 9.32572C27.2535 8.24724 27.8928 7.35006 28.7781 6.86872Z"
				fill="#FEEA00"
				stroke="#2A2D33"
				strokeLinejoin="round"
			/>
			<path
				d="M32.0328 11.8518C31.1872 11.3038 30.0936 11.1705 29.095 11.5916C28.0964 12.0127 27.4285 12.8889 27.2307 13.877C28.0764 14.425 29.1699 14.5583 30.1685 14.1372C31.1671 13.7161 31.835 12.8399 32.0328 11.8518Z"
				fill="#FEEA00"
				stroke="#2A2D33"
				strokeLinejoin="round"
			/>
		</svg>
	)
}

export default FranchiseIcon

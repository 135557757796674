import * as React from "react"

const DeliveryModule = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M4.994 16.99h-1a1 1 0 0 0 1 1v-1Zm17 0v1a1 1 0 0 0 1-1h-1Zm-20-13.562a1 1 0 0 0 0 2v-2Zm5 2a1 1 0 1 0 0-2v2Zm-5 2a1 1 0 0 0 0 2v-2Zm2 2a1 1 0 1 0 0-2v2Zm2 7.563c0-2.348.818-4.577 2.241-6.201L6.731 9.472c-1.764 2.014-2.737 4.72-2.737 7.519h2Zm2.241-6.201c1.42-1.621 3.315-2.5 5.259-2.5v-2c-2.566 0-4.995 1.163-6.763 3.182l1.504 1.318Zm5.259-2.5c1.943 0 3.838.879 5.258 2.5l1.504-1.318c-1.768-2.019-4.197-3.183-6.762-3.183v2Zm5.258 2.5c1.424 1.624 2.242 3.853 2.242 6.2h2c0-2.798-.973-5.504-2.738-7.518l-1.504 1.318Zm-13.758 7.2h17v-2h-17v2Zm4.562 1.063c0 .587-.476 1.063-1.062 1.063v2a3.063 3.063 0 0 0 3.062-3.063h-2Zm-1.062 1.063a1.063 1.063 0 0 1-1.063-1.063h-2a3.062 3.062 0 0 0 3.063 3.063v-2ZM7.43 19.053c0-.587.476-1.062 1.063-1.062v-2a3.063 3.063 0 0 0-3.063 3.062h2Zm1.063-1.062c.586 0 1.062.475 1.062 1.062h2a3.063 3.063 0 0 0-3.062-3.062v2Zm6.062-12.764c0 .586-.475 1.062-1.062 1.062v2a3.063 3.063 0 0 0 3.062-3.062h-2Zm-1.062 1.062a1.062 1.062 0 0 1-1.063-1.062h-2a3.062 3.062 0 0 0 3.063 3.062v-2ZM12.43 5.227c0-.587.476-1.063 1.063-1.063v-2a3.062 3.062 0 0 0-3.063 3.063h2Zm1.063-1.063c.587 0 1.062.476 1.062 1.063h2a3.063 3.063 0 0 0-3.062-3.063v2Zm6.062 14.89c0 .586-.476 1.062-1.062 1.062v2a3.063 3.063 0 0 0 3.062-3.063h-2Zm-1.062 1.062a1.063 1.063 0 0 1-1.063-1.063h-2a3.062 3.062 0 0 0 3.063 3.063v-2Zm-1.063-1.063c0-.587.476-1.062 1.063-1.062v-2a3.063 3.063 0 0 0-3.063 3.062h2Zm1.063-1.062c.587 0 1.062.475 1.062 1.062h2a3.063 3.063 0 0 0-3.062-3.062v2ZM1.994 5.428h5v-2h-5v2Zm0 4h2v-2h-2v2Z"
			stroke={"none"}
		/>
	</svg>
)

export default DeliveryModule

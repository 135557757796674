import { checkResponseStatus } from "./utils"

/**
 * Авторизован ли пользователь в сайте. Сервер проверяет авторизованность по кукам
 * @returns {Promise<boolean>}
 */
export function checkAuth() {
	if (typeof window !== "undefined") {
		// Условие для среды разработки (обход авторизации)
		// Исключение для локалки и внешнего IP одного из разработчиков
		if (
			window.location.hostname === "localhost" ||
			window.location.hostname === "188.243.87.223" ||
			window.location.hostname === "kz-quickresto.creonit.dev" ||
			window.location.hostname === "by-quickresto.creonit.dev" ||
			window.location.hostname === "quickresto.creonit.dev"
		) {
			return new Promise(resolve => {
				resolve(false)
			})
		}
	}

	return fetch("/api_controller/?module=auth&method=checkAuth", {
		method: "POST",
		headers: {
			Authorization: "Basic cXJ0ZXN0bG9naW46MXFhenhzdzI",
		},
	})
		.then(rawResponse => rawResponse.json())
		.then(checkResponseStatus)
		.then(response => response.payload.auth)
}

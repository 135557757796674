import React from "react"
import classNames from "classnames"

import AdaptiveLink, { AdaptiveLinkBaseProps } from "../../../AdaptiveLink"

import styles from "./styles.module.scss"

interface Props {
	className?: string

	bigSize?: boolean

	vertical?: boolean

	icon: JSX.Element

	title: string

	text?: string

	link: AdaptiveLinkBaseProps
}

export default function MenuItemCustomTile(props: Props) {
	return (
		<div
			className={classNames(styles.tile, props.className, {
				[styles.__bigSize]: props.bigSize,
				[styles.__vertical]: props.vertical,
			})}
		>
			<div className={styles.tile__iconContainer}>{props.icon}</div>

			<AdaptiveLink
                href={props.link.href}
                useGatsbyLink={props.link.useGatsbyLink}
                className={styles.tile__title}
            >
                {props.title}
            </AdaptiveLink>

			<p className={styles.tile__text}>{props.text}</p>
		</div>
	)
}

import * as React from "react"

const Warehouse = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M12 2 2.746 6.03 12 10.32M12 2l9.253 4.03L12 10.32"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
			fill={"none"}
		/>
		<path
			d="M16.318 3.88 7.212 8.103v4.185M2.746 6.328V17.71L12 22M21.253 6.216V17.71L12 21.999M11.992 21.767v-11.25"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
			fill={"none"}
		/>
	</svg>
)

export default Warehouse

import React from "react"
import { HeaderUIStateProvider } from "./hooks/headerUIState"
import Header, { HeaderProps } from "./index"

export interface HeaderWrapperProps {
	headerProps: HeaderProps
}

export default function HeaderWrapper(props: HeaderWrapperProps) {
	const headerProps = props.headerProps || {}
	return (
		<HeaderUIStateProvider>
			<Header {...headerProps} />
		</HeaderUIStateProvider>
	)
}

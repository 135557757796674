import * as React from "react"

const Cookery = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		width="19"
		height="18"
		viewBox="0 0 19 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M9.09523 10.6997L6.87888 12.916C7.35563 13.3928 7.35563 14.1657 6.87888 14.6424C6.40213 15.1192 5.62917 15.1192 5.15242 14.6424C4.98151 14.4715 4.87187 14.2626 4.8235 14.0428C4.78445 13.8654 4.63463 13.7156 4.45721 13.6765C4.23746 13.6282 4.02847 13.5185 3.85756 13.3476C3.38081 12.8709 3.38081 12.0979 3.85756 11.6212C4.33431 11.1444 5.10728 11.1444 5.58403 11.6212L6.32057 10.8846M9.24792 4.07267C10.6782 2.64244 12.9971 2.64244 14.4273 4.07267C15.8576 5.50289 15.8576 7.82175 14.4273 9.25197C12.9971 10.6822 9.81494 11.5454 8.38469 10.1152C6.95444 8.68496 7.81767 5.50289 9.24792 4.07267Z"
			stroke="#FF9500"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<ellipse
			cx="10.5084"
			cy="6.56926"
			rx="0.624892"
			ry="0.603932"
			fill="#FF9500"
		/>
		<ellipse
			cx="12.6332"
			cy="6.56926"
			rx="0.624892"
			ry="0.603932"
			fill="#FF9500"
		/>
		<ellipse
			cx="11.5717"
			cy="5.09673"
			rx="0.624892"
			ry="0.603932"
			fill="#FF9500"
		/>
	</svg>
)

export default Cookery

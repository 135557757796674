import React from 'react'

import styles from './styles.module.scss'

import { getKnowledgeBaseData } from './data'

import Menu from '../../components/Menu'

import { useLocalizationContext } from '../../../../../localization/useLocalizationContext'

export default function KnowledgeBase() {
  const localizationContext = useLocalizationContext()

  const data = getKnowledgeBaseData(localizationContext.locale)

  return (
    <div className={styles.container}>
      <Menu menuClassName={styles.menu} data={data.menu} />
    </div>
  )
}

import * as React from "react"

const ElectronicQueue = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 25 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M4.999 12a7.5 7.5 0 1 0 2.177-5.283"
			strokeWidth={2}
			strokeMiterlimit={10}
			strokeLinecap="round"
			fill={"none"}
		/>
		<path
			d="M15.499 12h-3M12.499 12V9M7.12 12 5 9.879M2.878 12l2.121-2.121"
			strokeWidth={2}
			strokeLinecap="round"
			fill={"none"}
		/>
	</svg>
)

export default ElectronicQueue

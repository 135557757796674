import * as React from "react"

const Pizzeria = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		width="18"
		height="18"
		viewBox="0 0 18 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M3 13.3923C4.82423 14.4455 6.89356 15 9 15C11.1064 15 13.1758 14.4455 15 13.3923L9 3L3 13.3923Z"
			stroke="#FF9500"
			strokeWidth="1.5"
			strokeLinejoin="round"
		/>
		<mask
			id="mask0_8703_4595"
			maskUnits="userSpaceOnUse"
			x="2"
			y="2"
			width="14"
			height="13"
		>
			<path
				d="M3.5056 12.5169C5.17618 13.4814 7.07122 13.9892 9.00024 13.9892C10.9293 13.9892 12.8243 13.4814 14.4949 12.5169L9.00024 2.99993L3.5056 12.5169Z"
				fill="#D9D9D9"
				stroke="#FF9500"
				strokeWidth="1.5"
				strokeLinejoin="round"
			/>
		</mask>
		<g mask="url(#mask0_8703_4595)">
			<circle
				cx="11.4146"
				cy="7.6211"
				r="1.83154"
				stroke="#FF9500"
				strokeWidth="1.5"
			/>
			<circle
				cx="10.2571"
				cy="13.0825"
				r="1.83154"
				stroke="#FF9500"
				strokeWidth="1.5"
			/>
			<circle
				cx="6.23926"
				cy="9.30664"
				r="1.83154"
				stroke="#FF9500"
				strokeWidth="1.5"
			/>
		</g>
	</svg>
)

export default Pizzeria

import * as React from "react"
import { SVGProps } from "react"

const PriceIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="60"
		height="60"
		viewBox="0 0 60 60"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M46.7606 14.9238C47.4447 18.479 46.8924 22.1747 45.1929 25.4154C43.507 28.6299 40.7897 31.211 37.4731 32.75C37.2389 32.5597 36.4696 32.1791 35.2659 32.1791H25.674H22.3682C19.2672 30.4184 16.8534 27.6884 15.5037 24.4101C14.1267 21.0652 13.9377 17.3444 14.9671 13.8473C15.9966 10.3502 18.1846 7.28046 21.1784 5.13293C24.1722 2.9854 27.7975 1.88514 31.47 2.00949C35.1424 2.13384 38.6481 3.47556 41.422 5.81838C44.196 8.16121 46.0765 11.3687 46.7606 14.9238Z"
			fill="#FFEA00"
		/>
		<path
			d="M27.6288 18.2186V9.56672L33.0541 9.56672C35.4432 9.56672 37.38 11.5035 37.38 13.8927C37.38 16.2818 35.4432 18.2186 33.0541 18.2186H27.6288ZM27.6288 18.2186V22.5463M27.6288 18.2186H24.381M27.6288 22.5463V26.9073M27.6288 22.5463H24.381M27.6288 22.5463H35.2029M8.88157 40.4139C8.88157 35.8694 12.5656 32.1853 17.1101 32.1853H22.4536M24.8438 40.4139L35.4198 40.4139C37.6921 40.4139 39.5341 38.5718 39.5341 36.2996C39.5341 34.7913 38.7225 33.4726 37.5122 32.7564M38.3699 39.178L49.5782 28.1687C51.4017 26.3775 54.3281 26.3906 56.1355 28.198C58.0145 30.0771 57.9431 33.145 55.9787 34.9346L43.6535 46.1627C39.7579 49.7114 34.6781 51.6784 29.4084 51.6784H21.2286M8.77535 40.4915L2 46.3563L14.2604 58.6167L21.0355 51.6852M22.4536 32.1853H35.4198C36.1838 32.1853 36.8992 32.3935 37.5122 32.7564M22.4536 32.1853C19.3622 30.4243 16.9557 27.6937 15.6102 24.4147C14.2374 21.0692 14.0489 17.3476 15.0753 13.8497C16.1016 10.3519 18.2828 7.28155 21.2675 5.13357C24.2521 2.9856 27.8663 1.88512 31.5275 2.0095C35.1887 2.13387 38.6837 3.47586 41.4491 5.81917C44.2145 8.16248 46.0893 11.3706 46.7713 14.9265C47.4533 18.4824 46.9027 22.1789 45.2084 25.4202C43.5277 28.6354 40.8186 31.217 37.5122 32.7564"
			stroke="#2A2D33"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default PriceIcon

import * as React from "react"
import { SVGProps } from "react"

const DarkKitchen = (props: SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 18 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<circle cx={8.625} cy={9.375} r={6.75} strokeWidth={1.5} fill={"none"} />
		<path
			d="M15.271 8.474s-2.976 3.007-6.646 3.007-6.646-3.007-6.646-3.007 2.976-3.008 6.646-3.008 6.646 3.008 6.646 3.008Z"
			strokeWidth={1.5}
			strokeLinejoin="round"
			fill={"none"}
		/>
		<circle cx={6.493} cy={8.474} r={0.711} />
		<circle cx={10.757} cy={8.474} r={0.711} />
		<path
			d="M13.875 1.875v2.25h2.25"
			strokeWidth={1.5}
			strokeLinecap="round"
			strokeLinejoin="round"
			fill={"none"}
		/>
	</svg>
)

export default DarkKitchen

import React from 'react'

import colors from '../../../../styles/_V2/export.module.scss'
import Assistance from '../assets/dropdownItemsIcons/Assistance'

import {
  externalLinks,
  pagesLinks,
} from '../../../../pages-data/_V2/common/links'

import Webinars from '../assets/dropdownItemsIcons/Webinars'
import Video from '../assets/dropdownItemsIcons/Video'
import ProductDemo from '../assets/dropdownItemsIcons/ProductDemo'
import Blog from '../assets/dropdownItemsIcons/Blog'
import BusinessAudit from '../assets/dropdownItemsIcons/BusinessAudit'
import Community from '../assets/dropdownItemsIcons/Community'
import RestoGuide from '../assets/dropdownItemsIcons/RestoGuide'
import CaseOfTheMonth from '../assets/dropdownItemsIcons/CaseOfTheMonth'
import SecretIngredient from '../assets/dropdownItemsIcons/SecretIngredient'
import {Locales} from "../../../../localization/types";

export const knowledgeBaseIconColor = colors.cyan
export const knowledgeBaseItemHoverColor = colors.cyanLightBg

export const getKnowledgeBaseItems = (locale: Locales) => {
  if (locale === 'kz-KZ') {
    return {
      support: {
        icon: <Assistance />,
        name: 'Көмек',
        description: 'Бізбен байланысыңыз',
        link: {
          href: `${externalLinks.quickrestoRU.href}${pagesLinks.support.href.slice(1)}`,
          useGatsbyLink: false,
          target: '_blank'
        },
      },
      webinars: {
        icon: <Webinars />,
        name: 'Вебинарлар',
        description: 'Сала сарапшыларымен кездесулер, оқу',
        link: {
          href: externalLinks.webinars,
          target: '_blank'
        },
      },
      youtube: {
        icon: <Video />,
        name: 'Видео',
        description: 'Бұл қалай жұмыс істейтінін қараңыз',
        link: {
          href: externalLinks.youtube,
          target: '_blank'
        },
      },
      demoCloud: {
        icon: <ProductDemo />,
        name: 'Өнім бойынша демо',
        description: 'Quick Resto-мен не істей алатыныңызды біліңіз',
        link: {
          ...externalLinks.demoCloud,
          target: '_blank'
        } ,
      },
      blog: {
        icon: <Blog />,
        name: 'Журнал «Котёл»',
        description: 'Индустрияға қатысты барлығы үшін оқыңыз',
        link: {
          ...pagesLinks.blog,
          target: '_blank'
        },
      },
      service: {
        icon: <BusinessAudit />,
        name: 'Бизнес аудиті',
        description: 'Не істеу керектігін біліңіз',
        link: pagesLinks.service,
      },
      tgRestoChanel: {
        icon: <Community />,
        name: 'Комьюнити',
        description: 'Кез келген сұрақтар қойыңыз және басқаларға көмектесіңіз',
        link: {
          href: externalLinks.tgRestoChanel,
          rel: 'nofollow',
          target: '_blank'
        },
      },
      restoGuide: {
        icon: <RestoGuide />,
        name: 'Ресторатордың анықтамалығы',
        description: 'Бизнесте табысқа жетуге арналған қадамдық нұсқаулық',
        link: {
          ...pagesLinks.restoGuide,
          target: '_blank'
        },
      },
      clients: {
        icon: <CaseOfTheMonth />,
        name: 'Кейс месяца',
        description: 'Ты только посмотри, что у них получилось',
        link: {
          ...pagesLinks.clients,
          href: `${externalLinks.quickrestoRU.href}${pagesLinks.clients.href.slice(1)}`,
          target: '_blank'
        },
      },
      secretIngredient: {
        icon: <SecretIngredient />,
        name: 'Құпия ингредиент',
        description: 'Оларда не шыққанын қараңыз',
        link: {
          ...pagesLinks.secretIngredient,
          href: `${externalLinks.quickrestoRU.href}${pagesLinks.secretIngredient.href.slice(1)}`,
          target: '_blank'
        },
      },
    }
  } else if (locale !== 'ru-RU') {
    return {
      support: {
        icon: <Assistance />,
        name: 'Инструкции',
        description: 'Ответы на любые вопросы',
        link: {
          href: `${externalLinks.quickrestoRU.href}${pagesLinks.support.href.slice(1)}`,
          useGatsbyLink: false,
          target: '_blank'
        },
      },
      webinars: {
        icon: <Webinars />,
        name: 'Вебинары',
        description: 'Встречи с экспертами отрасли, обучение',
        link: {
          href: externalLinks.webinars,
          target: '_blank'
        },
      },
      youtube: {
        icon: <Video />,
        name: 'Видео',
        description: 'Посмотри как это работает',
        link: {
          href: externalLinks.youtube,
          target: '_blank'
        },
      },
      demoCloud: {
        icon: <ProductDemo />,
        name: 'Демо по продукту',
        description: 'Узнай, что ты сможешь с Quick Resto',
        link: {
          ...externalLinks.demoCloud,
          target: '_blank'
        } ,
      },
      blog: {
        icon: <Blog />,
        name: 'Журнал «Котёл»',
        description: 'Читай обо всём, что касается индустрии',
        link: {
          ...pagesLinks.blog,
          target: '_blank'
        },
      },
      service: {
        icon: <BusinessAudit />,
        name: 'Аудит бизнеса',
        description: 'Знай, что надо делать',
        link: pagesLinks.service,
      },
      tgRestoChanel: {
        icon: <Community />,
        name: 'Комьюнити',
        description: 'Задавай любые вопросы и помогай другим',
        link: {
          href: externalLinks.tgRestoChanel,
          rel: 'nofollow',
          target: '_blank'
        },
      },
      restoGuide: {
        icon: <RestoGuide />,
        name: 'Справочник ресторатора',
        description: 'Пошаговая инструкция для достижения успеха в бизнесе',
        link: {
          ...pagesLinks.restoGuide,
          target: '_blank'
        },
      },
      clients: {
        icon: <CaseOfTheMonth />,
        name: 'Кейс месяца',
        description: 'Ты только посмотри, что у них получилось',
        link: {
          ...pagesLinks.clients,
          href: `${externalLinks.quickrestoRU.href}${pagesLinks.clients.href.slice(1)}`,
          target: '_blank'
        },
      },
      secretIngredient: {
        icon: <SecretIngredient />,
        name: 'Секретный ингредиент',
        description: 'Посмотри, что у них получилось',
        link: {
          ...pagesLinks.secretIngredient,
          href: `${externalLinks.quickrestoRU.href}${pagesLinks.secretIngredient.href.slice(1)}`,
          target: '_blank'
        },
      },
    }
  }


  return {
    support: {
      icon: <Assistance />,
      name: 'Инструкции',
      description: 'Ответы на любые вопросы',
      link: pagesLinks.support,
    },
    webinars: {
      icon: <Webinars />,
      name: 'Вебинары',
      description: 'Встречи с экспертами отрасли, обучение',
      link: {
        href: externalLinks.webinars,
      },
    },
    youtube: {
      icon: <Video />,
      name: 'Видео',
      description: 'Посмотри как это работает',
      link: {
        href: externalLinks.youtube,
      },
    },
    demoCloud: {
      icon: <ProductDemo />,
      name: 'Демо по продукту',
      description: 'Узнай, что ты сможешь с Quick Resto',
      link: externalLinks.demoCloud,
    },
    blog: {
      icon: <Blog />,
      name: 'Журнал «Котёл»',
      description: 'Читай обо всём, что касается индустрии',
      link: pagesLinks.blog,
    },
    service: {
      icon: <BusinessAudit />,
      name: 'Аудит бизнеса',
      description: 'Знай, что надо делать',
      link: pagesLinks.service,
    },
    tgRestoChanel: {
      icon: <Community />,
      name: 'Комьюнити',
      description: 'Задавай любые вопросы и помогай другим',
      link: {
        href: externalLinks.tgRestoChanel,
        rel: 'nofollow'
      },
    },
    restoGuide: {
      icon: <RestoGuide />,
      name: 'Справочник ресторатора',
      description: 'Пошаговая инструкция для достижения успеха в бизнесе',
      link: pagesLinks.restoGuide,
    },
    clients: {
      icon: <CaseOfTheMonth />,
      name: 'Кейс месяца',
      description: 'Ты только посмотри, что у них получилось',
      link: pagesLinks.clients,
    },
    secretIngredient: {
      icon: <SecretIngredient />,
      name: 'Секретный ингредиент',
      description: 'Посмотри, что у них получилось',
      link: pagesLinks.secretIngredient,
    },
  }

}

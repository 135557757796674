import * as React from "react"

const ChevronTop = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 12 8"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M10 6 6 2 2 6"
			strokeWidth={2.25}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default ChevronTop

import * as React from "react"

const OtherIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		width="18"
		height="22"
		viewBox="0 0 18 22"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<ellipse
			cx="8.99997"
			cy="8.61933"
			rx="7.61911"
			ry="4.7619"
			stroke="#FF9600"
			strokeWidth="2"
		/>
		<ellipse
			cx="8.99997"
			cy="8.61933"
			rx="7.61911"
			ry="4.7619"
			stroke="#FF9600"
			strokeWidth="2"
		/>
		<path
			d="M1.38086 8.61914V16.2382C1.38086 18.8681 4.79205 21.0001 8.99997 21.0001C13.2079 21.0001 16.6191 18.8681 16.6191 16.2382V8.61914"
			stroke="#FF9600"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<ellipse
			cx="8.99955"
			cy="11.4763"
			rx="3.04764"
			ry="1.90476"
			stroke="#FF9600"
			strokeWidth="2"
		/>
		<path
			d="M9 3.85714V1"
			stroke="#FF9600"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M3.28589 4.80979V2.90503"
			stroke="#FF9600"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M14.7141 4.80979V2.90503"
			stroke="#FF9600"
			strokeWidth="2"
			strokeLinecap="round"
		/>
	</svg>
)

export default OtherIcon

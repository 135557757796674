import * as React from "react"

const Notifications = (props: React.SVGProps<SVGSVGElement>) => {
	// Уникальный ID
	const gradId = Math.random()
		.toString(16)
		.slice(2)

	return (
		<svg
			viewBox="0 0 25 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M13.065 3.99H11.4c-2.488 0-3.732 0-4.681.486a4.46 4.46 0 0 0-1.938 1.96c-.486.958-.486 2.214-.486 4.72l.018 1.668c0 2.507 0 3.763.485 4.72a4.46 4.46 0 0 0 1.938 1.96c.95.486 2.193.486 4.682.486h1.647c2.484 0 3.728 0 4.677-.485a4.468 4.468 0 0 0 1.942-1.96c.481-.959.481-2.214.481-4.721v-1.667m-11 2.836h3m-3-3.003h6m6-5.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z"
				stroke={`url(#${gradId})`}
				strokeWidth={2}
				strokeLinecap="round"
				strokeLinejoin="round"
				fill={"none"}
				data-not-fill-stroke
			/>
			<defs>
				<linearGradient
					id={gradId}
					x1={12.73}
					y1={2.99}
					x2={12.73}
					y2={19.99}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FEE04C" />
					<stop offset={1} stopColor="#DFAD2B" />
				</linearGradient>
			</defs>
		</svg>
	)
}

export default Notifications

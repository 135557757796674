import React from "react"

import { pagesLinks } from "../../../pages-data/_V2/common/links"
import commonData from "../../../pages-data/_V2/common/commonData"
import { contacts, contactsBy, contactsKz } from "../../../pages-data/_V2/common/contacts"

import Solutions from "./contentSections/Solutions"
import KnowledgeBase from "./contentSections/KnowledgeBase"
import BusinessTypes from "./contentSections/BusinessTypes"
import {Locales} from "../../../localization/types";

export interface ContentSection {
	name: string
	sizes: {
		width: number
		height: number
		left: number
	}
	element: JSX.Element
}

export type NavigationItem =
	| {
			title: string
			contentSectionName: string
	  }
	| {
			title: string
			link: string
	  }

export interface HeaderData {
	phone: {
		text: string
		value: string
	}

	startButton: {
		text: string
		textKz?: string
		href: string
	}

	indexPage: {
		text: string
		href: string
		useGatsbyLink: boolean
	}

	navigation: Array<NavigationItem>

	contentSections: {
		[key: string]: ContentSection
	}
}

export const getData = (locale: Locales) => {
	const phones = {
		'ru-RU': contacts.phone,
		'ru-KZ': contactsKz.phone,
		'kz-KZ': contactsKz.phone,
		'ru-BY': contactsBy.phone
	}

	const solutionTitle = {
		'ru-RU': "Решения",
		'ru-KZ': "Решения",
		'kz-KZ': "Шешімдер",
		'ru-BY': "Решения",
		default: "Решения"
	}

	const businessTypesTitle = {
		'ru-RU': "Типы бизнеса",
		'ru-KZ': "Типы бизнеса",
		'kz-KZ': "Бизнес түрлері",
		'ru-BY': "Типы бизнеса",
		default: "Типы бизнеса",
	}

	const priceTitle = {
		'ru-RU': "Цены",
		'ru-KZ': "Цены",
		'kz-KZ': "Бағалар",
		'ru-BY': "Цены",
		default: "Цены",
	}

	const knowledgeBaseTitle = {
		'ru-RU': "База знаний",
		'ru-KZ': "База знаний",
		'kz-KZ': "Білім қоры",
		'ru-BY': "База знаний",
		default: "База знаний",
	}

	return {
		phone: phones[locale],
		startButton: pagesLinks.registration,
		indexPage: pagesLinks.index,
		navigation: [
			{
				title: solutionTitle[locale],
				contentSectionName: "solutions",
			},
			{
				title: businessTypesTitle[locale],
				contentSectionName: "businessTypes",
			},
			{
				title: priceTitle[locale],
				link: pagesLinks.price.href,
			},
			{
				title: knowledgeBaseTitle[locale],
				contentSectionName: "knowledgeBase",
			},
		],
		contentSections: {
			solutions: {
				name: "solutions",
				sizes: {
					width: 1320,
					height: 633,
					left: 60,
				},
				element: <Solutions />,
			},
			businessTypes: {
				name: "businessTypes",
				sizes: {
					width: 770,
					height: 381,
					left: 195,
				},
				element: <BusinessTypes />,
			},
			knowledgeBase: {
				name: "knowledgeBase",
				sizes: {
					width: 1000,
					height: 371,
					left: 346,
				},
				element: <KnowledgeBase />,
			},
		},
	} as HeaderData
}

import * as React from "react"

const QuickRestoManager = (props: React.SVGProps<SVGSVGElement>) => {
	// Уникальный ID
	const gradId = Math.random()
		.toString(16)
		.slice(2)

	return (
		<svg
			viewBox="0 0 25 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="m10.628 6.974-2-2m7.646 2 2-2m-4.823.733V2.88M9.13 14.185a1.502 1.502 0 0 1 1.272-.7 1.505 1.505 0 0 1 .798 2.781m-8.304-1.265h1.912m10.592.098a5 5 0 1 1-4.9-5.108h11v4l-6.1 1.108Z"
				stroke={`url(#${gradId})`}
				strokeWidth={2}
				strokeLinecap="round"
				strokeLinejoin="round"
				fill={"none"}
				data-not-fill-stroke
			/>
			<defs>
				<linearGradient
					id={gradId}
					x1={12.198}
					y1={2.879}
					x2={12.198}
					y2={19.99}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FEE04C" />
					<stop offset={1} stopColor="#DFAD2B" />
				</linearGradient>
			</defs>
		</svg>
	)
}

export default QuickRestoManager
